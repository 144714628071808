import React, {useState} from 'react';
import {Button, Navbar, Nav, Container} from 'react-bootstrap';
import Logo from '../assets/img/brandlabs-logo.png';
import '../assets/css/main.css';
import {Link} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import Login from '../dashboard/Login';
import Signup from '../dashboard/Signup';
import VerifyPhone from '../dashboard/VerifyPhone';
import Otp from '../dashboard/Otp';
import ForgetPassword from './Login/ForgetPassword';
import ResetPassword from './Login/ResetPassword';
import PasswordResetDone from './Login/PasswordResetDone';
import Congratulations from '../dashboard/Congratulations';
import Phone from '../dashboard/Phone';

const Header = () => {
  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false);
  const [phoneOtpShow, setPhoneOtpShow] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [showForget, setShowForegt] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showSigunUp, setShowSignUp] = useState(false);
  const [showResetPswDone, setShowResetPswDone] = useState(false);
  const [congratulationsModal, setCongratulationsModal] = useState(false);
  const handleClickLogin = () => {
    setShowLogin(true);
  };

  const handleGoBack = () => {
    navigate('/');
  };

  const clickSolutions = () => {
    window.scrollTo(0, 0);
    navigate('/solutions');
  };

  // const clickUtilies = () => {
  //   window.scrollTo(0, 0);
  //   navigate('/utilities');
  // };

  // const productPage = () => {
  //   window.scrollTo(0, 0);
  //   navigate('/product-pages');
  // };
  return (
    <>
      {showLogin && (
        <Login
          showLogin={showLogin}
          setShowLogin={setShowLogin}
          setShowSignUp={setShowSignUp}
          setShowForegt={setShowForegt}
          setShowPhone={setShowPhone}
          setOtpShow={setOtpShow}
        />
      )}
      {showResetPswDone && (
        <PasswordResetDone
          setShowResetPswDone={setShowResetPswDone}
          showResetPswDone={showResetPswDone}
        />
      )}
      {showSigunUp && (
        <Signup
          showSigunUp={showSigunUp}
          setShowSignUp={setShowSignUp}
          setOtpShow={setOtpShow}
          setShowLogin={setShowLogin}
        />
      )}
      {otpShow && (
        <Otp
          setOtpShow={setOtpShow}
          otpShow={otpShow}
          setCongratulationsModal={setCongratulationsModal}
          setShowPhone={setShowPhone}
        />
      )}

      {showForget && (
        <ForgetPassword
          showForget={showForget}
          setShowForegt={setShowForegt}
          setShowResetPassword={setShowResetPassword}
        />
      )}

      {showResetPassword && (
        <ResetPassword
          setShowResetPassword={setShowResetPassword}
          showResetPassword={showResetPassword}
          setShowResetPswDone={setShowResetPswDone}
        />
      )}
      {phoneOtpShow && (
        <VerifyPhone
          phoneOtpShow={phoneOtpShow}
          setPhoneOtpShow={setPhoneOtpShow}
        />
      )}
      {showPhone && (
        <Phone
          showPhone={showPhone}
          setShowPhone={setShowPhone}
          setPhoneOtpShow={setPhoneOtpShow}
        />
      )}

      {congratulationsModal && (
        <Congratulations
          setCongratulationsModal={setCongratulationsModal}
          congratulationsModal={congratulationsModal}
          setShowPhone={setShowPhone}
        />
      )}

      <Navbar collapseOnSelect expand='lg' variant='light' className='header'>
        <Container>
          <Navbar.Brand href=''>
            {' '}
            <Link to='/'>
              <img
                src={Logo}
                to='/'
                className='headerLogo'
                onClick={handleGoBack}
              />
            </Link>
          </Navbar.Brand>
          <Navbar.Brand>
            <div>
              <button
                href=''
                className='btnLogin mobile my-2 my-sm-0'
                onClick={handleClickLogin}
              >
                Login
              </button>
            </div>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            <Nav className='me-auto'></Nav>
            <Nav>
              <Nav.Link>
                <Link>
                  {/* <Dropdown style={{ backgroundColor: 'transparent' }}>
                    <Dropdown.Toggle
                      variant='transparent'
                      id='dropdown-basic'
                      className='linkPages lp'
                    >
                      Pages
                    </Dropdown.Toggle> */}
                  {/*
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={brandPage}>
                        Brand Pages
                      </Dropdown.Item>
                      <Dropdown.Item onClick={productPage}>
                        Product Pages
                      </Dropdown.Item>
                    </Dropdown.Menu> */}
                  {/* </Dropdown> */}
                </Link>
              </Nav.Link>

              <Nav.Link>
                <Link
                  className='linkPages'
                  to='/solutions'
                  onClick={clickSolutions}
                >
                  Solutions
                </Link>
              </Nav.Link>

              {/* <Nav.Link>
                <Link
                  className='linkPages'
                  to='/utilities'
                  onClick={clickUtilies}
                >
                  Utilities
                </Link>
              </Nav.Link> */}

              {/* desktop login */}

              <Button
                variant='danger'
                className='btnLogin pc'
                // onClick={onClickLogin}
                onClick={handleClickLogin}
              >
                Login
              </Button>
              
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
