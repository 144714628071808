import React, {useEffect, useState, useRef} from 'react';
import SectionTwo from '../../assets/svg/section-two.svg';
import SectionThree from '../../assets/svg/section-four.svg';
import SectionFive from '../../assets/svg/fifth.svg';
import ChatLogo from '../../assets/svg/cahtlogobtp.svg';
import ChatProfile from '../../assets/svg/chatprofile.svg';
import Youtube from '../../assets/svg/youtubeframe.svg';
import Collapse from 'react-bootstrap/Collapse';
import {BsX} from 'react-icons/bs';
import {IoIosAdd} from 'react-icons/io';
import Rightsidepic from '../../assets/svg/rightsidegirl.svg';
import Card from 'react-bootstrap/Card';
import GetBanner from '../../assets/svg/getbanner.svg';
import Booo from '../../assets/svg/booo.svg';
import {BsArrowRight} from 'react-icons/bs';
import GetBannerBottom from '../../assets/svg/getbannerbottom.svg';
import GetBannerBottom1 from '../../assets/svg/getbannerbottom1.svg';
import GetBannerBottom2 from '../../assets/svg/getbannerbottom2.svg';
import {useSpring, animated} from '@react-spring/web';
import firstup from '../../assets/svg/firstup.svg';
import alldetails from '../../assets/svg/alldetails.svg';
import uppperbanner from '../../assets/svg/uppperbanner.svg';

const Brand = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [scale, setScale] = useState(1);
  const imageRef = useRef(null);
  const collapse = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const handleScroll = () => {
      const imageBoundingRect = imageRef.current.getBoundingClientRect();
      const centerY = imageBoundingRect.top + imageBoundingRect.height / 2;
      const delta =
        1 - ((window.innerHeight - centerY) / window.innerHeight) * 1;
      setScale(1 + delta);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [showImage, setShowImage] = useState(false);
  const firstImageProps = useSpring({
    to: { transform: showImage ? 'translateX(0px)' : 'translateX(600px)' },
  });

  const secondImageProps = useSpring({
    to: { transform: showImage ? 'translateY(-650px)' : 'translateY(0px)' },
  });

  const [scrollTop, setScrollTop] = useState(0);
  const handleScroll2 = () => {
    setScrollTop(window.scrollY);
  };

  const springs = useSpring({
    from: { x: 700 },
    to: { x: 0 },
  });

  useEffect(() => {
    window.addEventListener('scroll', handleScroll2);
    if (scrollTop >= 1200) {
      setShowImage(true);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll2);
      setShowImage(false);
    };
  });

  return (
    <>
      <div className='brand-pages-container' id='top-gap'>
        <div className='main-first-container'>
          <div className='brand-page-main-section bg-red container'>
            <div className='brand-page-section text-center row'>
              <h1>Connect With Moms in a Brand New Way</h1>
              <p>
                Brand Labs offer a lot more than customisable brand pages that
                allow you to create a unique experience for your customers
              </p>
              <button>Try For Free</button>
              <div className='animated_image'>
                <img
                  ref={imageRef}
                  src={uppperbanner}
                  style={{
                    transform: `scale(${scale})`,
                    transition: 'transform 0.5s ease-out',
                    maxWidth: '60%',
                    marginBottom: '-30px',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='brand-page-second-section container'>
          <div className='brand-page-section row'>
            <h1 className='bg-white'>Do more with Brand Pages</h1>
            <p>
              Build an online business profile and get discovered by fans and
              customers with a Brand Page.
            </p>
          </div>
          <div className='full-width'>
            <div className='question-section'>
              {scrollTop <= 1200 ? (
                <div className='left-width'>
                  <h2>Highlight your uniqueness.</h2>
                  <p id='normal'>
                    Use predesigned templates and drag-and-drop tiles to create
                    a brand page that fits your brand and spotlights your
                    best-selling products, without ever writing a line of code.
                  </p>
                  <p>Managing every page with ease.</p>
                  <p>Get discovered by Moms.</p>
                </div>
              ) : (
                <div className='left-width'>
                  <p>Highlight your uniqueness.</p>
                  <h2>Insightful analytics at your tips.</h2>
                  <p id='normal'>
                    Our website offers a lot more than customizable brand pages
                    that allow you to create a unique exprience for your
                    customers
                  </p>
                  <p>Get discovered by Moms.</p>
                </div>
              )}
              <div className='right-width'>
                <div className='slide_brand_inner'>
                  {scrollTop <= 1230 && (
                    <div>
                      {scrollTop >= 1100 ? (
                        <animated.img src={firstup} style={secondImageProps} />
                      ) : (
                        <animated.img
                          src={firstup}
                          style={{
                            borderRadius: 8,
                            ...springs,
                          }}
                        />
                      )}{' '}
                    </div>
                  )}
                  <animated.img src={alldetails} style={firstImageProps} />
                </div>
              </div>
            </div>
          </div>
          <div className='full-width'>
            <div className='left-width'>
              <p>Highlight your uniqueness.</p>
              <h2>Managing every page with ease.</h2>
              <p id='normal'>
                You can control who sees your Page and posts, as well as view
                and edit your list of admins – the people who can make changes
                to your Page.
              </p>
              <p>Insightful analytics at your tips.</p>
            </div>
            <div className='right-width'>
              <img src={SectionTwo} />
            </div>
          </div>

          <div className='full-width'>
            <div className='left-width'>
              <p>Highlight your uniqueness.</p>
              <p>Managing every page with ease.</p>
              <h2>Insightful analytics at your tips.</h2>
              <p id='normal'>
                Track your brand page performance using real-time data and learn
                what &apos; s working well with your audience.
              </p>
            </div>
            <div className='right-width'>
              <img src={SectionThree} />
            </div>
          </div>
        </div>

        <div className='brand-page-second-section container'>
          <div className='row'>
            <div className='brand-page-section'>
              <h1>Tools for your every need</h1>
              <p>
                Elevate your brand with effective tools to grow your businesses.
              </p>
            </div>
            <img id='section-five-banner' src={SectionFive} />
          </div>
        </div>

        <div className='container'>
          <div className='full-width row'>
            <div className='left-width-discovered'>
              <Card className='inner-left-discovered'>
                <Card.Body>
                  <Card.Title className='inner-title'>
                    Get Discovered
                  </Card.Title>
                  <Card.Text className='upload-text'>
                    Upload Products. Unlock Results.
                  </Card.Text>
                  <img src={Youtube} alt='' />
                </Card.Body>
              </Card>
            </div>
            <div className='right-width-discovered'>
              <Card className='inner-right-width-discovered'>
                <Card.Body>
                  <Card.Title className='inner-title'>
                    Customer Service
                  </Card.Title>
                  <Card.Text className='upload-text'>
                    Connect with your followers and deliver seamless customer
                    service experiences.
                  </Card.Text>
                  <div className='inner-full-width'>
                    <div className='inner-left-width'>
                      <img src={ChatProfile} alt='' />
                      <p>Hi, Do you ship product to Bangalore?</p>
                    </div>
                    <div className='inner-right-width'>
                      <p>
                        Hi Cat, Thank you for your query. Yes we do ship to
                        bangalore.
                      </p>
                      <img src={ChatLogo} alt='' />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <div className='brand-page-second-section container'>
          <div className='brand-page-section-third text-center'>
            <h1>Want to do more?</h1>
            <p>
              Showcase your product on a dedicated product page to emphasise its
              unique features.
            </p>
            <button>Know More</button>
          </div>
          <img id='section-five-banner' src={Booo} className='booo' />
        </div>
        <div className='container'>
          <div className='full-width-question row'>
            <div className='left-width'>
              <div className='inner-width-collapse'>
                <h2>Have any questions?</h2>
                <ol>
                  <div className='inner-width-collapse-first-div'>
                    <span>
                      <li>Who can create brand pages?</li>
                      <button
                        onClick={collapse}
                        aria-controls='example-collapse-text'
                        aria-expanded={open}
                        className='btn-collapse'
                      >
                        <IoIosAdd className='plus' size={45} />
                        <BsX className='close' size={45} />
                      </button>
                    </span>
                    <Collapse in={open}>
                      <div id='example-collapse-text'>
                        Mompreneurs , Brand Mangers & Agencies can create brand
                        pages.
                      </div>
                    </Collapse>
                  </div>
                  <div className='inner-width-collapse-first-div'>
                    <span>
                      <li>How can moms discover my brand pages?</li>
                      <button
                        onClick={() => setOpen1(!open1)}
                        aria-controls='example-collapse-text'
                        aria-expanded={open1}
                        className='btn-collapse'
                      >
                        <IoIosAdd className='plus' size={45} />
                        <BsX className='close' size={45} />
                      </button>
                    </span>
                    <Collapse in={open1}>
                      <div id='example-collapse-text'>
                        Mompreneurs , Brand Mangers & Agencies can create brand
                        pages.
                      </div>
                    </Collapse>
                  </div>
                  <div className='inner-width-collapse-first-div'>
                    <span>
                      <li>How much does brand pages cost?</li>
                      <button
                        onClick={() => setOpen2(!open2)}
                        aria-controls='example-collapse-text'
                        aria-expanded={open2}
                        className='btn-collapse'
                      >
                        <IoIosAdd className='plus' size={45} />
                        <BsX className='close' size={45} />
                      </button>
                    </span>
                    <Collapse in={open2}>
                      <div id='example-collapse-text'>
                        Mompreneurs , Brand Mangers & Agencies can create brand
                        pages.
                      </div>
                    </Collapse>
                  </div>
                </ol>
              </div>
            </div>
            <div className='right-width' id='slideset3'>
              <img src={Rightsidepic} />
            </div>
          </div>
        </div>
        <div className='brand-page-getstarted-section '>
          <div className='container'>
            <div className='row'>
              <div className='brand-page-section '>
                <h1>Get started</h1>
                <p>
                  Create your brand page now & explore latest tips & tricks to
                  make your page successful
                </p>

                <div className='get-started-btn'>
                  <button id='btn-one'>
                    Product page <BsArrowRight />
                  </button>
                  <button id='btn-two'>Create your page</button>
                </div>
              </div>
              <div className='carousel-div'>
                <div className='inner-img-caursol'>
                  <img className='d-block' src={GetBanner} alt='First slide' />

                  <img
                    className='d-block'
                    src={GetBannerBottom}
                    alt='Second slide'
                  />
                  <img
                    className='d-block'
                    src={GetBannerBottom1}
                    alt='Third slide'
                  />
                  <img
                    className='d-block'
                    src={GetBannerBottom2}
                    alt='Fourth slide'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Brand;
