/* eslint-disable require-jsdoc */
import React, {useState, useEffect} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import propTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import TicketsApi from '../../services/TicketsApi';
import Select from 'react-select';
const categories = require('../../utils/categories.json');
export default function AddTicket(props) {
  AddTicket.propTypes = {
    show: propTypes.bool,
    handleClose: propTypes.any,
    ticket: propTypes.any,
    userDetails: propTypes.any,
    getTicket: propTypes.func,
    product: propTypes.string,
  };
  const [ticketData, setTicketData] = useState({
    title: '',
    category: {value: 'other', label: 'Other'},
    message: '',
  });
  const params = useParams();

  const handleChange = (event) => {
    console.log(event);
    setTicketData((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleSelectChange = (value, name) => {
    setTicketData((prevFormData) => {
      return {
        ...prevFormData,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (props.show === true) {
      setTicketData({
        title: '',
        category: {value: 'other', label: 'Other'},
        message: '',
      });
    }
  }, [props.show]);

  const createTicket = async (event) => {
    event.preventDefault();
    try {
      await TicketsApi.postTicket(ticketData,
          props.userDetails, params.id, props.product,
      );
      props.getTicket();
      props.handleClose();
    } catch (err) {
    }
  };

  return (
    <Modal size="lg" show={props.show}
      onHide={props.handleClose} backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Create Ticket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form method="post" onSubmit={(e) => e.preventDefault()}>
          <Form.Group className="mb-3" controlId="title">
            <Form.Label>Title</Form.Label>
            <Form.Control
              name="title"
              onChange={handleChange}
              value={ticketData.title}
              type="text"
              placeholder="Title"
            />
          </Form.Group>
          <Form.Group className='mar'>
            <Form.Label>Category</Form.Label>
            <Select
              placeholder='Select Option'
              value={ticketData.category}
              options={categories}
              onChange={(event) => handleSelectChange(
                  event, 'category',
              )}
              name='category'
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="message">
            <Form.Label>Message</Form.Label>
            <textarea className="form-control" onChange={handleChange}
              value={ticketData.message} name="message" rows="3"></textarea>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="primary" disabled={ticketData.title === '' || ticketData.message === '' ? true: false} onClick={createTicket}>
          Send
        </Button>{' '}
        <Button size="sm" variant="secondary"
          onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
