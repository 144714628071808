import React from 'react';
import {Modal} from 'react-bootstrap';
import {HiMailOpen} from 'react-icons/hi';

const ResetPassword = (prop) => {
  return (
    <Modal
      backdrop='static'
      show={prop.showResetPassword}
      onHide={() => {
        prop.setShowResetPassword(false);
      }}
    >
      <Modal.Header closeButton className='border-0 reset'>
        <Modal.Title>Email has already been sent to your account</Modal.Title>
      </Modal.Header>
      <Modal.Body className='modalBody'>
        <a
          href='https://gmail.com'
          target='_blank'
          className='gmail-open g-icon'
          rel='noreferrer'
        >
          <HiMailOpen /> Open Mail
        </a>
      </Modal.Body>
    </Modal>
  );
};

export default ResetPassword;
