import React, {useContext} from 'react';
import AppContext from './Context';

const ProgressBar = () => {
  const myContext = useContext(AppContext);
  const updateContext = myContext.userDetails;

  const percent = updateContext.currentPage * 25 + '%';
  // const percentage = updateContext.currentPage;
  const progress = {
    width: percent,
  };


  return (
    <div>
      <div className='background-progessbar'>
        <div className='progress-bar' style={progress}></div>
      </div>
    </div>
  );
};

export default ProgressBar;
