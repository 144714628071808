import axios from 'axios';

const brandlabsAuth = {
  fetchData: async function(url, method, headers, data) {
    let headerInfo = {};
    headerInfo.accesstoken = sessionStorage.getItem('accessToken');
    if (headers) {
      headerInfo = {...headerInfo, ...headers};
    }
    let response = {};
    if (data) {
      if (data.params) {
        response = await axios[method](url, {
          params: data.params,
          headers: headerInfo,
        });
      } else {
        response = await axios[method](url, data, {
          headers: headerInfo,
        });
      }
    } else {
      response = await axios[method](url, {
        headers: headerInfo,
      });
    }
    return response;
  },
};

export {brandlabsAuth};
