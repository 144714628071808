/* eslint-disable quotes */
import React, {useState} from 'react';
import {Form, Button, Collapse, Card} from 'react-bootstrap';
import DabarLalTel from '../../dashboard/assets/svg/image 209.svg';
import {AiOutlineRight} from 'react-icons/ai';
import FatherDay from '../../dashboard/assets/svg/fathersday.svg';
import FatherDayCele from '../../dashboard/assets/svg/fdaycleb.svg';
import LegoDayCamp from '../../dashboard/assets/svg/lego.svg';
import Kidzone from '../../dashboard/assets/svg/kidzone.svg';
import Toyzone from '../../dashboard/assets/svg/toyzone.svg';
import {BsFilter, BsRecordFill, BsCheck} from 'react-icons/bs';
import {MdOutlineWatchLater} from 'react-icons/md';
import {CiSearch} from 'react-icons/ci';
import {MdOutlineNavigateNext} from 'react-icons/md';

const MyCampaigns = () => {
  const [open, setOpen] = useState(false);
  const data = [
    {
      Type: 'Boost Post',
      Product: 'Dabur Lal Tel Campaign#1',
      Budget: 'Budget',
      Status: 'Active',
      Rs: '20K',
      Date: 'Start Date 3 Month',
      src: DabarLalTel,
    },
    {
      Type: 'Increase Followers',
      Product: 'Father’s Day',
      Budget: 'Budget',
      Status: 'Active',
      Rs: '20 Lakh',
      Date: 'Start Date 3 Month',
      src: FatherDay,
    },
    {
      Type: 'Ad in App Feed',
      Product: 'Father’s day Celebration #2',
      Budget: 'Budget',
      Status: 'In Review',
      Rs: '2Rs. 20 Crore 2 L...',
      Date: 'Start Date 3 Month',
      src: FatherDayCele,
    },
    {
      Type: 'Ad in Blogs',
      Product: 'Lego Day Campaign',
      Budget: 'Budget',
      Status: 'Inactive',
      Rs: '12,212',
      Date: 'Start Date: 12/12/12',
      src: LegoDayCamp,
    },
    {
      Type: 'Pop Ups',
      Product: 'Kidzone campaign #1',
      Budget: 'Budget',
      Status: 'Completed',
      Rs: '12,000',
      Date: 'End Date: 12/12/12 12 Days',
      src: Kidzone,
    },
    {
      Type: 'Pop Ups',
      Product: 'Toyzone Mania',
      Budget: 'Budget',
      Status: 'Rejected',
      Rs: '2000',
      Date: 'Start Date: 12/12/12',
      src: Toyzone,
    },
  ];

  const [search, setNewSearch] = useState('');
  const handleSearchChange = (e) => {
    setNewSearch(e.target.value);
  };

  const filtered = !search? data: data.filter((data) =>
    data.Status.toLowerCase().includes(search.toLowerCase()));

  return (
    <div className='dashboardRightDiv'>
      <div className='middleConatinerDashboard'>
        <div>
          <Form className='col-12'>
            <div className='row'>
              <div className='col-4'>
                <div className='search-input'>
                  <CiSearch id='search-icon' />
                  <Form.Group className='mb-3' controlId='formBasicEmail'>
                    <Form.Control
                      id='in'
                      type='text'
                      placeholder='Search by listing title, category, etc.'
                      value={search}
                      onChange={handleSearchChange}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className='col-2'>
                <Button
                  id='filter-btn1'
                  onClick={() => setOpen(!open)}
                  aria-controls='example-collapse-text'
                  aria-expanded={open}
                  className='filter-btn-dash'
                >
                  <MdOutlineWatchLater /> Filter By Day
                </Button>
              </div>

              <div className='col-3'>
                <Button
                  id='filter-btn'
                  onClick={() => setOpen(!open)}
                  aria-controls='example-collapse-text'
                  aria-expanded={open}
                  className='filter-btn-dash'
                >
                  <BsFilter /> Filter
                </Button>
              </div>
              <div className='col-3'>
                <Button variant='primary' className='primaryButton'>
                  Coming Soon
                </Button>
              </div>

              {/* Aman ADDED */}
              <div className='inner-soon'>
                <p>Coming soon</p>
              </div>
              {/* ENDING HERE */}
            </div>
          </Form>
        </div>
        <Collapse in={open}>
          <div className='filterButtonUpper'>
            <button className='filterButton'>Active</button>
            <button className='filterButton'>In Review</button>
            <button className='filterButton'>Inactive</button>
            <button className='filterButton'>Rejected</button>
            <button className='filterButton'>Completed</button>
          </div>
        </Collapse>
        <div className='campaign-all-element'>
          {filtered.map((value) => {
            return (
              <>
                <Card className='cardDashBoardMain'>
                  <div className='cardDashboard'>
                    <div className='first-div-camp'>
                      <img src={value.src} alt='' className='camp-img-dash' />
                      <div className='nameDasboard'>
                        <label htmlFor=''>{value.Type}</label>
                        <br />
                        <text>{value.Product}</text>
                      </div>
                    </div>

                    <div className='second-div-camp'>
                      <label htmlFor=''>{value.Budget}</label>
                      <br />
                      <text htmlFor=''>Rs. {value.Rs}</text>
                    </div>

                    <div className='third-div-camp'>
                      {value.Status === 'Active' && (
                        <button className='greenButton'>
                          {' '}
                          <BsRecordFill />
                          {value.Status}
                        </button>
                      )}
                      {value.Status === 'Inactive' && (
                        <button className='slatyButton'>{value.Status}</button>
                      )}
                      {value.Status === 'Inreview' && (
                        <button className='inreviewButton'>
                          <BsRecordFill />
                          {value.Status}
                        </button>
                      )}
                      {value.Status === 'Completed' && (
                        <button className='completedButton'>
                          <BsCheck />
                          {value.Status}
                        </button>
                      )}
                      {value.Status === 'Rejected' && (
                        <button className='rejectedButton'>
                          {value.Status}
                        </button>
                      )}

                      {value.Status === 'In Review' && (
                        <button className='inreviewButton'>
                          {value.Status}
                        </button>
                      )}
                      <br />
                      <label>{value.Date}</label>
                      <label> .12 Days</label>
                    </div>
                    <div className='arrowDiv fourth-div-camp'>
                      <div className='arrow-campaign'>
                        <MdOutlineNavigateNext className='dashBoardArrow' />
                      </div>
                      <div className='switch-campaign'>
                        <label className='switch'>
                          <input type='checkbox' checked />
                          <span className='slider round'></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </Card>
              </>
            );
          })}
        </div>
        <Card className='cardDashBoardMain'>
          <div className='cardDashboard'>
            <div>
              <img src={FatherDay} alt='' />
              <div className='nameDasboard'>
                <label htmlFor=''>Increase Followers</label>
                <br />
                <text>Father’s Day</text>
              </div>
            </div>
            <div>
              <label htmlFor=''>Budget</label>
              <br />
              <text htmlFor=''> &#8377; 20 Lakh</text>
            </div>
            <div>
              <button>. Active</button>
              <br />
              <label>Start Date: 12/12/12</label>
              <label> .12 Days</label>
            </div>
            <div className='arrowDiv'>
              <div>
                <AiOutlineRight className='dashBoardArrow' />
              </div>
              <div>
                <label className='switch'>
                  <input type='checkbox' checked />
                  <span className='slider round'></span>
                </label>
              </div>
            </div>
          </div>
        </Card>

        <Card className='cardDashBoardMain'>
          <div className='cardDashboard'>
            <div>
              <img src={DabarLalTel} alt='' />
              <div className='nameDasboard'>
                <label htmlFor=''>Name</label>
                <br />
                <text>Dabur Lal Tel Campaign#1</text>
              </div>
            </div>
            <div>
              <label htmlFor=''>Budget</label>
              <br />
              <text htmlFor=''>Rs. 20K</text>
            </div>
            <div>
              <button>. Active</button>
              <br />
              <label>Start Date: 12/12/12</label>
              <label> .12 Days</label>
            </div>
            <div className='arrowDiv'>
              <div>
                <AiOutlineRight className='dashBoardArrow' />
              </div>
              <div>
                <label className='switch'>
                  <input type='checkbox' checked />
                  <span className='slider round'></span>
                </label>
              </div>
            </div>
          </div>
        </Card>

        <Card className='cardDashBoardMain'>
          <div className='cardDashboard'>
            <div>
              <img src={DabarLalTel} alt='' />
              <div className='nameDasboard'>
                <label htmlFor=''>Name</label>
                <br />
                <text>Dabur Lal Tel Campaign#1</text>
              </div>
            </div>
            <div>
              <label htmlFor=''>Budget</label>
              <br />
              <text htmlFor=''>Rs. 20K</text>
            </div>
            <div>
              <button>. Active</button>
              <br />
              <label>Start Date: 12/12/12</label>
              <label> .12 Days</label>
            </div>
            <div className='arrowDiv'>
              <div>
                <AiOutlineRight className='dashBoardArrow' />
              </div>
              <div>
                <label className='switch'>
                  <input type='checkbox' checked />
                  <span className='slider round'></span>
                </label>
              </div>
            </div>
          </div>
        </Card>

        <Card className='cardDashBoardMain'>
          <div className='cardDashboard'>
            <div>
              <img src={DabarLalTel} alt='' />
              <div className='nameDasboard'>
                <label htmlFor=''>Name</label>
                <br />
                <text>Dabur Lal Tel Campaign#1</text>
              </div>
            </div>
            <div>
              <label htmlFor=''>Budget</label>
              <br />
              <text htmlFor=''>Rs. 20K</text>
            </div>
            <div>
              <button>. Active</button>
              <br />
              <label>Start Date: 12/12/12</label>
              <label> .12 Days</label>
            </div>
            <div className='arrowDiv'>
              <div>
                <AiOutlineRight className='dashBoardArrow' />
              </div>
              <div>
                <label className='switch'>
                  <input type='checkbox' checked />
                  <span className='slider round'></span>
                </label>
              </div>
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default MyCampaigns;
