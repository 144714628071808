import React, {Fragment, useState} from 'react';
import {Card, Button, Table, Badge} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import PaymentApi from '../../services/PaymentApi';
import {Link} from 'react-router-dom';
import errorHandling from '../../errorhandling/ErrorHandle';
import PayPal from '../../services/PayPal';
import moment from 'moment';
import ProfileApi from '../../services/ProfileApi';
import Spinner from '../../services/Spinner';
import {useQuery, useQueryClient} from 'react-query';
import deleteMessage from '../../services/DeleteMessage';
import EmailAddsApi from '../../services/EmailAddsApi';
// import getErrorMessages from '../../errorhandling/ErrorMessages';

const EmailAdds = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [addId, setAddId] = useState('');
  const [amount, setAmount] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const queryClient = useQueryClient();

  const {isLoading, data, isError} =
  useQuery('emailAdds', EmailAddsApi.getEmailAdds,
      {refetchOnWindowFocus: true});

  const userDataDetails = useQuery('userDetails', ProfileApi.getuserDetail,
      {enabled: false});
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
  }
  const handleAdd = () => {
    navigate('create-add', {replace: true});
  };

  const handleEdit = (event, id) => {
    event.preventDefault();
    navigate('edit-add/' + id, {replace: true});
  };
  const handleDelete = async (event, id) => {
    event.preventDefault();
    const deleteParams = {
      title: 'Are you sure?',
      text: 'You want to delete this Add?',
    };
    const deleteData = await deleteMessage(deleteParams);
    if (deleteData.isConfirmed) {
      await EmailAddsApi.deleteAddById(id);
      queryClient.invalidateQueries('emailAdds');
    }
  };

  if (isError) {
    // return getErrorMessages(error);
    return null;
  }
  return (
    <Fragment>
      <div className="container dashboardRightDiv">
        <div className="middleConatinerDashboard">
          {isLoading ?
            <Spinner />:
              <Card>
                <Card.Header>
                  <h3>
                  Email Adds
                    <Button
                      variant="outline-primary"
                      className="primaryButton btn btn-primary float-end"
                      onClick={handleAdd}
                    >
                    Create Email Add
                    </Button>
                  </h3>
                </Card.Header>
                <Card.Body>
                  {/* {data && data.data.data.length === 0 ?
                  (userData && userData.c_code &&
                    userData.c_code === 'IN') ?
                  <img
                    src={process.env.PUBLIC_URL + '/images/indiabanner.png'}
                    alt="image"/> :
                  <img
                    src ={process.env.PUBLIC_URL +
                    '/images/othercountrybanner.png'}
                    alt="image"/>: */}
                  <Table className="table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.data.data.list.map((emailAdd, i) => {
                        return (
                          <tr key={i + 1}>
                            <td width="50%">
                              <Link className="anchor-decoration"
                                to={'detail/' + emailAdd._id}>
                                <h4>{emailAdd.title}</h4>
                              </Link>
                            </td>
                            <td>
                              <Badge
                                bg={
                                emailAdd.status === 'inReview' ?
                                  'warning' :
                                  emailAdd.status === 'approved' ?
                                  'info' :
                                  emailAdd.status === 'paid' ?
                                  'info' :
                                  emailAdd.status === 'published' ?
                                  'success' :
                                  emailAdd.status === 'rejected' ?
                                  'danger':
                                  emailAdd.status === 'clarification' ?
                                  'warning' :
                                  'secondary'
                                }
                              >
                                {emailAdd.status.charAt(0).toUpperCase() +
                                emailAdd.status.slice(1)}
                              </Badge>{' '}
                            </td>
                            <td>
                              {
                                moment(emailAdd.created_at)
                                    .format('DD/MM/YYYY')
                              }
                            </td>
                            <td>
                              <Button
                                disabled={
                                  emailAdd.status === 'draft' ||
                                  emailAdd.status === 'clarification' ?

                                    false :
                                    true
                                }
                                size="sm"
                                variant="outline-secondary"
                                onClick={(event) =>
                                  handleEdit(event, emailAdd._id)
                                }
                              >
                                  Edit
                              </Button>{' '}
                              <Button
                                size="sm"
                                disabled={
                                    emailAdd.status === 'draft' ||
                                    emailAdd.status === 'clarification' ?
                                      false :
                                      true
                                }
                                variant="outline-secondary"
                                onClick={(event) =>
                                  handleDelete(event, emailAdd._id)
                                }
                              >
                                  Delete
                              </Button>{' '}
                              <Button
                                size="sm"
                                disabled={
                                    emailAdd.status === 'approved' ?
                                      false :
                                      true
                                }
                                variant="outline-danger"
                                // onClick={(event) =>
                                //   handlePayment(
                                //       event,
                                //       emailAdd._id,
                                //       emailAdd.phone,
                                //       emailAdd.amount,
                                //   )
                                // }
                              >
                                <Link className="anchor-decoration"
                                  to={'detail/' + emailAdd._id}>
                                  Pay
                                </Link>
                              </Button>
                            </td>

                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  {/* } */}
                </Card.Body>
              </Card>
          }
        </div>
      </div>
      <PayPal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        addId={addId}
        userDetails={userData}
        amount={amount}

      />
    </Fragment>
  );
};

export default EmailAdds;
