import 'react-toastify/dist/ReactToastify.css';
import errorHandling from './ErrorHandle';

// eslint-disable-next-line require-jsdoc
function getErrorMessages(err, data) {
  if (err.response) {
    errorHandling.handleErrors(err.response.data.errorMsg, data);
    return err.response.data.errorMsg;
  } else {
    errorHandling.handleErrors('Server error');
    return;
  }
}


export default getErrorMessages;
