/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
import React, {Fragment, useEffect, useState} from 'react';
import {Card, Table, Button} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import moment from 'moment';
import './style.css';
import TicketsApi from '../../services/TicketsApi';
import Messages from '../tickets/Messages';
import ProfileApi from '../../services/ProfileApi';
import {useQuery} from 'react-query';

const TicketDetails = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [ticketDetails, setTicketDetails] = useState({
    title: '',
    created_at: '',
    status: '',
    id: '',
    assignee: {userId: '', userName: ''},
  });
  const [showMessage, setShowMessage] = useState(false);
  const handleCloseMessage = () => setShowMessage(false);
  const handleShowMessage = () => setShowMessage(true);
  const userDataDetails = useQuery('userDetails', ProfileApi.getuserDetail, {
    enabled: false,
  });
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
    userData.fullName = userDataDetails.data.data.data[0].full_name;
  }
  useEffect(() => {
    getTicket();
  }, []);

  const getTicket = async () => {
    const data = await TicketsApi.getTicketById(params.id);
    if (data) {
      setTicketDetails(data.data);
    }
  };
  const handleBack = () => {
    navigate('/dashboard/support');
  };
  return (
    <Fragment>
      <div className="container dashboardRightDiv">
        <div className="row middleConatinerDashboard">
          <div className="col-md-6">
            <Card>
              <Card.Header>Ticket Details
                <Button
                  variant="outline-secondary"
                  className="float-end"
                  onClick={handleBack}
                >
                Back
                </Button>
              </Card.Header>
              <Card.Body>
                <Table className="striped bordered hover">
                  <tbody>
                    <tr><td>Ticket Name</td><td>
                      {ticketDetails.title}</td></tr>
                    <tr><td>Assignee</td><td>
                      {ticketDetails.assignee.userId === '' ? 'Admin': ticketDetails.assignee.userName}</td></tr>
                    <tr><td>Status</td>
                      <td>{ticketDetails.status.charAt(0).toUpperCase() +
                    ticketDetails.status.slice(1)}</td></tr>
                    <tr><td>Created Date</td><td>
                      {moment(ticketDetails.created_at).
                          format('DD/MM/YYYY')}</td></tr>

                    <tr><td>Messages</td><td>
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick= {handleShowMessage}
                      >
                        View
                      </Button>
                    </td></tr>

                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </div>
        </div>
        <Messages
          show={showMessage}
          handleClose={handleCloseMessage}
          handleShow={handleShowMessage}
          userDetails={userData}
          ticketId={params.id}
        />
      </div>
    </Fragment>
  );
};

export default TicketDetails;
