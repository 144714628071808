import React, {useState} from 'react';
import OtpInput from 'react18-input-otp';
import {Button, Modal, Form} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import ProfileApi from '../services/ProfileApi';

const VerifyPhone = (prop) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [OTP, setOTP] = useState('');
  const phoneNumber = sessionStorage.getItem('phone_number');

  const handleVerify =async (e) => {
    e.preventDefault();
    const data = {
      phone_number: phoneNumber,
      otp: OTP,
    };
    ProfileApi.verifyOtp(data)
        .then(function(response) {
          if (response.data.status == true) {
            navigate('/on-boarding');
            Swal.fire({
              icon: 'success',
              title: 'Account created successfully!',
              showConfirmButton: false,
              timer: 2000,
            });
          } else if (response.data.status == false) {
            prop.setPhoneOtpShow(true);
            Swal.fire({
              icon: 'error',
              title: 'Oops! wrong OTP',
              showConfirmButton: false,
              timer: 1000,
            });
          } else {
            prop.setPhoneOtpShow(false);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
  };

  function handleChange (OTP) {
    setFormData({...formData, ['otp_entered_by_user']: OTP});
    setOTP(OTP);
  }
  return (
    <Modal
      backdrop='static'
      show={prop.phoneOtpShow}
      onHide={() => {
        prop.setPhoneOtpShow(false);
      }}
    >
      <Modal.Header closeButton className='border-0'></Modal.Header>
      <Modal.Body>
        <Form.Label>Enter Verification code</Form.Label>
        <Form>
          <Form.Label id='mail-ga'>
            We have sent you OTP on +{phoneNumber}
          </Form.Label>
          <Form.Group
            className='mb-3 otpNumber'
            controlId='exampleForm.ControlInput1'
          >
            <div className='otp'>
              <OtpInput
                value={OTP}
                onChange={handleChange}
                numInputs={5}
                otpType='number'
                inputStyle='inputStyle'
                secure
                name='otp_entered_by_user'
              />
            </div>
          </Form.Group>
          <Button
            variant='danger'
            className='col-12 customPopupBtn'
            onClick={handleVerify}
          >
            Verify
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default VerifyPhone;
