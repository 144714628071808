import React, {useContext} from 'react';
import AppContext from '../dashboard/dashboardPages/createpages/Context';

const ProgresOnboarding = () => {
  const myContext = useContext(AppContext);
  const updateContext = myContext.userDetails;
  const percent = updateContext.currentPage * 50 + '%';
  const progress = {
    width: percent,
  };

  return (
    <div>
      <div className='background-progessbar-on'>
        <div className='progress-bar' style={progress}></div>
      </div>
    </div>
  );
};

export default ProgresOnboarding;
