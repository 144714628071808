import errorHandling from '../errorhandling/ErrorHandle';
import {brandlabsAuth} from './BrandlabsAuth';
import apiUrl from '../utils/brandlabapi';
import api from '../utils/api';
import axios from 'axios';

export default {
  // get profile Details
  getuserDetail: async () => {
    try {
      const response = await brandlabsAuth.
          fetchData(apiUrl.userDetailsApi, 'get');
      return response;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  userSignin: async (data)=>{
    try {
      const response = api
          .post('/BptglobalUser/global-settings-user-signin', data, {
            headers: {
              'accesstoken': process.env.
                  REACT_APP_Global_Token,
              'Content-Type': 'application/json',
            },
          });
      return response;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  userExists: async (data)=>{
    try {
      const response = api
          .post('/BptglobalUser/global_settings_user_exists', data, {
            headers: {
              'Accesstoken': process.env.REACT_APP_Global_Token,
              'Content-Type': 'application/json',
            },
          });
      return response;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },
  // global_settings_user_add
  registerUserAdd: async (data)=>{
    try {
      const response = api
          .post('/BptglobalUser/global_settings_user_add', data, {
            headers: {
              'accesstoken': process.env.REACT_APP_Global_Token,
              'Content-Type': 'application/json',
            },
          });
      return response;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },


  newRegisterUserAdd: async (data)=>{
    try {
      const response = await axios
          .post('https://core.dev.beingtheparent.com/api/users/v1/register', data, {
            headers: {
              // accesstoken: process.env.REACT_APP_Global_Token,
              'Content-Type': 'application/json',
            },
          });
      return response;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  updateuserDetails: async (data)=>{
    try {
      const response = axios
          .post(process.env.REACT_APP_Btp_Global_Api +
            'Btpmobileapi/updateuser-details', data, {
            headers: {
              'accesstoken': sessionStorage.getItem('accessToken'),
              'Content-Type': 'application/json',
            },
          });
      return response;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  sendOtp: async (data)=>{
    try {
      const response = axios
          .post(process.env.REACT_APP_Btp_Global_Api +
             '/BptglobalUser/send_otp', data, {
            headers: {
              'accesstoken': sessionStorage.getItem('accessToken'),
              'Content-Type': 'application/json',
            },
          });
      return response;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  verifyOtp: async (data)=>{
    try {
      const response = axios
          .post( process.env.REACT_APP_Btp_Global_Api +
             'BptglobalUser/verify_otp', data, {
            headers: {
              'accesstoken': sessionStorage.getItem('accessToken'),
              'content-type': 'application/json',
            },
          });
      return response;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  globalSettingsUserVerify: async (data)=>{
    try {
      const response = api
          .post('/BptglobalUser/global_settings_user_verify', data, {
            headers: {
              'Accesstoken': process.env.REACT_APP_Global_Token,
              'Content-Type': 'text/plain',
            },
          });
      return response;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  // resend_verification_pin
  resendVerificationPin: async (data)=>{
    try {
      const response =
      api.post('/BptglobalUser/global_settings_resend_verification_pin', data, {
        headers: {
          'Accesstoken': process.env.REACT_APP_Global_Token,
          'Content-Type': 'text/plain',
        },
      });
      return response;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },
  forgetPassword: async (data)=>{
    try {
      const response =
      api.post('BptglobalUser/global_settings_user_forgot_password', data, {
        headers: {
          'Accesstoken': process.env.REACT_APP_Global_Token,
          'Content-Type': 'text/plain',
        },
      });
      return response;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },
};

