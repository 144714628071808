/* eslint-disable require-jsdoc */
import React, {useEffect, useState} from 'react';
import CreatePage1 from './CreatePage1';
import CreatePage2 from './CreatePage2';
import CreatePage3 from './CreatePage3';
import CreatePage4 from './CreatePage4';
import Header from '../../Header';
import AppContext from './Context';
import ProgressBar from './ProgressBar';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';
import ProfileApi from '../../../services/ProfileApi';
import {useQuery} from 'react-query';
import MyPagesApi from '../MyPagesApi';
import errorHandling from '../../../errorhandling/ErrorHandle';

const CreatePage = (prop) => {
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [brandName, setBrandName] = useState('');
  const navigate = useNavigate();
  const [logoFile, setLogoFile] = useState('');
  const [bannerLogo, setBannerLogo] = useState('');
  const [productData, setProductData] = useState({});
  const [productFile, setProductFile] = useState('');
  const [brandData, setBrandData] = useState({});
  const [brandMediaFile, setBrandMediaFile] = useState('');
  const userDataDetails = useQuery('userDetails', ProfileApi.getuserDetail, {
    enabled: false,
  });
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
  }
  console.log(userData.sk_user_id);
  const [formData, setFormData] = useState({
    user_id: userData.sk_user_id,
    brand_name: '',
    website_link: '',
    categories: '',
    brand_logo: '',
    brand_banner: '',
  });


  // for getting userDetails


  useEffect(() => {
    if (location.state != null && location.state.key == 'product') {
      setStep(4);
    }
    if (location.state != null && location.state.key == 'addproduct') {
      setStep(4);
    }

    if (location.state != null && location.state.key == 'addsection') {
      setStep(3);
    }
  }, []);

  async function productSubmit() {
    const data = {
      product_logo: productFile,
      product_name: productData.product_name,
      product_introduction: productData.product_introduction,
      page_id: sessionStorage.getItem('page_id'),
      product_categories: productData.product_categories,
      user_id: userData.sk_user_id,
      product_status: 'active',
    };
    try {
      await MyPagesApi.createProduct(data);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  }


  useEffect(() => {
    prop.setShow(false);
  }, []);


  const userDetails = {
    currentPage: step,
    brand_name: brandName,
    setStep,
    setBrandName,
  };

  useEffect(() => {
    if (sessionStorage.getItem('username') == null) {
      navigate('/');
    }
  }, []);

  window.addEventListener('storage', (event) => {
    if (event.key == 'logout' && event.newValue) {
      sessionStorage.removeItem('logged-in');
      localStorage.removeItem('logout');
      window.location.href = '/login';
    }
  });

  const upadtePages = async () => {
    const data = {
      brand_banner: bannerLogo,
      brand_logo: logoFile,
      brand_name: formData.brand_name,
      website_link: formData.website_link,
      categories: formData.categories,
      page_status: 'active',
      user_id: userData.sk_user_id,
    };
    try {
      await MyPagesApi.pageUpdate(data, location.state._id);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  };

  const updateProduct = async () => {
    const data = {
      product_logo: productFile,
      product_name: productData.product_name,
      product_introduction: productData.product_introduction,
      page_id: location.state.product.page_id,
      product_categories: productData.product_categories,
      user_id: userData.sk_user_id,
    };
    try {
      await MyPagesApi.productUpdate(data, location.state.product._id);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  };

  const addProduct = async () => {
    const data = {
      product_logo: productFile,
      product_name: productData.product_name,
      product_introduction: productData.product_introduction,
      page_id: location.state.page_id,
      product_categories: productData.product_categories,
      user_id: userData.sk_user_id,
      product_status: 'active',
    };
    try {
      await MyPagesApi.createProduct(data);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  };

  async function submitData() {
    const data = {
      brand_banner: bannerLogo,
      brand_logo: logoFile,
      brand_name: formData.brand_name,
      website_link: formData.website_link,
      categories: formData.categories,
      page_status: 'active',
      user_id: userData.sk_user_id,
    };
    try {
      const res = await MyPagesApi.createPages(data);
      sessionStorage.setItem('page_id', res.data._id);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  }

  async function submitBrandPage() {
    const data = {
      section_media: brandMediaFile,
      section_description: brandData.section_description,
      section_title: brandData.section_title,
      pageId: sessionStorage.getItem('page_id'),
      page_status: 'active',
      userId: userData.sk_user_id,
    };
    try {
      await MyPagesApi.createSection(data);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  }


  async function addSection() {
    const data = {
      section_media: brandMediaFile,
      section_description: brandData.section_description,
      section_title: brandData.section_title,
      page_status: 'active',
      userId: userData.sk_user_id,
      pageId: location.state.page_id,
    };
    try {
      await MyPagesApi.createSection(data);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  }

  // const getOnboarding= async ()=>{
  //   await MyPagesApi.getOnboarding(userData.sk_user_id)
  //       .then((reen) => {
  //         console.log(reen);
  //         if (reen.data.message == 'failed') {
  //           navigate('/on-boarding');
  //         }
  //       })
  //       .catch((er) => {
  //         console.log(er);
  //       });
  // };

  // useEffect(()=>{
  //   getOnboarding();
  // }, []);

  return (
    <>
      <div className="mainPageDashboard">
        <Header />
      </div>
      <AppContext.Provider
        value={{
          userDetails,
          formData,
          setFormData,
          submitData,
          logoFile,
          setLogoFile,
          bannerLogo,
          setBannerLogo,
          brandData,
          setBrandData,
          brandMediaFile,
          setBrandMediaFile,
          submitBrandPage,
          productData,
          setProductData,
          productFile,
          setProductFile,
          productSubmit,
          upadtePages,
          updateProduct,
          addProduct,
          logoFile,
          bannerLogo,
          addSection,
        }}
      >
        <div className="create-page-onboarding">
          <div className="card create-page">
            {step === 2 && <CreatePage2 />}
            {step === 3 && <CreatePage3 />}
            {step === 4 && <CreatePage4 />}
            <ProgressBar />
            {step === 1 && <CreatePage1 />}
          </div>
        </div>
      </AppContext.Provider>
    </>
  );
};

export default CreatePage;
