/* eslint-disable react/no-unknown-property */
import React, {memo, Fragment, useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import propTypes from 'prop-types';
import './style.css';
import 'rc-slider/assets/index.css';
const Slider = require('rc-slider');

const WeekSelector = (props) => {
  WeekSelector.propTypes = {
    weeks: propTypes.any,
    getWeeks: propTypes.any,
  };
  const createSliderWithTooltip = Slider.createSliderWithTooltip;
  const Range = createSliderWithTooltip(Slider.Range);
  const [weeks, setWeeks] = useState([0, 40]);

  useEffect(() => {
    setWeeks(props.weeks);
  }, [props.weeks]);

  const onChangeRange = (value) => {
    setWeeks(value);
    props.getWeeks(value);
  };
  return (
    <Fragment>
      <Form.Group className="mb-3" controlId="url">
        <Form.Label>Pregnancy Week</Form.Label>
        <Range className="t-slider range-slider"
          min={0} max={40} range onChange={onChangeRange}
          value={weeks} tipProps={{visible: true}}
        />
      </Form.Group>
    </Fragment>
  );
};

export default memo(WeekSelector);
