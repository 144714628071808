/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import { BsRecordFill } from 'react-icons/bs';
import Accordion from 'react-bootstrap/Accordion';
import { CiSearch } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import NoPageFound from '../../dashboard/assets/img/nopage.png';
import Filterday from './Filterday';
import ProfileApi from '../../services/ProfileApi';
import brandlabapi from '../../utils/brandlabapi';
import { MdOutlineDeleteOutline } from 'react-icons/md';
import MyPagesApi from './MyPagesApi';
import { useQuery } from 'react-query';
import deleteMessage from '../../services/DeleteMessage';
import { BsPlusSquare } from 'react-icons/bs';
import errorHandling from '../../errorhandling/ErrorHandle';
import moment from 'moment-timezone';

// import {BiMailSend} from 'react-icons/bi';
// import DefaultAvatar from '../../dashboard/assets/img/profile.png';
// import Collapse from 'react-bootstrap/Collapse';
// import {IoIosClose} from 'react-icons/io';
// import axios from 'axios';
// import Col from 'react-bootstrap/Col';
// import InputGroup from 'react-bootstrap/InputGroup';
// import Row from 'react-bootstrap/Row';
// import { AiOutlineSend } from 'react-icons/ai';


const MyPages = () => {
  const navigate = useNavigate();
  const [filterDay, setFilterDay] = useState(false);
  // const [readMessage, setReadMessage]=useState([]);
  // const [open, setOpen] = useState(false);
  const userDataDetails = useQuery('userDetails', ProfileApi.getuserDetail, {
    enabled: false,
  });
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
  }

  // console.log(userData.sk_user_id);

  // const handleClose=()=> {
  //   setOpen(false);
  // };

  // const [userID, setUserId]=useState('');
  // console.log(userID);
  const getUserDeatils = async () => {
    await ProfileApi.getuserDetail()
      .then((resn) => {
        // console.log(resn);
        // setFullname(resn.data.data[0].full_name);
        // console.log(resn.data.data[0].sk_user_id);
        // setUserId(resn.data.data[0].sk_user_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserDeatils();
  }, []);

  // console.log(userData.sk_user_id);

  const [productData, setProductData] = useState([]);
  const [arrayData, setArrayData] = useState([]);
  const [totalPage, setTotalPages] = useState();

  const getPagesData = async () => {
    try {
      const res = await MyPagesApi.getPages();
      setTotalPages(res.data.totalPages);
      setFetchedData(res.data.data);
      setArrayData(res.data.data);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  };


  const getProductData = async () => {
    try {
      const res = await MyPagesApi.getProductWid();
      console.log(res);
      setProductData(res.data.data);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  };



  useEffect(() => {
    getPagesData();
    getProductData();
  }, []);

  const handleCreatePage = () => {
    navigate('/create-page');
  };

  const [searchText, setSearchText] = useState();
  const [fetchedData, setFetchedData] = useState([]);
  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value == '') {
      setArrayData(fetchedData);
    }
    filterData(value);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    filterData(searchText);
  };

  const filterData = (value) => {
    const val = value.toLowerCase().trim();
    if (!val) {
      setArrayData(arrayData);
    } else {
      const filteredData = fetchedData.filter((item) => {
        return Object.keys(item).some((key) => {
          return item[key].toString().toLowerCase().includes(val);
        });
      });
      setArrayData(filteredData);
    }
  };

  const handleToggleChange = async (e, val) => {
    e.preventDefault();
    let value = '';
    if (e.target.checked) {
      value = 'active';
    } else {
      value = 'inactive';
    }
    const data = {
      page_status: value,
    };
    await MyPagesApi.pageUpdate(data, val._id);
    getPagesData();
  };

  const handleToggleChangeProduct = async (e, val) => {
    e.preventDefault();
    let value = '';
    if (e.target.checked) {
      value = 'active';
    } else {
      value = 'inactive';
    }
    const data = {
      product_status: value,
    };
    await MyPagesApi.productUpdate(data, val._id);
    getProductData();
  };

  const handleClickDelete = async (e, val) => {
    const deleteParams = {
      title: 'Are you sure ?',
      text: 'You want to delete this page ?',
    };
    const deleteData = await deleteMessage(deleteParams);
    if (deleteData.isConfirmed) {
      try {
        const res = await MyPagesApi.pageDelete(val._id);
        if (res.status == 200) {
          getPagesData();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleClickDeleteProduct = async (e, val) => {
    const deleteParams = {
      title: 'Are you sure ?',
      text: 'You want to delete this product ?',
    };
    const deleteData = await deleteMessage(deleteParams);
    if (deleteData.isConfirmed) {
      try {
        const res = await MyPagesApi.productDelete(val._id);
        if (res.status == 200) {
          getProductData();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleEdit = (value) => {
    navigate('/create-page', { state: value });
  };

  const handleEditProduct = (value) => {
    navigate('/create-page', { state: { product: value, key: 'product' } });
  };

  const handleAddProduct = (value) => {
    navigate('/create-page', {
      state: {
        product: value,
        key: 'addproduct',
        page_id: value._id,
      },
    });
  };

  const handleAddSection = (value) => {
    navigate('/create-page', {
      state: {
        product: value,
        key: 'addsection',
        page_id: value._id,
      },
    });
  };


  // Harcoded because some api problem
  const getMessageApi = async () => {
    // await MyPagesApi.getMessage(userData.sk_user_id).then((res)=>{
    //   console.log(res);
    // }).catch((err)=>{
    //   console.log(err);
    // });
    // console.log(res);
    // setReadMessage(res.data);
    // console.log(res.data);
  };


  useEffect(() => {
    getMessageApi();
  }, []);

  // const [dataVlaue, setDataValue]=useState({});
  // const handleOpen=(value)=>{
  // console.log(value);
  // setOpen(true);
  // setDataValue(value);
  // };


  return (
    <>
      {filterDay && (
        <Filterday setFilterDay={setFilterDay} filterDay={filterDay} />
      )}
      <div className='dashboardRightDiv'>
        <div className='middleConatinerDashboard'>
          <div className='top-btn'>
            <Form className='col-12'>
              <div className='row'>
                <div className='col-10'>
                  {totalPage != 0 &&
                    <div className='search-input'>
                      <Form.Group >
                        <Form.Control
                          id='in'
                          type='text'
                          placeholder='Search by listing title, category, etc.'
                          value={searchText}
                          onChange={handleSearch}
                          name="search"
                        />
                      </Form.Group>
                      {/* onClick={handleSearchClick} */}
                      <CiSearch id='search-icon' />
                    </div>}
                </div>
                <div className='col-2'>
                  <Button
                    variant='primary'
                    className='primaryButton float-end'
                    onClick={handleCreatePage}
                  >
                    Create new page
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          {arrayData?.map((value, indx) => {
            return (
              <>
                {userData.sk_user_id == value.user_id && (
                  <div key={indx}>
                    <Card className='cardDashBoardMain ' id={value.page_status}>
                      <div className='cardDashboard '>
                        <div className='left-image-dash'>
                          <img
                            src={
                              brandlabapi.mediaURL +
                              'api/media/v3/no-auth?key=' +
                              value.brand_logo
                            }
                            alt=''
                          />
                          <div className='nameDasboard'>
                            <label>Brand</label>
                            <br />
                            <text>{value.brand_name}</text>
                          </div>
                        </div>
                        {/* <div className='fallowers-card'>
                          <label>Add New</label>

                          <br />
                          <text>123</text>
                        </div> */}
                        {/* <div className='posts-card'>
                          <label>Posts</label>
                          <br />
                          <text>120</text>
                        </div> */}
                        <div className='fifth-div'>
                          {
                            <button className={value.page_status}>
                              <BsRecordFill />
                              {value.page_status}
                            </button>
                          }
                          <br />
                          <div>
                            <label id='date-time'>
                              {moment.utc(value.brand_page_date).
                                tz('Asia/Kolkata').
                                format('DD-MM-YYYY')}
                            </label>
                          </div>
                        </div>
                        <div className='arrowDiv sixth-div'>
                          <Button
                            id='edit_btn'
                            onClick={() => handleEdit(value)}
                          >
                            Edit
                          </Button>
                          <div>
                            {value.page_status == 'active' && (
                              <div className='d-flex ai-center g8'>
                                <label className='s-label'></label>
                                <Form.Check
                                  type='switch'
                                  id='custom-switch'
                                  checked={
                                    value.page_status == 'active' ? true : false
                                  }
                                  onChange={(e) => handleToggleChange(e, value)}
                                />
                              </div>
                            )}
                            {value.page_status == 'inreview' && (
                              <div className='d-flex ai-center g8 disable'>
                                <label className='s-label'></label>
                                <Form.Check
                                  type='switch'
                                  id='custom-switch'
                                  checked={
                                    value.page_status == 'active' ? true : false
                                  }
                                  onChange={(e) => handleToggleChange(e, value)}
                                />
                              </div>
                            )}
                            {value.page_status == 'inactive' && (
                              <div className='d-flex ai-center g8'>
                                <label className='s-label'></label>
                                <Form.Check
                                  type='switch'
                                  id='custom-switch'
                                  checked={
                                    value.page_status == 'active' ? true : false
                                  }
                                  onChange={(e) => handleToggleChange(e, value)}
                                />
                              </div>
                            )}
                            <MdOutlineDeleteOutline
                              id='delete'
                              name='delete'
                              onClick={(e) => handleClickDelete(e, value)}
                            />
                          </div>
                        </div>
                      </div>
                      <Accordion className='border-0'>
                        <Accordion.Item eventKey='0' className='border-0'>
                          <Accordion.Header>
                            See all products
                            <Button
                              className='add-new section'
                              onClick={() => handleAddSection(value)}
                              style={{ marginLeft: '-100px' }}
                            >
                              <BsPlusSquare className='add-icon' />
                              Section
                            </Button>
                            <Button
                              className='add-new'
                              onClick={() => handleAddProduct(value)}
                              style={{ marginLeft: '-20px' }}
                            >
                              <BsPlusSquare className='add-icon' />
                              Product
                            </Button>
                          </Accordion.Header>

                          <Accordion.Body>
                            {productData?.map((val, index) => {
                              return (
                                <>
                                  {value._id == val.page_id && (
                                    <div key={index}>
                                      <Card className='product_card'>
                                        <div className='cardDashboard'>
                                          <div>
                                            <div id='nameDasboard'>
                                              <label>Product Title</label>
                                              <br />
                                              <text>{val.product_name}</text>
                                            </div>
                                          </div>
                                          <div>
                                            <label>Categories</label>
                                            <br />
                                            <text>
                                              {' '}
                                              {val.product_categories}
                                            </text>
                                          </div>
                                          <div style={{ textAlign: "center" }}>
                                            <button
                                              className={val.product_status}
                                            >
                                              {val.product_status}
                                            </button>
                                            <br />
                                            <label>
                                              {moment.utc(val.product_date_create).
                                                tz('Asia/Kolkata').
                                                // format('YYYY-MM-DD HH:mm:ss')}
                                                format('DD-MM-YYYYY')}
                                            </label>
                                          </div>

                                          <div>
                                            <Button
                                              id='edit_btn'
                                              onClick={() =>
                                                handleEditProduct(val)
                                              }
                                              style={{ marginLeft: '-20px' }}
                                            >
                                              Edit
                                            </Button>
                                            <br />
                                            {val.product_status == 'active' &&
                                              (<div className='d-flex
 ai-center g8 product_tog'><label className='s-label'></label><Form.Check
                                                  type='switch'
                                                  id='custom-switch'
                                                  checked={val.product_status ==
                                                    'active' ?
                                                    true :
                                                    false
                                                  }
                                                  onChange={(e) =>
                                                    handleToggleChangeProduct(
                                                      e, val)}
                                                />
                                              </div>
                                              )}
                                            {val.product_status ==
                                              'inreview' && (
                                                <div className='d-flex ai-center
g8 disable product_tog'>
                                                  <label className='s-label'></label>
                                                  <Form.Check
                                                    type='switch'
                                                    id='custom-switch'
                                                    checked={
                                                      val.product_status ==
                                                        'active' ?
                                                        true :
                                                        false
                                                    }
                                                    onChange={(e) =>
                                                      handleToggleChangeProduct(
                                                        e, val)
                                                    }
                                                  />
                                                </div>
                                              )}
                                            {val.product_status ==
                                              'inactive' && (
                                                <div className='d-flex ai-center
                                       g8 product_tog'>
                                                  <Form.Check
                                                    type='switch'
                                                    id='custom-switch'
                                                    checked={
                                                      val.product_status ==
                                                        'active' ? true :
                                                        false
                                                    }
                                                    onChange={(e) =>
                                                      handleToggleChangeProduct(
                                                        e, val)}
                                                  />
                                                </div>
                                              )}
                                            <MdOutlineDeleteOutline
                                              id='delete'
                                              name='delete'
                                              onClick={(e) =>
                                                handleClickDeleteProduct(e, val)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </Card>
                                    </div>
                                  )}
                                </>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Card>
                  </div>
                )}
              </>
            );
          })}

          {arrayData.length == '0' && (
            <div className='NoPage'>
              <img id='nopage' src={NoPageFound} />
              <p>Sorry, No page found :(</p>
            </div>
          )}
        </div>
      </div>

      {/* <div className='messagePopup'>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <p>Messages</p>
              <BiMailSend id="messageIcon" />
            </Accordion.Header>
            <Accordion.Body>
              {
                readMessage.map((value, index)=>{
                  return (
                    <>
                      <div className='userMessgaesDiv'
                        onClick={() => handleOpen(value)}
                        aria-controls="example-collapse-text"
                        aria-expanded={open} key={index}>
                        <img src={DefaultAvatar} />
                        <p
                        >
                          {value.full_name}
                        </p>
                        <p id="time"></p>
                      </div>
                    </>
                  );
                })
              }

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div> */}
      {/* <div >
        <div style={{minHeight: '300px'}} className='messagePopup_side'>
          <>
            <Collapse in={open} dimension="width">
              <div id="example-collapse-text">
                <Card className='messageInput'>
                  <div className='userMessgaesDiv clicked'>
                    <img src={DefaultAvatar} />
                    <p>
                      {dataVlaue.full_name}
                    </p>
                    <p id="time"></p>
                    <div className='close'>
                      <IoIosClose onClick={handleClose}/>
                    </div>
                  </div>
                  <div className='messagePart'>
                    <div className='userMessage'>
                      <p>{dataVlaue.msg}</p>
                    </div>
                    <div className='managerMessage'>
                      <p>I am Dharmendra</p>
                    </div>
                    <div className='inputEmail'>
                      <Form >
                        <Row>
                          <Form.Group >
                            <Form.Control
                              id="mailSend"
                              placeholder="Message"
                              type="text"
                              name="message"
                              required
                            />
                            <input type="hidden" name="_captcha" value="false"/>
                            <input type="hidden"
                            name="_template" value="table"/>
                            <Form.Control.Feedback>Please Enter The Message
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        <br />
                        <Button id="mailSendBtn"
                        ><AiOutlineSend/></Button>
                      </Form>
                    </div>
                  </div>
                </Card>
              </div>
            </Collapse>
          </>
        </div>
      </div> */}
    </>
  );
};

export default MyPages;

