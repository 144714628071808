import React from 'react'

const Setting = () => {
    return (
        <div className='dashboardRightDiv'>
            <div className='middleConatinerDashboard'>
                <h1>Settings Page</h1>
                <div className='inner-soon'>
                    <p>Coming soon</p>
                </div>
            </div>
        </div>
    )
}

export default Setting
