import AppContext from './Context';
import React, {useState, useContext, useEffect} from 'react';
import {Form} from 'react-bootstrap';
import Select from 'react-select';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

const CreatePage2 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const myContext = React.useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const updateContext = myContext.userDetails;
  const {formData, setFormData, submitData, upadtePages} =
    useContext(AppContext);
  const menuPortalTarget = document.getElementById('root');
  const next = (event) => {
    event.preventDefault();
    if (location.state == null) {
      console.log('null');
      const form = event.currentTarget;
      if (form.checkValidity() === false&&formData.website_link == undefined||
      formData.categories == undefined) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        submitData();
        updateContext.setStep(updateContext.currentPage + 1);
      }
      setValidated(true);
    } else {
      const form = event.currentTarget;
      if (form.checkValidity() === false &&
      formData.website_link == undefined || formData.categories == undefined) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        upadtePages();
        navigate('/dashboard/my-pages');
      }
      setValidated(true);
    }
  };

  const back = () => {
    updateContext.setStep(updateContext.currentPage - 1);
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const data = [
    {
      value: 1,
      label: 'Nutriion & Health',
    },
    {
      value: 2,
      label: 'Pregnancy Clothing',
    },
    {
      value: 3,
      label: 'Maternity Accessories',
    },
    {
      value: 4,
      label: 'Books & Music',
    },
    {
      value: 5,
      label: 'Wellness',
    },
    {
      value: 6,
      label: 'Personal Care',
    },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const handleChangeMultiSelect = (e) => {
    e.map((value) => {
      setFormData({...formData, ['categories']: value.label});
    });
    setSelectedOption(e);
  };

  useEffect(() => {
    if (location.state != null) {
      setFormData({
        ...formData,
        ['website_link']: location.state.website_link,
      });
    } else if (location.state == null) {
      // setFormData({});
    }
  }, [location]);

  return (
    <div className='create-page-onboarding'>
      <div className='contain'>
        <Form noValidate validated={validated} onSubmit={next}>
          <div className='form'>
            <div className='create-page-div'>
              <a href='/dashboard/my-pages' id='bck-to-hm'>
                Exit
              </a>
              <h1>Add more information</h1>
            </div>
            <section className='create-page-section'>

              <div className='cp-section-div1'>
                <Form.Group className='mar'>
                  <Form.Label>Website link</Form.Label>
                  <Form.Control
                    type='url'
                    placeholder='exp: https://yourwebsite.com'
                    name='website_link'
                    onChange={handleChange}
                    value={formData.website_link}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Please fill the website link.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mar'>
                  <Form.Label>Categories</Form.Label>
                  <Select
                    isMulti
                    placeholder='Select Option'
                    value={selectedOption}
                    options={data}
                    onChange={handleChangeMultiSelect}
                    name='categories'
                    required
                    maxMenuHeight={180}
                    menuPlacement="auto"
                    menuPortalTarget={menuPortalTarget}
                  />
                  <Form.Control.Feedback type="invalid">
                    Select Categories
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

            </section>
            <div className='btn-nxt-bck'>
              {/* <button id='bck' onClick={back}>Back</button> */}
              {location.state == null ? (
                <p id='cntr'>Step 2 of 4</p>
              ) : (
                <p id='cntr'>Step 2 of 2</p>
              )}
              <button
                id='nxt'
                type='submit'
                className='formSubmit'
              >
                Next
              </button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreatePage2;
