/* eslint-disable require-jsdoc */
import React, {useState, useCallback, useEffect} from 'react';
import {Button, Modal, Form, Row, Col} from 'react-bootstrap';
import Drag from '../dashboard/assets/svg/Drag.svg';
import {useDropzone} from 'react-dropzone';
import FileUploadApi from './FileUploadApi';
import propTypes from 'prop-types';
import errorHandling from '../errorhandling/ErrorHandle';


export default function UploadFiles(props) {
  UploadFiles.propTypes = {
    imageFeature: propTypes.any,
    show: propTypes.bool,
    getFileData: propTypes.any,
    attachments: propTypes.any,
    featuredImage: propTypes.any,
    handleClose: propTypes.any,
  };
  const [files, setFiles] = useState([]);
  const [featuredImage, setFeaturedImage] = useState({});

  async function fileOnLoad(acceptedFiles) {
    const reader = new FileReader();
    reader.onload = (async function(entry) {
      const image = new Image();
      image.src = entry.target.result;
      image.onload = function() {
        if (props.imageFeature === 'featured') {
          console.log(this.width, this.height);
          // if (this.width !== 1500 && this.height !== 1000) {
          //   errorHandling.handleErrors('Please upload 1500*1000 size image');
          //   return;
          // }
        }
        saveFile(acceptedFiles);
      };
    });
    await reader.readAsDataURL(acceptedFiles[0]);
  }
  const onDrop = useCallback(async (acceptedFiles) => {
    fileOnLoad(acceptedFiles);
  });

  async function saveFile(acceptedFiles) {
    const data = await FileUploadApi.
        uploadFile('s-posts', 's-posts', acceptedFiles[0]);
    if (data) {
      const fileData = [...files];
      const imageData = {};
      imageData.key = data.data.key;
      imageData.size = acceptedFiles[0].size;
      imageData.name = acceptedFiles[0].name;
      imageData.type = acceptedFiles[0].type;
      if (props.imageFeature === 'featured') {
        imageData.imageFeature = 'featured';
        setFeaturedImage(imageData);
      } else {
        imageData.imageFeature = 'attachments';
        fileData.push(imageData);
        setFiles(fileData);
      }
      await props.getFileData(fileData, imageData);
    }
  }

  useEffect(() => {
    if (props.show === true) {
      setFiles(props.attachments);
      setFeaturedImage(props.featuredImage);
    }
  }, [props.show]);

  const {getRootProps, getInputProps} = useDropzone(
      {onDrop, accept: {'image/*': []}});

  return (
    <Modal size="lg" show={props.show}
      onHide={props.handleClose} backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Files</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="file">
            <Form.Label>Upload Files
              {props.imageFeature ===
            'featured' ? '(1500*1000)': null}
            </Form.Label>
            <Row>
              <Col md="12">
                <div {...getRootProps()} className="drag-drop">
                  <input
                    {...getInputProps()}
                    name="sPosts"
                    id="sPosts"
                  />
                  <div>
                    <img src={Drag} alt="" className="darg-icon" />
                    <p className="image-text">Drag and drop an image, or{' '}
                      <span className="drag-browser"> Browse </span>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              {featuredImage && Object.keys(featuredImage).length > 0 &&
              props.imageFeature === 'featured' ?
                  <Col md="2">
                    <img className="preview-image"
                      src={process.env.REACT_APP_Media_Api +
                      'api/media/v3/no-auth?key=' + featuredImage.key} alt="" />
                  </Col>: files.map((file, i) => {
                    return <Col key={i+1} md="2">
                      <img className="preview-image"
                        src={process.env.REACT_APP_Media_Api +
                        'api/media/v3/no-auth?key=' + file.key} alt="" />
                    </Col>;
                  })
              }
            </Row>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
            Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
