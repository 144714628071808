/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
import React, {Fragment, useState} from 'react';
import {Card, Table, Button} from 'react-bootstrap';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import TicketsApi from '../../services/TicketsApi';
import {useQuery} from 'react-query';
import getErrorMessages from '../../errorhandling/ErrorMessages';
import ProfileApi from '../../services/ProfileApi';
import Messages from './Messages';
import AddTicket from './AddTicket';
import {Link} from 'react-router-dom';
import NoPageFound from '../../dashboard/assets/img/nopage.png';

const Tickets = () => {
  const params = useParams();
  const [ticketId, setTicketId] = useState('');
  const [show, setShow] = useState(false);
  const [showTicket, setShowTicket] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseTicket = () => setShowTicket(false);
  const handleShowTicket = () => setShowTicket(true);

  const {data, isError} =
  useQuery(['tickets', params.id], TicketsApi.getAllTickets);
  const userDataDetails = useQuery('userDetails', ProfileApi.getuserDetail,
      {enabled: false});
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
  }
  if (isError) {
    return getErrorMessages(isError);
  }
  const viewMessages = (event, ticketId) => {
    event.preventDefault();
    setShow(true);
    setTicketId(ticketId);
  };
  return (
    <Fragment>
      <div className="container dashboardRightDiv">
        <div className="middleConatinerDashboard">
          {data && data.data.data.list.length == '0' ?
            <div className='NoPageSupport'>
              <img id='nopage' src={NoPageFound} />
              <p>Sorry, No data found :(</p>
            </div>:
          <Card className="">
            <Card.Header>Tickets
            </Card.Header>
            <Card.Body>
              <Table className="table">
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Created Date</th>
                    <th>Product</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {data && data.data.data.list.map((ticket, i) => {
                    return (
                      <tr key={i + 1}>
                        <td><Link className="anchor-decoration"
                          to={'detail/' + ticket._id}>
                          <h4>{ticket.title}</h4>
                        </Link></td>
                        <td>{moment(ticket.created_at).format('DD/MM/YYYY')}</td>
                        <td>{ticket.product}</td>
                        <td><Button
                          size="sm"
                          disabled={ticket.messages.length > 0 ? false : true
                          }
                          variant="outline-primary"
                          onClick={(event) => {
                            viewMessages(event, ticket._id);
                          }
                          }
                        > View Messages
                        </Button></td>
                      </tr>);
                  })
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          }

        </div>
      </div>
      <Messages
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        userDetails={userData}
        ticketId={ticketId}
      />
      <AddTicket
        show={showTicket}
        handleClose={handleCloseTicket}
        handleShow={handleShowTicket}
        userDetails={userData}
        ticketId={ticketId}
      />
    </Fragment>
  );
};

export default Tickets;
