import AppContext from './Context';
import React, {useState, useContext, useEffect} from 'react';
import {Form, Card} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {FaPlus} from 'react-icons/fa';
import Select from 'react-select';
import FileUpload from '../../assets/svg/file_upload.svg';
import FileUploadApi from '../../../services/FileUploadApi';
import brandlabapi from '../../../utils/brandlabapi';
import errorHandling from '../../../errorhandling/ErrorHandle';
import ProfileApi from '../../../services/ProfileApi';
import MyPagesApi from '../MyPagesApi';
import {useQuery} from 'react-query';
import {useLocation} from 'react-router-dom';
import deleteMessage from '../../../services/DeleteMessage';
// import {MdOutlineDeleteOutline} from 'react-icons/md';
const CreatePage4 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const myContext = React.useContext(AppContext);
  const updateContext = myContext.userDetails;
  const [validated, setValidated] = useState(false);
  const menuPortalTarget = document.getElementById('root');
  const page = sessionStorage.getItem('page_id');
  const {
    productData,
    setProductData,
    setProductFile,
    productSubmit,
    updateProduct,
    addProduct,
    productFile,
  } = useContext(AppContext);

  const back = () => {
    updateContext.setStep(updateContext.currentPage - 1);
  };
  const [arrayData, setArrayData] = useState([]);
  const [inputList, setinputList] = useState([
    {product_logo: '', product_name: '', product_introduction: ''},
  ]);

  const userDataDetails = useQuery('userDetails', ProfileApi.getuserDetail, {
    enabled: false,
  });
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
  }
  const [previewFile, setPreviewFile] = useState();
  const handleComplete = (event) => {
    event.preventDefault();
    if (location.state == null) {
      const form = event.currentTarget;
      if (form.checkValidity() === false &&
       productData.product_name == undefined ||
        productData.product_introduction == undefined ||
        productFile == undefined) {
        event.preventDefault();
        event.stopPropagation();
      } else {
        productSubmit();
        navigate('/confirmation');
      }
      setValidated(true);
    } else {
      if (location.state.key == 'addproduct') {
        const form = event.currentTarget;
        if (form.checkValidity() === false &&
         productData.product_name == undefined ||
          productData.product_introduction == undefined ||
           productFile == undefined) {
          event.preventDefault();
          event.stopPropagation();
        } else {
          addProduct();
          navigate('/dashboard/my-pages', {state: location});
          console.log('addproduct validate');
        }
        setValidated(true);
      } else if (location.state.key == 'product') {
        updateProduct();
        navigate('/dashboard/my-pages', {state: location});
      }
    }
  };

  const handleChange = (e) => {
    setProductData({...productData, [e.target.name]: e.target.value});
  };

  const data = [
    {
      value: 1,
      label: 'Nutriion & Health',
    },
    {
      value: 2,
      label: 'Pregnancy Clothing',
    },
    {
      value: 3,
      label: 'Maternity Accessories',
    },
    {
      value: 4,
      label: 'Books & Music',
    },
    {
      value: 5,
      label: 'Wellness',
    },
    {
      value: 6,
      label: 'Personal Care',
    },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const handleChangeMultiSelect = (e) => {
    e.map((value) => {
      setProductData({...productData, ['product_categories']: value.label});
    });
    setSelectedOption(e);
  };

  const handleProductLogo = async (e) => {
    const fileData = await FileUploadApi.uploadFile(
        'brandlabs',
        'brandlabs',
        e.target.files[0],
    );
    try {
      if (fileData.data) {
        setProductFile(fileData.data.key);
        setPreviewFile(fileData.data.key);
      }
    } catch (err) {
      errorHandling.handleErrors(err);
    }
  };

  const handleaddclick = () => {
    setProductData({});
    if (
      (productData.product_logo != File || undefined || null) &&
      (productData.product_name != undefined || '' || null) &&
      (productData.product_introduction != undefined || '' || null)
    ) {
      productSubmit();
      getProductData();
      setinputList([
        ...inputList,
        {product_logo: '', product_name: '', product_introduction: ''},
      ]);
      setSelectedOption();
    }
  };

  const getProductData = async (id) => {
    const res = await MyPagesApi.getProduct(page);
    setArrayData(res.data.data);
  };

  useEffect(() => {
    getProductData(page);
  }, []);

  useEffect(() => {
    if (location.state != null) {
      setProductData({
        ...productData,
        ['product_name']: location.state.product.product_name,
        ['product_introduction']: location.state.product.product_introduction,
        ['product_categories']: location.state.product.selectedOption,
      });
      setProductFile(location.state.product.product_logo);
    } else if (location.state == null) {
      setProductData({});
    }
  }, [location]);

  const handleClickDeleteProduct = async (e, val) => {
    const deleteParams = {
      title: 'Are you sure?',
      text: 'You want to delete this product?',
    };
    const deleteData = await deleteMessage(deleteParams);
    if (deleteData.isConfirmed) {
      try {
        const res = await MyPagesApi.productDelete(val._id);
        if (res.status == 200) {
          getProductData();
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  return (
    <div>
      <Form noValidate validated={validated} onSubmit={handleComplete}>
        <div className="create-page-div">
          <a href="/dashboard/my-pages" id="bck-to-hm">
            Exit
          </a>
          <h1>Atlast, add products to your brand</h1>
          <div className="fourth-page-product">
            {location.state == null && (
              <text onClick={handleaddclick}>
                <FaPlus />
                &#160;Add More
              </text>
            )}
          </div>
        </div>

        <section className="create-page-section">

          {inputList.map((x, i) => {
            return (
              < div className='all_section' key={i}>
                <Form controlId="form">
                  <div className="cp-section-div1" id={`create-${i}`} >
                    <div className="section-btn-add">
                      <label>Product {i + 1}</label>
                    </div>

                    <Form.Group className="mar">
                      <Form.Label>Logo</Form.Label>
                      <img className="FileUpload" src={FileUpload} />
                      <div className="drag-logo">
                        <input
                          type="file"
                          className="file_upload"
                          name="brand_logo"
                          value={productData.product_logo}
                          onChange={handleProductLogo}
                          accept="image/jpeg,image/png,image/jpg"
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Choose Logo
                        </Form.Control.Feedback>
                      </div>
                      <br />
                      <Form.Text className="text-muted">
                        512x512 or higher recommended. Max 04 MB size
                      </Form.Text>
                    </Form.Group>
                    <Form.Group className="mar">
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter your product name"
                        name="product_name"
                        value={productData.product_name}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your product name.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                    >
                      <Form.Label>Introduction </Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter your product introduction"
                        name="product_introduction"
                        value={productData.product_introduction}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter your product introduction.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mar">
                      <Form.Label>Categories </Form.Label>
                      <div className="App multi_selction_bootm"
                        style={{marginBottom: '200px'}}>
                        <Select
                          isMulti
                          placeholder="Select Option"
                          value={selectedOption}
                          options={data}
                          onChange={handleChangeMultiSelect}
                          name="product_categories"
                          required
                          maxMenuHeight={160}
                          menuPlacement="auto"
                          menuPortalTarget={menuPortalTarget}
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        Please enter your product categories.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                </Form>
                <div>
                  <label>Preview Page</label>
                  <div>
                    <Card className="cp-card preview-page">
                      <Card.Body>
                        <div className="cp-fourth-page-right">
                          <div className="cp-fourth-inner">
                            <span className="cp-fourth-product">Products</span>
                            {/* <span className="see-all">See All</span> */}
                          </div>
                        </div>
                        <div className="horizental-add">
                          <Card className="border-0 cp-inner-logo-card1
                     inner-preview-card">
                            {location.state == null ? (
                        <div>
                          <img
                            src={
                              brandlabapi.mediaURL +
                              'api/media/v3/no-auth?key=' +
                              previewFile
                            }
                            alt=""
                            className="right-header-img"
                          />
                        </div>
                      ) : (
                        <div>
                          {previewFile == undefined ? (
                            <img
                              src={
                                brandlabapi.mediaURL +
                                'api/media/v3/no-auth?key=' +
                                location.state.product.product_logo
                              }
                              alt=""
                              className="right-header-img"
                            />
                          ) : (
                            <img
                              src={
                                brandlabapi.mediaURL +
                                'api/media/v3/no-auth?key=' +
                                previewFile
                              }
                              alt=""
                              className="right-header-img"
                            />
                          )}
                        </div>
                      )}

                            <div className="cp-inner-logo-text">
                              {productData.product_name == undefined || '' ? (
                          <text>Baby Massage oil</text>
                        ) : (
                          <text>{productData.product_name}</text>
                        )}

                    {productData.product_introduction == undefined || '' ? (
                          <p id="product_introduction">
                            Provides strength, nourishment & daily care.
                          </p>
                        ) : (
                          <p id="product_introduction">
                            {productData.product_introduction}
                          </p>
                        )}
                    {/* <button className="know-more">Know More</button> */}
                            </div>
                          </Card>
                        </div>
                      </Card.Body>
                    </Card>
{/* {arrayData?.map((val) => {
                return (
                  <>
                    {location.state == null && (
                      <>
                        {userData.sk_user_id == val.user_id && (
                          <div>
                            {page == val.page_id && (
                              <Card className="preview-page cp-card
                               dynamic-card">
                                <Card.Body>
                                  <MdOutlineDeleteOutline
                                    id="delete"
                                    name="delete"
                                    onClick={(e) =>
                                      handleClickDeleteProduct(e, val)
                                    }
                                  />

                                  <div className="cp-fourth-page-right">
                                    <div className="cp-fourth-inner">
                                      <span className="cp-fourth-product">
                                        Products
                                      </span>
                                    </div>
                                  </div>
                                  <div className="horizental-add">
                                    <Card className="border-0
                              cp-inner-logo-card1 inner-preview-card">
                                      <div>
                                        <img
                                          src={
                                            brandlabapi.mediaURL +
                                            'api/media/v3/no-auth?key=' +
                                            val.product_logo
                                          }
                                          alt=""
                                          className="right-header-img"
                                        />
                                      </div>
                                      <div className="cp-inner-logo-text">
                                        <text>{val.product_name}</text>

                                        <p id="product_introduction">
                                          {val.product_introduction}
                                        </p>

                                        <button className="know-more">
                                          Know More
                                        </button>
                                      </div>
                                    </Card>
                                  </div>
                                </Card.Body>
                              </Card>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </>
                );
              })} */}
                  </div>
                </div>
              </div>
            );
          })}

        </section>
        <div className="btn-nxt-bck">
          {/* <button id="bck" onClick={back}>Back</button> */}
          <p id="cntr">Step 4 of 4</p>
          <button
            id="nxt"
            type="submit"
            className="formSubmit"
          >
            Complete
          </button>
        </div>
      </Form>
    </div>
  );
};

export default CreatePage4;
