import React, {useEffect, useState, useRef, Fragment} from 'react';
import {Navbar, Nav, Container} from 'react-bootstrap';
import Logo from '../assets/img/brandlabs-logo.png';
import {logout} from '../auth/auth';
import {IoIosExit} from 'react-icons/io';
import {RiUserSmileFill} from 'react-icons/ri';
import ProfileApi from '../services/ProfileApi';
import {useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
// import {TbMessage2} from 'react-icons/tb';

const Header = () => {
  const [dropDown, setDropDown] = useState(false);
  const ref = useRef();
  const navigate = useNavigate();

  const profilepic = sessionStorage.getItem('profilepic');

  useEffect(() => {
    const handleClickDropdown = (e) => {
      if (dropDown && ref.current && !ref.current.contains(e.target)) {
        setDropDown(false);
      }
    };
    document.addEventListener('mousedown', handleClickDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickDropdown);
    };
  }, [dropDown]);

  const {data, isError} = useQuery(
      'userDetails',
      ProfileApi.getuserDetail);

  const homepage = () => {
    navigate('/dashboard/my-pages');
  };
  return (
    <Fragment>
      {!isError ? (
        <Navbar collapseOnSelect expand='lg' variant='light' className='header'>
          <Container>
            <Navbar.Brand
              href=''
              onClick={homepage}
              style={{cursor: 'pointer'}}
            >
              <img src={Logo} className='headerLogo' />
            </Navbar.Brand>

            <Navbar id='responsive-navbar-nav'>
              {/* <Nav className='me-auto
              customer'>Message &nbsp; <TbMessage2 id="customerIcon"/>
              </Nav>&nbsp; &nbsp; */}
              <Nav>
                {
                data ?(
                  <span className='span-username'>
                    Hi, {data.data.data[0]?.full_name}
                  </span>
                ): null
                }
                {profilepic == null && (
                  <RiUserSmileFill
                    id='nav-profile'
                    onClick={() => setDropDown((oldState) => !oldState)}
                  />
                )}
                {profilepic != null && (
                  <button
                    id='btn-flow'
                    onClick={() => setDropDown((oldState) => !oldState)}
                  >
                    <img src={profilepic} id='profile-pic' />
                  </button>
                )}

                {dropDown && (
                  <div className='flex flex-col dropDownProfile'>
                    <div className='box'></div>
                    <ul className='flex flex-col gap-4'>
                      <li id='red' onClick={() => logout()}>
                        <IoIosExit id='logout' />
                        Logout
                      </li>
                    </ul>
                  </div>
                )}
              </Nav>
            </Navbar>
          </Container>
        </Navbar>
      ) : null}
    </Fragment>
  );
};

export default Header;
