import React from 'react';
import {Link} from 'react-router-dom';
import '../assets/css/desktop.css';

const Coming = () => {
  return (
    <div>
      <div className='card text-center comming'>
        <div className='card-body'>
          <h1 className='card-text'>Coming Soon..</h1>
          <Link to='/' className='btn btn-light'>
            Go Back{''}
          </Link>
        </div>
      </div>
    </div>
  );
};
export default Coming;
