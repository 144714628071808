import React, {Fragment} from 'react';
import {Card, Button, Table, Badge} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import SponsoredPostApi from '../../services/SponsoredPostApi';
import {Link} from 'react-router-dom';
import moment from 'moment';
import ProfileApi from '../../services/ProfileApi';
import Spinner from '../../services/Spinner';
import {useQuery, useQueryClient} from 'react-query';
import getErrorMessages from '../../errorhandling/ErrorMessages';
import deleteMessage from '../../services/DeleteMessage';

// MdNavigateNext

const SponsoredPosts = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();



  const {isLoading, data, isError, error} =
    useQuery('sponsoredPosts', SponsoredPostApi.getSponsoredPosts,
        {refetchOnWindowFocus: true});

  const userDataDetails = useQuery('userDetails', ProfileApi.getuserDetail,
      {enabled: true});
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
  }

  // console.log( "userDataDetails",userData);
  const handleAdd = () => {
    navigate('create-post', {replace: true});
  };

  const handleEdit = (event, id) => {
    event.preventDefault();
    navigate('edit-post/' + id, {replace: true});
  };

  const handleDelete = async (event, id) => {
    const deleteParams = {
      title: 'Are you sure?',
      text: 'You want to delete this post?',
    };
    const deleteData = await deleteMessage(deleteParams);
    if (deleteData.isConfirmed) {
      if (deleteData.isConfirmed) {
        await SponsoredPostApi.deletePostById(id);
        queryClient.invalidateQueries('sponsoredPosts');
      }
    };
  };

  if (isError) {
    getErrorMessages(error, userData.c_code);
  }

  return (
    <Fragment>
      <div className="container dashboardRightDiv">
        <div className="middleConatinerDashboard">
          {isLoading ?
            <Spinner /> :
            <Card>
              <Card.Header>
                <h3>
                  Sponsored Posts
                  <Button
                    variant="outline-primary"
                    className="primaryButton btn btn-primary float-end"
                    onClick={handleAdd}
                  >
                    Create Post
                  </Button>
                </h3>
              </Card.Header>
              <Card.Body>
                {data && data.data.data.list.length === 0 ?
                  (userData && userData.c_code &&
                    userData.c_code === 'IN') ?
                    <img
                      src={process.env.PUBLIC_URL + '/images/indiabanner.png'}
                      alt="image" /> :
                    <img
                      src={process.env.PUBLIC_URL +
                        '/images/othercountrybanner.png'}
                      alt="image" /> :
                  <Table className="table">
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>Status</th>
                        <th>Created</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.data.data.list.map((sponsoredPost, i) => {
                        return (
                          <tr key={i + 1}>
                            <td width="50%">

                              <Link className="anchor-decoration"
                                to={'detail/' + sponsoredPost._id}>
                                <h4>{sponsoredPost.title}</h4>
                              </Link>

                            </td>
                            <td>
                              <Badge
                                bg={
                                  sponsoredPost.status === 'inReview' ?
                                    'warning' :
                                    sponsoredPost.status === 'approved' ?
                                      'info' :
                                      sponsoredPost.status === 'paid' ?
                                        'info' :
                                        sponsoredPost.status === 'published' ?
                                          'success' :
                                          sponsoredPost.status === 'rejected' ?
                                            'danger' :
                                            sponsoredPost.status ===
                                            'clarification' ?
                                              'warning' :
                                              'secondary'
                                }
                              >
                                {sponsoredPost.status.charAt(0).toUpperCase() +
                                  sponsoredPost.status.slice(1)}
                              </Badge>{' '}
                            </td>
                            <td>
                              {'Author: ' + sponsoredPost.authorName}
                              <br />
                              {
                                moment(sponsoredPost.created_at)
                                    .format('DD/MM/YYYY')
                              }
                            </td>
                            <td>
                              <Button
                                disabled={
                                  sponsoredPost.status === 'draft' ||
                                    sponsoredPost.status === 'clarification' ?

                                    false :
                                    true
                                }
                                size="sm"
                                variant="outline-secondary"
                                onClick={(event) =>
                                  handleEdit(event, sponsoredPost._id)
                                }
                              >
                                Edit
                              </Button>{' '}
                              <Button
                                size="sm"
                                disabled={
                                  sponsoredPost.status === 'draft' ||
                                    sponsoredPost.status === 'clarification' ?
                                    false :
                                    true
                                }
                                variant="outline-secondary"
                                onClick={(event) =>
                                  handleDelete(event, sponsoredPost._id)
                                }
                              >
                                Delete
                              </Button>{' '}
                              <Button
                                size="sm"
                                disabled={
                                    sponsoredPost.status === 'approved' ?
                                    false :
                                    true
                                }
                                variant="outline-danger"
                              >
                                <Link className="anchor-decoration"
                                  to={'detail/' + sponsoredPost._id}>
                                  Pay
                                </Link>
                              </Button>
                            </td>

                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              </Card.Body>
            </Card>
          }
        </div>
      </div>
    </Fragment>
  );
};

export default SponsoredPosts;
