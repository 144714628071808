import React from 'react';
import '../assets/css/main.css';
import Logo1 from '../assets/img/logo-new 2.png';
import Ios from '../assets/img/ios.png';
import Android from '../assets/img/android.png';
import {Link} from 'react-router-dom';
import {Container, Row} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import { AiFillInstagram,AiFillFacebook,AiFillTwitterCircle,AiFillLinkedin } from 'react-icons/ai';

const Footer = () => {
  const navigate = useNavigate();
  // const brandPage = () => {
  //   window.scrollTo(0, 0);
  //   navigate('/brand-pages');
  // };

  const clickSolutions = () => {
    window.scrollTo(0, 0);
    navigate('/solutions');
  };

  // const clickUtilies = () => {
  //   window.scrollTo(0, 0);
  //   navigate('/utilities');
  // };

  // const productPage = () => {
  //   window.scrollTo(0, 0);
  //   navigate('/product-pages');
  // };
  return (
    <div>
      <footer className='footer'>
        <Container>
          <Row>
            <div className='footer-col downloadOur'>
              <h4>
                <a href='https://www.beingtheparent.com/'>
                  <img src={Logo1} className='beingtheparentlogo' />
                </a>{' '}
              </h4>
              <ul>
                <li>
                  <a href='#'></a>
                </li>
                <li>
                  <a href='#'></a>
                </li>
                <p href='#' className='download' style={{color: '#333333'}}>
                  Download Our App
                </p>
                <div className='download-img'>
                  <a
                    href='https://apps.apple.com/in/app/being-the-parent/id1615017230'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img src={Ios} alt='' />
                  </a>

                  <a
                    href='https://play.google.com/store/apps/details?id=com.beingtheparent.app'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <img src={Android} alt='' />
                  </a>
                </div>
              </ul>
            </div>
            <div className='footer-col'>
              <h4 className='pages'>Pages</h4>
              <ul>
                {/* <li onClick={brandPage}>
                  <Link>Brand Pages</Link>
                </li>
                <li onClick={productPage}>
                  <Link>Product Pages</Link>
                </li> */}

                <li onClick={clickSolutions}>
                  <Link>Solutions</Link>
                </li>
                {/* <li onClick={clickUtilies}>
                  <Link>Utilities</Link>
                </li> */}
              </ul>
            </div>
            <div className='footer-col'>
            <h4 className='pages'>Company</h4>
              <ul>

                {/* <li>
                  <Link to='/Coming-soon'>Ad choices</Link>
                </li>
                <li>
                  <Link to='/Coming-soon'>Advertisting policy</Link>
                </li> */}
                <li>
                  <a href='https://www.beingtheparent.com/privacy-policy/' target="_blank">Privacy Policy</a>
                </li>
                <li>
                <a href='https://www.beingtheparent.com/terms-of-service/' target="_blank">Terms of Service</a>
                </li>
              </ul>
            </div>
            <div className='footer-col'>
              <h4>Follow Us</h4>

              <div className='social-links'>
                <a href='https://www.facebook.com/Beingtheparent'>
                  <AiFillFacebook />
                </a>

                <a href='https://twitter.com/beingdparent'>
                 <AiFillTwitterCircle />
                </a>
                <a href='https://www.linkedin.com/company/mykids-ventures-private-limited/ '>
                  <AiFillLinkedin />
                </a>
                <a href='https://www.instagram.com/beingtheparent/'>
                  <AiFillInstagram />
                </a>
              </div>
            </div>
          </Row>
          <center>
            {' '}
            <p id='copyright'>
              &copy; 2014-2023 Mykids Ventures Private Limited 1.0.10
            </p>
          </center>
        </Container>
      </footer>
    </div>
  );
};

export default Footer;
