import React, {useState} from 'react';
import Man from '../../assets/svg/manwithlaptop.svg';
import Card from 'react-bootstrap/Card';
import Single1 from '../../assets/svg/single1.svg';
import Single2 from '../../assets/svg/single2.svg';
import Single3 from '../../assets/svg/single3.svg';
import Sponsored from '../../assets/svg/sponsered1.svg';
import Mom from '../../assets/svg/momwithbaby.svg';
import Frame from '../../assets/svg/frame.svg';
import Notification from '../../assets/svg/notification.svg';
import Emailmark from '../../assets/svg/emailmark.svg';
import Square from '../../assets/svg/Square.svg';
import SmallSquare from '../../assets/svg/SmallSquare.svg';

const Utilities = () => {
  const [appShow, setAppShow] = useState(false);
  const handleClickApp = () => {
    setAppShow(true);
  };

  const handleClickWebsite = () => {
    setAppShow(false);
  };
  return (
    <>
      <div className='utilities-main-container' id='top-gap'>
        <section className='utilities-first-section'>
          <div className='container'>
            <div className='utilities-first-section-left'>
              <h1>Brand Labs Ad Guide</h1>
              <p>Find the format and specifications that fit your objective</p>
            </div>
            <div className='utilities-first-section-right'>
              <img src={Man} alt='' />
            </div>
          </div>
        </section>
      </div>
      <section className='utilities-second-section'>
        <div className='container'>
          <div className='row'>
            <h1 className='utilities-reach-spons'>Sponsored Display Ads</h1>
            <p className='utilities-reach'>Reach highly engaged audience on,</p>
            <div className=''>
              <button id='utilities-inner-app' onClick={handleClickApp}>
                App
              </button>{' '}
              <button
                id='utilities-inner-btpwebsite'
                onClick={handleClickWebsite}
              >
                BTP Website
              </button>
            </div>
            {appShow == true ? (
              <div className='utilities-second-inner-section col-12'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <Card
                      border='secondary'
                      className='col-sm-4 utilities-card'
                    >
                      <Card.Img variant='top' src={Single1} />
                      <Card.Body>
                        <h1>Single Image Ads</h1>
                        <Card.Text className='inner-mess'>View Specs</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-sm-4'>
                    <Card
                      border='secondary'
                      className='col-sm-4 utilities-card'
                    >
                      <Card.Img variant='top' src={Single2} />
                      <Card.Body>
                        <h1>Carousel Ads</h1>
                        <Card.Text className='inner-mess'>View Specs</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-sm-4'>
                    <Card
                      border='secondary'
                      className='col-sm-4 utilities-card'
                    >
                      <Card.Img variant='top' src={Single3} />
                      <Card.Body>
                        <h1>Video Ads</h1>
                        <Card.Text className='inner-mess'>View Specs</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            ) : (
              <div className='utilities-second-inner-section col-12'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <Card
                      border='secondary'
                      className='col-sm-4 utilities-card'
                    >
                      <Card.Img variant='top' src={Frame} />
                      <Card.Body>
                        <h1>Medium Rectangle </h1>
                        <p>( 300 * 250 )</p>
                        <Card.Text className='inner-mess'>View Specs</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-sm-4'>
                    <Card
                      border='secondary'
                      className='col-sm-4 utilities-card'
                    >
                      <Card.Img variant='top' src={Square} />
                      <Card.Body>
                        <h1>Square </h1>
                        <p>( 250 *250 )</p>
                        <Card.Text className='inner-mess'>View Specs</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                  <div className='col-sm-4'>
                    <Card
                      border='secondary'
                      className='col-sm-4 utilities-card'
                    >
                      <Card.Img variant='top' src={SmallSquare} />
                      <Card.Body>
                        <h1>Small Square </h1>
                        <p>( 200 *200 )</p>
                        <Card.Text className='inner-mess'>View Specs</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className='utilities-second-section'>
        <div className='container'>
          <div className='row'>
            <h1 className='utilities-reach-spons'>Sponsored Messaging</h1>
            <div className='utilities-second-inner-section col-12'>
              <div className='row'>
                <div className='col-sm-4'>
                  <Card border='secondary' className='col-sm-4 utilities-card'>
                    <Card.Img variant='top' src={Sponsored} />
                    <Card.Body>
                      <h1>In Message Ads</h1>
                      <Card.Text className='inner-mess'>View Specs</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className='col-sm-4'>
                  <Card border='secondary' className='col-sm-4 utilities-card'>
                    <Card.Img variant='top' src={Notification} />
                    <Card.Body>
                      <h1>Push Notifications</h1>
                      <Card.Text className='inner-mess'>View Specs</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
                <div className='col-sm-4'>
                  <Card border='secondary' className='col-sm-4 utilities-card'>
                    <Card.Img variant='top' src={Emailmark} />
                    <Card.Body>
                      <h1>Email Marketing</h1>
                      <Card.Text className='inner-mess'>View Specs</Card.Text>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='utilities-fourth-section'>
        <div className='container'>
          <div className='row'>
            <div className='utilities-fourth-main-section'>
              <h1 className='utilities-reach-spons'>
                Custom Advertising
                <br />
                Solutions
              </h1>
              <div className='utilities-fourth-section-left'>
                <img src={Mom} alt='' />
              </div>
              <div className='utilities-fourth-section-right'>
                <div className='utilities-fourth-section-right-inner-div'>
                  <h3>Boost brand page post</h3>
                  <p>
                    Measure and learn from results to drive growth for your
                    brand and products.
                  </p>
                  <p className='inner-mess'>View Specs</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Utilities;
