import { BroadcastChannel } from "broadcast-channel";
// import { useNavigate } from "react-router-dom";

const logoutChannel = new BroadcastChannel("logout");

export const logout = () => {
  logoutChannel.postMessage("Logout");
  // localStorage.removeItem("token", 'this_is_a_demo_token')
  sessionStorage.removeItem("username");
  sessionStorage.removeItem("accessToken");
  sessionStorage.removeItem("profilepic");
  sessionStorage.removeItem("given_name");
  sessionStorage.removeItem("full_name");
  sessionStorage.removeItem("userId");
  window.localStorage.clear();
  window.sessionStorage.clear();
  // navigate("/")
  window.location.href = window.location.origin + "/";
};

export const checkIsAuthenticated = () => {
  return (
    sessionStorage.getItem("accessToken") === "accessToken" &&
    sessionStorage.getItem("username") === "username"
  );
};

export const logoutAllTabs = () => {
  logoutChannel.onmessage = () => {
    logout();
    logoutChannel.close();
  };
};
