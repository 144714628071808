/* eslint-disable react/jsx-key */
/* eslint-disable max-len */
import React, {Fragment, useEffect, useState} from 'react';
import {Badge, Card, Table, Button} from 'react-bootstrap';
import {useParams, Link} from 'react-router-dom';
import FileUploadApi from '../../services/FileUploadApi';
import moment from 'moment';
import './style.css';
import EmailAddsApi from '../../services/EmailAddsApi';
import PriceDetails from '../sponsoredPosts/PriceDetails';
import Transaction from '../transactions/Transaction';
import AddTicket from '../tickets/AddTicket';
import Messages from '../tickets/Messages';
import TicketsApi from '../../services/TicketsApi';
import Attachments from '../../components/Attachments';

const EmailAddDetail = () => {
  const params = useParams();
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [showTicket, setShowTicket] = useState(false);
  const [ticketData, setTicketData] = useState({});
  const [addDetails, setAddDetails] = useState({
    title: '',
    weeks: [],
    stages: [],
    created_at: '',
    status: '',
    userId: '',
    id: '',
    url: '',
  });
  const [fileDetails, setFileDetails] = useState([]);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleCloseMessage = () => setShowMessage(false);
  const handleShowMessage = () => setShowMessage(true);
  const handleCloseTicket = () => setShowTicket(false);
  const handleShowTicket = () => setShowTicket(true);

  useEffect(() => {
    getAddDetails();
    getFileDetails();
    getTicket();
  }, []);

  const getAddDetails = async () => {
    const data = await EmailAddsApi.getAddById(params.id);
    if (data) {
      setAddDetails(data.data);
    }
  };

  const getTicket = async () => {
    const data = await TicketsApi.getTicketByProductId(params.id);
    if (data) {
      setTicketData(data.data);
    }
  };

  const getFileDetails = async () => {
    const data = await FileUploadApi.getFileDetails(params.id);
    if (data) {
      setFileDetails(data.data);
    }
  };

  return (
    <Fragment>
      <div className="container dashboardRightDiv">
        <div className="row middleConatinerDashboard">
          <div className="col-md-6">
            <Card>
              <Card.Header>Add Details
              </Card.Header>
              <Card.Body>
                <Table className="striped bordered hover">
                  <tbody>
                    <tr><td>Title</td><td>
                      {addDetails.title}</td></tr>
                    <tr><td>URL</td><td>{addDetails.url}</td></tr>
                    <tr><td>Stages</td><td>{addDetails.stages.map((stage, i) => {
                      return <Badge key={i+1} pill color="primary">{stage ? stage.charAt(0).toUpperCase() + stage.slice(1): stage}</Badge>;
                    })}</td></tr>
                    <tr><td>Weeks</td><td>{addDetails.weeks.toString().split(',').join(' to ')}</td></tr>
                    <tr><td>Status</td>
                      <td>{addDetails.status.charAt(0).toUpperCase() +
                    addDetails.status.slice(1)}</td></tr>
                    <tr><td>Created Date</td><td>
                      {moment(addDetails.created_at).
                          format('DD/MM/YYYY')}</td></tr>
                    <tr><td>View Transaction Details</td><td>
                      <Button
                        size="sm"
                        variant="outline-primary"
                        onClick={handleShow}
                      >
                        View
                      </Button>
                    </td></tr>
                    <tr><td>{Object.keys(ticketData).length > 0 ? 'View Ticket' : 'Contact Support'}</td><td>
                      {Object.keys(ticketData).length > 0 ?
                        <Link className="anchor-decoration"
                          to={'/dashboard/support/detail/' + ticketData._id}>
                          {ticketData.title}
                        </Link> :
                        <Button
                          size="sm"
                          variant="outline-primary"
                          onClick={Object.keys(ticketData).length > 0 ? handleShowMessage : handleShowTicket}
                        >
                          Contact
                        </Button>
                      }
                    </td></tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            {fileDetails.length !== 0 ?
              <Attachments fileDetails={fileDetails}/>: null
            }
          </div>
          <div className="col-md-6">
            {
              addDetails.status == 'approved' &&
              <PriceDetails productDetails={addDetails} getProductDetails={getAddDetails} cause="email-adds"/>}
          </div>
        </div>
        <Transaction
          handleClose={handleClose}
          handleShow={handleShow}
          show={show}
        />
        {Object.keys(ticketData).length > 0 ?
          <Messages
            show={showMessage}
            handleClose={handleCloseMessage}
            handleShow={handleShowMessage}
            userDetails={addDetails}
            productId={params.id}
            ticketData={ticketData}
            product="sponsored-posts"
          /> :
          <AddTicket
            show={showTicket}
            handleClose={handleCloseTicket}
            handleShow={handleShowTicket}
            userDetails={addDetails}
            productId={params.id}
            getTicket={getTicket}
            product="email-adds"
          />
        }
      </div>
    </Fragment>
  );
};

export default EmailAddDetail;
