import React, {useEffect, useState} from 'react';
import Table from 'react-bootstrap/Table';
import {AiFillSound} from 'react-icons/ai';
import {useNavigate} from 'react-router-dom';
import MyPagesApi from './MyPagesApi';
import ProfileApi from '../../services/ProfileApi';
import {useQuery} from 'react-query';
import errorHandling from '../../errorhandling/ErrorHandle';

const Homepage = () => {
  const navigate = useNavigate();
  const [arrayData, setArrayData] = useState([]);
  const [productData, setProductData] = useState([]);

  const userDataDetails = useQuery('userDetails', ProfileApi.getuserDetail, {
    enabled: false,
  });
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
  }

  const getPageData = async () => {
    try {
      const res = await MyPagesApi.getPages();
      setArrayData(res.data.data);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  };

  const getProduct = async () => {
    try {
      const res = await MyPagesApi.getProductWid();
      setProductData(res.data.data);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  };

  useEffect(() => {
    getPageData();
    getProduct();
  }, []);

  const handleCreatePage = () => {
    navigate('/create-page');
  };

  return (
    <>
      <div className='dashboardRightDiv'>
        {/* <div className='verify_if_not_verified'>
          <h1>Verify</h1>
        </div> */}
        <div className='middleConatinerDashboard'>
          <div className='card cardDashHomepage border-0'>
            <div className='campCardUpar'>
              <AiFillSound />
              <h5>My Pages</h5>
              {/* <p>View All</p> */}
            </div>
            <div className='classCampHeader'>
              <div>
                <label htmlFor=''>Followers</label>
                <br />
                <text>0</text>
                <label className='percentData'>+0.0%</label>
              </div>
              <div>
                <label htmlFor=''>Posts</label>
                <br />
                <text>0</text>
              </div>
              <div>
                <button className='btnCampCreate' onClick={handleCreatePage}>
                  Create Pages
                </button>
              </div>
            </div>
            <div className='border-0 col-12'>
              <div className='brand-page-main-div'>
                <div className='col-7 cardBrandFaloower'>
                  <div className='campaign-inner-section'>
                    <label className='campLevel'>Brand Pages</label>
                    <div className='pagination'>
                      <p>
                        {/* <GrFormPrevious />
                        <MdNavigateNext /> */}
                      </p>
                    </div>
                  </div>
                  <Table className='table'>
                    <thead>
                      <tr>
                        <th>Brand</th>
                        <th>Followers</th>
                        <th>Posts</th>
                        <th>Product Pages</th>
                        <th></th>
                      </tr>
                    </thead>
                    {arrayData?.map((value) => {
                      return (
                        <>
                          {userData.sk_user_id == value.user_id && (
                            // <div>
                            <tbody>
                              <tr>
                                <td>{value.brand_name}</td>
                                <td>0</td>
                                <td>0</td>
                                {productData?.map((val, index) => {
                                  return (
                                    <>
                                      {value.page_id == val.page_id &&
                                        userData.sk_user_id == value.user_id &&
                                         (
                                           <td key={index}>
                                             <span>{val.product_name}</span>
                                           </td>
                                         )}
                                    </>
                                  );
                                })}
                              </tr>
                            </tbody>
                          )}
                        </>
                      );
                    })}
                  </Table>
                </div>
                <div className='col-5 mb-2 cardproductPages2'>
                  <div className='campaign-inner-section'>
                    <label className='campLevel'>Product Pages</label>
                    <div className='pagination'>
                      <p>
                        {/* <GrFormPrevious />
                        <MdNavigateNext /> */}
                      </p>
                    </div>
                  </div>
                  <Table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Category</th>
                      </tr>
                    </thead>
                    {arrayData?.map((value) => {
                      return (
                        <>
                          {userData.sk_user_id == value.user_id && (
                            <>
                              {productData?.map((val) => {
                                return (
                                  <>
                                    {value.page_id == val.page_id && (
                                      <tbody>
                                        <tr>
                                          <td> {val.product_name}</td>
                                          <td>{val.product_categories}</td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </>
                                );
                              })}
                            </>
                          )}
                        </>
                      );
                    })}
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mobile-load'>Mobile View</div>
    </>
  );
};

export default Homepage;
