import React, {useState} from 'react';
import ChatLogo from '../../assets/svg/cahtlogobtp.svg';
import ChatProfile from '../../assets/svg/chatprofile.svg';
import Collapse from 'react-bootstrap/Collapse';
import {BsX} from 'react-icons/bs';
import {IoIosAdd} from 'react-icons/io';
import Rightsidepic from '../../assets/svg/rightsidegirl.svg';
import Card from 'react-bootstrap/Card';
import GetBanner from '../../assets/svg/getbanner.svg';
import ProductBanner from '../../assets/svg/productbanner.svg';
import ProductImageFirst from '../../assets/svg/product_pref.svg';
import ProductImageSecond from '../../assets/svg/product_pref1.svg';
import ProductImageThird from '../../assets/svg/product_pref2.svg';
import ReactStars from 'react-rating-stars-component';
import ProductImageMidlle from '../../assets/svg/product_mid.svg';
import GetBannerBottom from '../../assets/svg/getbannerbottom.svg';
import GetBannerBottom1 from '../../assets/svg/getbannerbottom1.svg';
import GetBannerBottom2 from '../../assets/svg/getbannerbottom2.svg';
import KingBadge from '../../assets/svg/kingbadge.svg';
import QuestionandAnswer from '../../assets/svg/qandanswer.svg';

const ProductPage = () => {
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [rating, setRating] = useState(4);
  const collapse = () => {
    setOpen(!open);
  };

  const ratingChanged = (r) => {
    setRating(r);
  };

  return (
    <>
      <div className='brand-pages-container' id='top-gap'>
        <div className='main-first-container'>
          <div className='brand-page-main-section bg-red container'>
            <div className='full product-page-section text-center row '>
              <h1>Do more with Product Pages</h1>
              <p>
                Build interesting & compelling product pages, showcase key
                features & get discovered by prospective customers.
              </p>
              <button>
                Try Now <img src={KingBadge} id='king_badge' />
              </button>
              <img src={ProductBanner} id='product-bg' />
            </div>
          </div>
        </div>

        <div className='product-page-second-section container'>
          <div className='product-page-section-second row'>
            <h1>What can you do with product page</h1>
            <p>
              Build exclusive product pages and showcase your product features,
              offers and many more to your customers.
            </p>
          </div>
          <div className='build-product-bg'>
            <div className='full-width'>
              <div className='question-section'>
                <div className='left-width'>
                  <h2>Build your product presence</h2>
                  <p id='normal'>
                    Use predesigned templates and drag-and-drop tiles to create
                    a brand page that fits your brand and spotlights your
                    best-selling products, without ever writing a line of code.
                  </p>
                  <p>Manage every product with ease</p>
                  <p>Manage every product with ease</p>
                </div>
                <div className='right-width'>
                  <img src={ProductImageFirst} />
                </div>
              </div>
            </div>
          </div>
          <div className='full-width '>
            <div className='left-width'>
              <p>Build your product presence</p>
              <h2>Manage every product with ease</h2>
              <p id='normal'>
                Create product listings and edit product photos, list your
                offers, respond quickly to buyers messages.
              </p>
              <p>Manage every product with ease</p>
            </div>
            <div className='right-width'>
              <img src={ProductImageSecond} />
            </div>
          </div>
          <div className='full-width'>
            <div className='left-width'>
              <p>Build your product presence</p>
              <p>Manage every product with ease</p>
              <h2>Manage every product with ease</h2>
              <p id='normal'>
                Track your product page performance using real time data such as
                product views, traffic to product pages etc
              </p>
            </div>
            <div className='right-width'>
              <img src={ProductImageThird} />
            </div>
          </div>
        </div>
        <div className='product-page-second-section-do container'>
          <div className='row'>
            <div className='product-do-more-section'>
              <h1>What can you do with product page</h1>
              <p>
                Build exclusive product pages and showcase your product
                features, offers and many more to your customers.
              </p>
            </div>
            <img id='section-five-banner' src={ProductImageMidlle} />
          </div>
        </div>
        <div className='container'>
          <div className='full-width row'>
            <div className='left-width-customer'>
              <Card className='inner-right-width-discovered'>
                <Card.Body>
                  <Card.Title className='inner-title'>
                    Customer Service
                  </Card.Title>
                  <Card.Text className='upload-text'>
                    Connect with your followers and deliver seamless customer
                    service experiences.
                  </Card.Text>
                  <div className='inner-full-width'>
                    <div className='inner-left-width'>
                      <img src={ChatProfile} alt='' />
                      <p>Hi, Do you ship product to Bangalore?</p>
                    </div>
                    <div className='inner-right-width'>
                      <p>
                        Hi Cat, Thank you for your query. Yes we do ship to
                        bangalore.
                      </p>
                      <img src={ChatLogo} alt='' />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div className='right-width-customer'>
              <Card className='inner-left-customer'>
                <Card.Body>
                  <Card.Title className='inner-title-review'>
                    Customer reviews & ratings
                  </Card.Title>
                  <Card.Text className='gather'>
                    Gather invaluable source of feedback from your customers
                  </Card.Text>
                  <div className='review-text'>
                    <label>4.8</label>
                    <ReactStars
                      key={`rating_${rating}`}
                      count={5}
                      name='rating'
                      size={24}
                      position='center'
                      activeColor='#ffd700'
                      onChange={ratingChanged}
                      value={Math.max(0, rating)}
                    />
                    <p>based on 64 reviews</p>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        <div className='have-any-question'>
          <div className='container'>
            <div className='full-width-question row'>
              <div className='left-width'>
                <div className='inner-width-collapse'>
                  <div className='qu_and_ans'>
                    <img src={QuestionandAnswer} />
                  </div>
                  <h2>Have any questions?</h2>
                  <ol>
                    <div className='inner-width-collapse-first-div'>
                      <span>
                        <li>Who can create product pages?</li>
                        <button
                          onClick={collapse}
                          aria-controls='example-collapse-text'
                          aria-expanded={open}
                          className='btn-collapse'
                        >
                          <IoIosAdd className='plus' size={45} />
                          <BsX className='close' size={45} />
                        </button>
                      </span>
                      <Collapse in={open}>
                        <div id='example-collapse-text'>
                          Mompreneurs , Brand Mangers & Agencies can create
                          product pages.
                        </div>
                      </Collapse>
                    </div>
                    <div className='inner-width-collapse-first-div'>
                      <span>
                        <li>How can I create product page?</li>
                        <button
                          onClick={() => setOpen1(!open1)}
                          aria-controls='example-collapse-text'
                          aria-expanded={open1}
                          className='btn-collapse'
                        >
                          <IoIosAdd className='plus' size={45} />
                          <BsX className='close' size={45} />
                        </button>
                      </span>
                      <Collapse in={open1}>
                        <div id='example-collapse-text'>
                          Mompreneurs , Brand Mangers & Agencies can create
                          brand pages.
                        </div>
                      </Collapse>
                    </div>
                    <div className='inner-width-collapse-first-div'>
                      <span>
                        <li>How can moms discover my product page?</li>
                        <button
                          onClick={() => setOpen2(!open2)}
                          aria-controls='example-collapse-text'
                          aria-expanded={open2}
                          className='btn-collapse'
                        >
                          <IoIosAdd className='plus' size={45} />
                          <BsX className='close' size={45} />
                        </button>
                      </span>
                      <Collapse in={open2}>
                        <div id='example-collapse-text'>
                          Mompreneurs , Brand Mangers & Agencies can create
                          brand pages.
                        </div>
                      </Collapse>
                    </div>
                    <div className='inner-width-collapse-first-div'>
                      <span>
                        <li>How much does product pages cost?</li>
                        <button
                          onClick={() => setOpen3(!open3)}
                          aria-controls='example-collapse-text'
                          aria-expanded={open3}
                          className='btn-collapse'
                        >
                          <IoIosAdd className='plus' size={45} />
                          <BsX className='close' size={45} />
                        </button>
                      </span>
                      <Collapse in={open3}>
                        <div id='example-collapse-text'>
                          Mompreneurs , Brand Mangers & Agencies can create
                          brand pages.
                        </div>
                      </Collapse>
                    </div>
                  </ol>
                </div>
              </div>
              <div className='right-width' id='slideset3'>
                <img src={Rightsidepic} />
              </div>
            </div>
          </div>
        </div>
        <div className='product-page-getstarted-section '>
          <div className='container'>
            <div className='row product-page'>
              <div className='brand-page-section '>
                <h1>Get started</h1>
                <p>
                  Create your product page now & explore latest tips & tricks to
                  make your page successful
                </p>
                <div className='get-started-btn'>
                  <button id='create-own-page'>Create your page</button>
                </div>
              </div>
              <div className='carousel-div'>
                <div className='inner-img-caursol'>
                  <img className='d-block' src={GetBanner} alt='First slide' />
                  <img
                    className='d-block'
                    src={GetBannerBottom}
                    alt='Second slide'
                  />
                  <img
                    className='d-block'
                    src={GetBannerBottom1}
                    alt='Third slide'
                  />
                  <img
                    className='d-block'
                    src={GetBannerBottom2}
                    alt='Fourth slide'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
