/* eslint-disable react/no-unknown-property */
import React, {Fragment, useEffect, useState, useRef} from 'react';
import {Card, Form, Button, Row, Col} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import UploadFiles from '../../services/UploadFiles';
import SponsoredPostApi from '../../services/SponsoredPostApi';
import FileUploadApi from '../../services/FileUploadApi';
import './style.css';
import ProfileApi from '../../services/ProfileApi';
import SimpleReactValidator from 'simple-react-validator';
import {useQuery} from 'react-query';

const AddSponsoredPost = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [sponsoredPost, setSponsoredPost] = useState({
    title: '',
    body: '',
    _id: '',
    authorDescription: '',
    authorName: '',
    hyperLink: '',
    status: '',
    textLink: '',
  });

  const [imageFeature, setImageFeature] = useState(false);
  const [show, setShow] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [featuredImage, setFeaturedImage] = useState({});
  const [error, setError] = useState(false);
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const handleClose = () => setShow(false);

  const handleShow = ((event, imageFeature) => {
    event.preventDefault();
    setShow(true);
    setImageFeature(imageFeature);
  });


  const userDataDetails = useQuery('userDetails', ProfileApi.getuserDetail,
      {enabled: false});
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
  }
  useEffect(() => {
    if (params.id) {
      getPostDeatils();
      getFileDetails();
    }
  }, []);

  const getFileDetails = async () => {
    const data = await FileUploadApi.getFileDetails(params.id);
    if (data.data && data.data.length !== 0) {
      const attachmentData = [];
      data.data.map((image) => {
        const imageData = {};
        imageData.key = image.attachment_url;
        imageData.size = image.size;
        imageData.name = image.fileName;
        imageData.type = image.fileType;
        imageData.imageFeature = image.imageFeature;
        attachmentData.push(imageData);
      });
      const filteredAttachments =
      attachmentData.filter((item) => item.imageFeature !== 'featured');
      setAttachments(filteredAttachments);
      const filteredFeaturedImage =
      attachmentData.filter((item) => item.imageFeature === 'featured');
      setFeaturedImage(filteredFeaturedImage[0]);
    }
  };

  const getPostDeatils = async () => {
    const data = await SponsoredPostApi.getPostById(params.id);
    if (data) {
      setSponsoredPost(data.data);
    }
  };
  const handleChange = (event) => {
    setSponsoredPost((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleBack = () => {
    navigate('/dashboard/sponsored-posts');
  };

  const submitPosts = async (event, status) => {
    event.preventDefault();
    setError(false);
    if (validator.current.allValid()) {
      sponsoredPost.attachments = attachments;
      sponsoredPost.featuredImage = featuredImage;
      sponsoredPost.status = status;
      sponsoredPost.fullName = userData.full_name;
      sponsoredPost.email = userData.email;
      if (status === 'inReview' && Object.keys(featuredImage).length === 0) {
        setError(true);
        return;
      } else {
        setError(false);
        const data = await SponsoredPostApi.postSponsoredPost(sponsoredPost);
        if (data) {
          navigate('/dashboard/sponsored-posts');
        }
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const getFileData = async (attachements, featuredImage) => {
    if (imageFeature === 'featured') {
      setFeaturedImage({});
      setFeaturedImage(featuredImage);
      setError(false);
    } else {
      setAttachments(attachements);
    }
  };

  const handleDeleteImage = (event, imageType, index) => {
    event.preventDefault();
    if (imageType) {
      setFeaturedImage({});
    } else {
      const attachmentdata = attachments.filter((item, i) => i !== index);
      setAttachments(attachmentdata);
    }
  };

  return (
    <Fragment>
      <div className="container dashboardRightDiv">
        <div className="middleConatinerDashboard">
          <Card>
            <Card.Header>
              Add Sponsored Post
              <Button
                variant="outline-secondary"
                className="float-end"
                onClick={handleBack}
              >
                Back
              </Button>
            </Card.Header>
            <Card.Body>
              <Form method="post" onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col md="12">
                    <Form.Group className="mb-3" controlId="title">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="title"
                        onChange={handleChange}
                        value={sponsoredPost.title}
                        type="text"
                        placeholder="Title"
                      />
                      <div className="validation-error">
                        {validator.current.message('title',
                            sponsoredPost.title, 'required')}</div>
                    </Form.Group>

                  </Col>
                  <Col md="12">
                    <Form.Group className="mb-3" controlId="body">
                      <Form.Label>Body</Form.Label>
                      <Form.Control
                        name="body"
                        onChange={handleChange}
                        value={sponsoredPost.body}
                        as="textarea"
                        rows={3}
                        placeholder="Body"
                      />
                      <div className="validation-error">
                        {validator.current.message('body',
                            sponsoredPost.body, 'required')}</div>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="textLink">
                      <Form.Label>Text to be Linked</Form.Label>
                      <Form.Control
                        type="text"
                        name="textLink"
                        onChange={handleChange}
                        value={sponsoredPost.textLink}
                        placeholder="Text"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="hyperLink">
                      <Form.Label>HyperLink</Form.Label>
                      <Form.Control
                        type="text"
                        name="hyperLink"
                        onChange={handleChange}
                        value={sponsoredPost.hyperLink}
                        placeholder="HyperLink"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="authorName">
                      <Form.Label>Author Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="authorName"
                        onChange={handleChange}
                        value={sponsoredPost.authorName}
                        placeholder="Author Name"
                      />
                    </Form.Group>
                  </Col>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="hyperLink">
                      <Form.Label>Author Description</Form.Label>
                      <Form.Control
                        type="text"
                        name="authorDescription"
                        onChange={handleChange}
                        value={sponsoredPost.authorDescription}
                        placeholder="Author Description"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <Form.Group className="mb-3 button-position"
                      controlId="attachement">
                      <Form.Label>{' '}</Form.Label>
                      <Button size="sm" variant="outline-secondary"
                        onClick={(event) => handleShow(event, 'featured')}>
                          Add Featured Image</Button>
                    </Form.Group>
                    {error === true ?
                        <div className="validation-error">
                          {'The Featured  Image is required'}</div>: null
                    }
                  </Col>
                  <Col md="9">
                    <Form.Group className="mb-3 button-position"
                      controlId="attachement">
                      <Form.Label>{' '}</Form.Label>
                      <Button size="sm" variant="outline-secondary"
                        onClick={(event) => handleShow(event, 'multiple')}>
                          Add Images</Button>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    {featuredImage && Object.keys(featuredImage).length > 0 ?
                      <span className="image-holder">
                        <img className="preview-image"
                          src={process.env.REACT_APP_Media_Api +
                         'api/media/v3/no-auth?key=' +
                         featuredImage.key} alt="" />
                        <button onClick={(event) =>
                          handleDeleteImage(event, 'featuredImage')}
                        className="delete btn btn-outline-danger btn-circle">
                          X</button>
                      </span> :
                      null
                    }
                  </Col>
                  <Col md="9">
                    {attachments.length > 0 ?
                    attachments.map((file, i) => {
                      return <span className="image-holder" key={i+1} md="2">
                        <img className="preview-image"
                          src={process.env.REACT_APP_Media_Api +
                          'api/media/v3/no-auth?key=' + file.key} alt="" />
                        <button onClick={(event) =>
                          handleDeleteImage(event, '', i)}
                        className="delete btn btn-outline-danger btn-circle">X
                        </button>
                      </span>;
                    }): null
                    }
                  </Col>
                </Row>
                <br />
                <Button variant="outline-secondary"
                  type="submit" onClick={(event) =>
                    submitPosts(event, 'draft')}>Save as Draft
                </Button>{' '}
                <Button variant="outline-danger" type="submit"
                  onClick={(event) => submitPosts(event, 'inReview')}>
                  Submit for Review
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
      <UploadFiles
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        getFileData={getFileData}
        imageFeature={imageFeature}
        attachments={attachments}
        featuredImage={featuredImage}
        id={sponsoredPost._id}
      />
    </Fragment>
  );
};

export default AddSponsoredPost;
