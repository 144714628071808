/* eslint-disable max-len */
import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Google from "../dashboard/assets/svg/image 378.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { useGoogleLogin } from "@react-oauth/google";
import ProfileApi from "../services/ProfileApi";
import MyPagesApi from "./dashboardPages/MyPagesApi";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";

const Login = (prop) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const handleChange = (e) => {
    const value = e.target.value;
    if (e.target.name == "password") {
      setFormData({ ...formData, ["password"]: value.replace(/\s/g, "") });
    } else if (e.target.name == "username") {
      setFormData({ ...formData, ["username"]: value.replace(/\s/g, "") });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleClickRegister = () => {
    prop.setShowSignUp(true);
    prop.setShowLogin(false);
  };

  const handleForgetPassowrd = () => {
    prop.setShowForegt(true);
    prop.setShowLogin(false);
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const loginwithGoogle = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );
        const data = JSON.stringify({
          email: res.data.email,
          c_code: "",
          mobile: "",
          country: "91",
          pincode: "583 400",
          signupsource: "",
          due_date_parent: "",
          dob: "",
          full_name: res.data.given_name,
          alies_name: "",
          gender: "Female",
          i_am: "2",
          password: "",
          registred_from: "",
          no_of_children: "0",
          username_momnexus: "",
          childata: [],
          firebase_device_id: process.env.REACT_APP_Global_Token_Firbase,
        });
        ProfileApi.registerUserAdd(data)
          .then(function (response) {
            if (response.data.status === true) {
              setLoader(false);
              localStorage.setItem(
                "accessToken",
                response.data.data.Accesstoken
              );
              sessionStorage.setItem(
                "accessToken",
                response.data.data.Accesstoken
              );
              sessionStorage.setItem("username", res.data.email);
              sessionStorage.setItem("profilepic", res.data.picture);
              sessionStorage.setItem("given_name", res.data.given_name);
              localStorage.setItem("full_name", res.data.name);
              prop.setShowLogin(false);
              Swal.fire({
                icon: "success",
                title: "Welcome Back!",
                showConfirmButton: false,
                timer: 1000,
              });
              prop.setShowLogin(false);
              ProfileApi.getuserDetail()
                .then((resn) => {
                  // console.log(resn,"resm");
                  MyPagesApi.getOnboarding(resn.data.data[0].sk_user_id)
                    .then((reen) => {
                      if (reen.data.message == "failed") {
                        if (resn.data.data[0].c_code === "IN") {

                          if(resn.data.data[0].emil_verification_status == "0" &&
                          resn.data.data[0].mobile_verification_status == "0" ){
                            navigate("/verify");
                          }else{

                            navigate("/on-boarding");
                          }
                        } else {
                          if (
                            resn.data.data[0].emil_verification_status == "0" &&
                          resn.data.data[0].mobile_verification_status == "0" 
                          ) {
                            navigate("/verify");
                            
                          }else{
                            navigate("/abroad");
                          }
                        }
                      } else {
                        if (
                          resn.data.data[0].emil_verification_status == "0" &&
                          resn.data.data[0].mobile_verification_status == "0" &&
                          resn.data.data[0].c_code != "IN"
                        ) {
                          navigate("/verify");
                        } else if (
                          resn.data.data[0].c_code != "IN" &&
                          resn.data.data[0].mobile != ""
                        ) {
                          navigate("/dashboard/sponsored-posts");
                        } else if (
                          resn.data.data[0].emil_verification_status == "1" ||
                          resn.data.data[0].mobile_verification_status == "1"
                        ) {
                          navigate("/dashboard/sponsored-posts");
                        } else {
                          navigate("/verify");
                        }
                      }
                    })
                    .catch((er) => {
                      console.log(er);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (response.data.status === false) {
              navigate("/");
              setLoader(false);
              Swal.fire({
                icon: "error",
                text: "Your account is not registered please create your account.",
                showConfirmButton: true,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message,
                showConfirmButton: false,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    const data = JSON.stringify({
      username: formData.username,
      password: formData.password,
    });
    const form = e.currentTarget;
    if (
      form.checkValidity() === false ||
      formData.username == "" ||
      formData.password == ""
    ) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      setLoader(true);
      await ProfileApi.userSignin(data)
        .then(function (response) {
          const token = response.data.data.Accesstoken;
          const username = formData.username;
          if (response.data.status === true) {
            setLoader(false);
            sessionStorage.setItem("accessToken", token);
            sessionStorage.setItem("username", username);
            prop.setShowLogin(false);
            Swal.fire({
              icon: "success",
              title: "Welcome Back!",
              showConfirmButton: false,
              timer: 1000,
            });
            prop.setShowLogin(false);
            ProfileApi.getuserDetail()
              .then((resn) => {
                if (
                  resn.data.data[0].emil_verification_status == "0" &&
                  resn.data.data[0].mobile_verification_status == "0" &&
                  resn.data.data[0].c_code != "IN"
                ) {
                  navigate("/verify");
                } else if (
                  resn.data.data[0].c_code == "" &&
                  resn.data.data[0].mobile_verification_status == "0" &&
                  resn.data.data[0].mobile == null
                ) {
                  navigate("/verify");
                } else if (
                  resn.data.data[0].c_code == "IN" &&
                  resn.data.data[0].mobile == null
                ) {
                  navigate("/verify");
                } else if (
                  resn.data.data[0].c_code == "IN" &&
                  resn.data.data[0].mobile_verification_status == "0"
                ) {
                  navigate("/verify");
                } else if (
                  resn.data.data[0].c_code != "IN" &&
                  resn.data.data[0].mobile != null
                ) {
                  MyPagesApi.getOnboarding(resn.data.data[0].sk_user_id)
                    .then((reen) => {
                      if (reen.data.message == "failed") {
                        navigate("/abroad");
                      } else {
                        navigate("/dashboard/sponsored-posts");
                      }
                    })
                    .catch((er) => {
                      console.log(er);
                    });
                } else if (
                  resn.data.data[0].emil_verification_status == "1" ||
                  resn.data.data[0].mobile_verification_status == "1"
                ) {
                  MyPagesApi.getOnboarding(resn.data.data[0].sk_user_id)
                    .then((reen) => {
                      if (reen.data.message == "failed") {
                        if (resn.data.data[0].c_code === "IN") {
                          navigate("/on-boarding");
                        } else {
                          navigate("/abroad");
                        }
                      } else {
                        navigate("/dashboard/sponsored-posts");
                      }
                    })
                    .catch((er) => {
                      console.log(er);
                    });
                } else {
                  navigate("/verify");
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else if (response.data.status === false) {
            navigate("/");
            setLoader(false);
            Swal.fire({
              icon: "error",
              text: response.data.message,
              showConfirmButton: true,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response.data.message,
              showConfirmButton: false,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    setValidated(true);
  };

  return (
    <>
      {loader ? (
        <div className="text-center vackground">
          <div className="loader"></div>
        </div>
      ) : (
        <Modal
          autoComplete="off"
          backdrop="static"
          show={prop.showLogin}
          onHide={() => {
            prop.setShowLogin(false);
          }}
        >
          <div>
            <Modal.Header closeButton className="border-0">
              <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modalBody">
              <Form
                autoComplete="off"
                noValidate
                validated={validated}
                onSubmit={handleLogin}
                className="signupHomepage"
              >
                <Form.Group className="mb-3">
                  <Form.Control
                    type="email"
                    placeholder="Email"
                    autoFocus
                    name="username"
                    value={formData.username}
                    onChange={handleChange}
                    autoComplete="off"
                    required
                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                  />

                  <Form.Control.Feedback type="invalid">
                    Please fill the username.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <span>
                    <input
                      className="rg-form"
                      type={passwordShown ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Please fill the password.
                    </Form.Control.Feedback>

                    <span onClick={togglePasswordVisiblity}>
                      {passwordShown == true && (
                        <AiOutlineEyeInvisible className="eyeviewer">
                          {" "}
                        </AiOutlineEyeInvisible>
                      )}
                      {passwordShown == false && (
                        <AiOutlineEye className="eyeviewer"></AiOutlineEye>
                      )}
                    </span>
                  </span>
                </Form.Group>

                <Form.Label
                  htmlFor="disabledSelect"
                  onClick={handleForgetPassowrd}
                  className="forgetPass"
                >
                  Forgot Password
                </Form.Label>

                <Button
                  variant="danger"
                  type="submit"
                  className="col-12 customPopupBtn"
                >
                  Sign in
                </Button>
                <p className="text-center or">or</p>
                <Button
                  className="col-12 withGoogle "
                  onClick={loginwithGoogle}
                >
                  <img src={Google} alt="" className="googleFont" /> Login With
                  Google
                </Button>

                <p className="text-center haveAccount">
                  Don’t have an account?{" "}
                  <span className="text-danger" onClick={handleClickRegister}>
                    Signup
                  </span>
                </p>
              </Form>
            </Modal.Body>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Login;
