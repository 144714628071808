import errorHandling from '../../errorhandling/ErrorHandle';
import { brandlabsAuth } from '../../services/BrandlabsAuth';
import brandlabapi from '../../utils/brandlabapi';

export default {
  getPages: async () => {
    const response = await brandlabsAuth.fetchData(brandlabapi.getPages, 'get');
    return response;
  },
  getProduct: async (id) => {
    const response = await brandlabsAuth.fetchData(
      brandlabapi.getProduct + id,
      'get',
    );
    return response;
  },



  getOnboarding: async (id) => {
    const response = await brandlabsAuth.fetchData(
      brandlabapi.getOnboarding + id,
      'get',
    );
    return response;
  },

  getProductWid: async () => {
    const response = await brandlabsAuth.fetchData(
      brandlabapi.getProductWid,
      'get',
    );
    return response;
  },

  createPages: async (data) => {
    try {
      const response = await brandlabsAuth.fetchData(
        brandlabapi.createPages,
        'post',
        '',
        data,
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  createSection: async (data) => {
    try {
      const response = await brandlabsAuth.fetchData(
        brandlabapi.createSection,
        'post',
        '',
        data,
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
  createProduct: async (data) => {
    try {
      const response = await brandlabsAuth.fetchData(
        brandlabapi.createProduct,
        'post',
        '',
        data,
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  productUpdate: async (data, id) => {
    try {
      const res = await brandlabsAuth.fetchData(
        brandlabapi.productUpdate + id,
        'patch',
        '',
        data,
      );
      return res.data;
    } catch (err) {
      console.log(err);
      errorHandling.handleErrors(err);
      return;
    }
  },

  pageUpdate: async (data, id) => {
    try {
      const res = await brandlabsAuth.fetchData(
        brandlabapi.pageUpdate + id,
        'patch',
        '',
        data,
      );
      return res.data;
    } catch (err) {
      console.log(err);
      errorHandling.handleErrors(err);
      return;
    }
  },
  pageDelete: async (id) => {
    try {
      const res = await brandlabsAuth.fetchData(
        brandlabapi.pageDelete + id,
        'delete',
      );
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },
  sectionDelete: async (id) => {
    try {
      const res = await brandlabsAuth.fetchData(
        brandlabapi.sectionDelete + id,
        'delete',
      );
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },
  productDelete: async (id) => {
    try {
      const res = await brandlabsAuth.fetchData(
        brandlabapi.productDelete + id,
        'delete',
      );
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },



  // getMessage: async (id) => {
  //   try {
  //     const res = await brandlabsAuth.fetchData(
  //         brandlabapi.getMessage + id,
  //         'get',
  //     );
  //     return res.data;
  //   } catch (err) {
  //     errorHandling.handleErrors(err.response.data);
  //     return;
  //   }
  // },

  getMessage: async (id) => {
    const response = await brandlabsAuth.fetchData(
      brandlabapi.getMessage + id,
      'get',
    );
    return response;
  },

  getSectionData: async () => {
    const response = await brandlabsAuth.fetchData(
      brandlabapi.getSectionData,
      'get',
    );
    return response;
  },
};
