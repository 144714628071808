import React, {useState, useContext, useCallback, useEffect} from 'react';
import {Form, Card} from 'react-bootstrap';
import AppContext from './Context';
import {useDropzone} from 'react-dropzone';
import Drag from '../../../dashboard/assets/svg/Drag.svg';
import {FiChevronLeft, FiAlignJustify} from 'react-icons/fi';
import {Link, useNavigate} from 'react-router-dom';
import PreviewBanner from '../../assets/svg/preview_banner.svg';
import FileUpload from '../../assets/svg/file_upload.svg';
import FileUploadApi from '../../../services/FileUploadApi';
import brandlabapi from '../../../utils/brandlabapi';
import errorHandling from '../../../errorhandling/ErrorHandle';
import {useLocation} from 'react-router-dom';

const CreatePage1 = () => {
  const location = useLocation();
  const [previewFile, setPreviewFile] = useState();
  const [previewBanner, setPrevieBanner] = useState();
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const myContext = React.useContext(AppContext);
  const updateContext = myContext.userDetails;
  const {
    formData,
    setFormData,
    setLogoFile,
    setBannerLogo,
    logoFile,
    bannerLogo,
  } = useContext(AppContext);

  const onDrop = useCallback(async (acceptedFiles) => {
    const fileData = await FileUploadApi.uploadFile(
        'brandlabs',
        'brandlabs',
        acceptedFiles[0],
    );
    try {
      if (fileData.data) {
        setBannerLogo(fileData.data.key);
        setPrevieBanner(fileData.data.key);
      }
    } catch (err) {
      errorHandling.handleErrors(err);
    }
  });

  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg'],
    },
    onDrop});
  const next = (event) => {
    console.log('logoFile' + logoFile);
    console.log('bannerLogo' + bannerLogo);
    console.log('brandName' + formData.brand_name);
    event.preventDefault();
    if (location.state == null) {
      console.log('null');
      const form = event.currentTarget;
      if (form.checkValidity() === false &&
       formData.brand_name == undefined || logoFile == '' || bannerLogo == '') {
        event.preventDefault();
        event.stopPropagation();
      } else {
        updateContext.setStep(updateContext.currentPage + 1);
      }
      setValidated(true);
    } else {
      const form = event.currentTarget;
      if (form.checkValidity() === false &&
       formData.brand_name == undefined || logoFile == '' || bannerLogo == '') {
        event.preventDefault();
        event.stopPropagation();
      } else {
        updateContext.setStep(updateContext.currentPage + 1);
      }
      setValidated(true);
    }
  };

  const back = () => {
    navigate('/dashboard/my-pages');
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == 'brand_name') {
      setFormData({
        ...formData,
        ['brand_name']: value.replace(/[^a-zA-Z\s]/g, ''),
      });
    } else {
      setFormData({...formData, [name]: value});
    }
  };

  const handleChangeLogo = async (e) => {
    const fileData = await FileUploadApi.uploadFile(
        'brandlabs',
        'brandlabs',
        e.target.files[0],
    );
    try {
      if (fileData.data) {
        setLogoFile(fileData.data.key);
        setPreviewFile(fileData.data.key);
      }
    } catch (err) {
      errorHandling.handleErrors(err);
    }
  };

  useEffect(() => {
    if (location.state != null) {
      setFormData({
        ...formData,
        ['brand_name']: location.state.brand_name,
      });
      setLogoFile(location.state.brand_logo);
      setBannerLogo(location.state.brand_banner);
    } else if (location.state == null) {
      setFormData({});
    }
  }, [location]);

  return (
    <div className='create-page-onboarding'>
      <Form noValidate validated={validated} onSubmit={next}>
        <div className='create-page-div'>
          <a id='bck-to-hm'>
            <Link to='/dashboard/my-pages'>Exit & Create New Brand Page</Link>
          </a>
          <h1>First up, fill up some basic information</h1>
        </div>
        <section className='create-page-section'>
          <div className='cp-section-div1'>
            <Form.Group className='mar'>
              <Form.Label>Brand Name</Form.Label>
              {location.state != null ? (
                <Form.Control
                  type='text'
                  placeholder='Enter your brand name'
                  name='brand_name'
                  id='brand_name'
                  onChange={handleChange}
                  required
                  value={formData.brand_name}
                />
              ) : (
                <Form.Control
                  type='text'
                  placeholder='Enter your brand name'
                  name='brand_name'
                  id='brand_name'
                  onChange={handleChange}
                  required
                  value={formData.brand_name}
                />
              )}

              <Form.Control.Feedback type='invalid'>
                Please enter the brand name.
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mar'>
              <Form.Label>Logo</Form.Label>
              <img className='FileUpload' src={FileUpload} />
              <div className='drag-logo'>
                <input
                  accept='image/jpeg,image/png,image/jpg'
                  type='file'
                  className='file_upload'
                  name='brand_logo'
                  id='brand_logo'
                  value={formData.brand_logo}
                  onChange={handleChangeLogo}
                  required
                />
                <Form.Control.Feedback type='invalid' >
                  Choose logo
                </Form.Control.Feedback>
              </div>
              <br />
              <Form.Text className='text-muted'>
                512x512 or higher recommended. Max 04 MB size
              </Form.Text>
              <br />
            </Form.Group>

            <Form.Group controlId='formBasicEmail' className='mar'>
              <Form.Label>Banner</Form.Label>

              <div {...getRootProps()} className='drag-drop'
                style={{marginBottom: '100px'}}>
                <input
                  {...getInputProps()}
                  name='brand_banner'
                  id='brand_banner'
                  value={formData.brand_banner}
                  required
                />
                <Form.Control.Feedback type='invalid' >
                  Please choose banner
                </Form.Control.Feedback>
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <div className='banner-section'>
                    <img src={Drag} alt='' className='darg-icon' />
                    <p>
                      Drag and drop an image, or{' '}
                      <span className='drag-browser'> Browse </span>
                    </p>
                  </div>
                )}
              </div>

              {/* <Form.Text className='text-muted'>
                960x300 or higher recommended. Max 08 MB size
              </Form.Text> */}
            </Form.Group>
          </div>
          <div className='cp-section-div2'>
            <label>Preview Page</label>
            <div>
              <Card className='text-center cp-card'>
                <Card.Body>
                  <div className='cp-right-icon'>
                    <div>
                      <FiChevronLeft />
                    </div>
                    <div className='cp-right-icon-home'>
                      <FiAlignJustify />
                    </div>
                  </div>
                  <Card.Title>
                    {location.state == null ? (
                      <div>
                        {previewBanner == undefined ? (
                          <img
                            src={PreviewBanner}
                            alt=''
                            className='right-header-img'
                          />
                        ) : (
                          <img
                            src={
                              brandlabapi.mediaURL +
                              'api/media/v3/no-auth?key=' +
                              previewBanner
                            }
                            alt=''
                            className='right-header-img'
                          />
                        )}
                      </div>
                    ) : (
                      <div>
                        {previewBanner == undefined ? (
                          <img
                            src={
                              brandlabapi.mediaURL +
                              'api/media/v3/no-auth?key=' +
                              location.state.brand_banner
                            }
                            alt=''
                            className='right-header-img'
                          />
                        ) : (
                          <img
                            src={
                              brandlabapi.mediaURL +
                              'api/media/v3/no-auth?key=' +
                              previewBanner
                            }
                            alt=''
                            className='right-header-img'
                          />
                        )}
                      </div>
                    )}
                  </Card.Title>
                  {location.state == null ? (
                    <div>
                      {previewFile == undefined ? (
                        <img
                          src={previewFile}
                          alt=''
                          className='right-header-over'
                        />
                      ) : (
                        <img
                          src={
                            brandlabapi.mediaURL +
                            'api/media/v3/no-auth?key=' +
                            previewFile
                          }
                          alt=''
                          className='right-header-over'
                        />
                      )}
                    </div>
                  ) : (
                    <div>
                      {previewFile == undefined ? (
                        <img
                          src={
                            brandlabapi.mediaURL +
                            'api/media/v3/no-auth?key=' +
                            location.state.brand_logo
                          }
                          alt=''
                          className='right-header-over'
                        />
                      ) : (
                        <img
                          src={
                            brandlabapi.mediaURL +
                            'api/media/v3/no-auth?key=' +
                            previewFile
                          }
                          alt=''
                          className='right-header-over'
                        />
                      )}
                    </div>
                  )}
                  <Card.Text className='headre-title'>
                    {formData.brand_name}
                  </Card.Text>

                  <div></div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </section>
        <div className='btn-nxt-bck'>
          <button id='bck' onClick={back}>
            Exit
          </button>
          {location.state == null ? (
            <p id='cntr'>Step 1 of 4</p>
          ) : (
            <p id='cntr'>Step 1 of 2</p>
          )}
          <button id='nxt' type='submit' className='formSubmit' >
            Next
          </button>
        </div>
      </Form>
    </div>
  );
};

export default CreatePage1;
