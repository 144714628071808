import errorHandling from '../errorhandling/ErrorHandle';
import {brandlabsAuth} from './BrandlabsAuth';
import apiUrl from '../utils/brandlabapi';
function getStageInNumber(stages) {
  switch (true) {
    case stages.includes('pregnancy') && stages.includes('parenting') &&
      stages.includes('planning'):
      return ['2', '1', '3'];
    case stages.includes('pregnancy') && stages.includes('parenting'):
      return ['2', '3'];
    case stages.includes('pregnancy') && stages.includes('planning'):
      return ['2', '1'];
    case stages.includes('planning') && stages.includes('parenting'):
      return ['1', '3'];
    case stages.includes('planning'):
      return ['1'];
    case stages.includes('pregnancy'):
      return ['2'];
    case stages.includes('parenting'):
      return ['3'];
    default:
      return null;
  }
}
export default {
  // add post
  postEmailAdd: async (data) => {
    let res = {};
    try {
      if (data._id !== '') {
        res = await brandlabsAuth.
            fetchData(apiUrl.emailAddApi, 'put', {}, data);
      } else {
        res = await brandlabsAuth.
            fetchData(apiUrl.emailAddApi, 'post', {}, data);
      }
      return res.data;
    } catch (err) {
      if (err.response) {
        errorHandling.handleErrors(err.response.data);
        return;
      } else {
        errorHandling.handleErrors('Server error');
        return;
      }
    }
  },

  // get all posts
  getEmailAdds: async () => {
    const response = await brandlabsAuth.
        fetchData(apiUrl.emailAddApi + 's', 'get');
    return response;
  },

  // get post detail
  getAddById: async (id) => {
    try {
      const res = await brandlabsAuth.
          fetchData(apiUrl.emailAddApi + '?id=' + id, 'get');
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  getSearchCount: async (stages, weeks) => {
    const stage = await getStageInNumber(stages);
    console.log(stage);
    const queryData = {
      'query': {
        'i_am': stage,
      },
      'table': 'mst_user',
    };
    if (stage !== null && (stage.includes('2') || stage.includes('4'))) {
      queryData.weeks = weeks;
    }
    try {
      const res = await brandlabsAuth.
          fetchData(apiUrl.searchApi, 'post', {}, queryData );
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  // delete post
  deleteAddById: async (id) => {
    try {
      const res = await brandlabsAuth.
          fetchData(apiUrl.emailAddApi + '?id=' + id, 'delete');
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  // File details by post id
  getFileByAddId: async (id) => {
    try {
      const res = await brandlabsAuth.
          fetchData(apiUrl.emailAddApi + '?id=' + id, 'get');
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },
};
