import React, {useState} from 'react';
import {Col, Row, Container, Card} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/css/main.css';
import Meet from '../assets/img/Group 2113.png';
import Perfect from '../assets/img/Group 2104.png';
import Logo from '../assets/img/logo-new 2.png';
import Pc from '../assets/img/Group 2115.png';
import Dabar from '../assets/img/Group 2116.png';
import Family from '../assets/img/BTP Landing Page 3.png';
import Vector from '../assets/img/Vector.png';
import Channa from '../assets/img/Channa Keshava.jpg';
import Charith from '../assets/img/charith kashyap.jpg';
import ParentsGroup from '../assets/img/pGroup.png';
import mobilePGroup from '../assets/img/mobilePGroup.png';
import Campaign from '../assets/svg/Campaign.svg';
import Product from '../assets/svg/Productivity.svg';
import Shopkeep from '../assets/svg/Shopkeeper.svg';
import Tar from '../assets/svg/Target.svg';
import Graph from '../assets/svg/Graph Setting.svg';
import Manage from '../assets/svg/management.svg';
import ManWithPc from '../assets/svg/image 7.svg';
import BrandIm from '../assets/svg/image 13.svg';
import Category from '../assets/svg/image 9.svg';
import Mom from '../assets/svg/image 10.svg';
import SetupManage from '../assets/svg/image 11.svg';
import Signup from '../dashboard/Signup';
import Otp from '../dashboard/Otp';
import Congratulations from '../dashboard/Congratulations';
import Phone from '../dashboard/Phone';
import VerifyPhone from '../dashboard/VerifyPhone';
import Login from '../dashboard/Login';
import ForgetPassword from './Login/ForgetPassword';
import ResetPassword from './Login/ResetPassword';
import PasswordResetDone from './Login/PasswordResetDone';
import {useNavigate} from 'react-router-dom';

const Homepage = () => {
  const [showSigunUp, setShowSignUp] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [congratulationsModal, setCongratulationsModal] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [phoneOtpShow, setPhoneOtpShow] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showForget, setShowForegt] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showResetPswDone, setShowResetPswDone] = useState(false);
  const navigate = useNavigate();

  const openModal = () => {
    setShowSignUp(true);
    setOtpShow(false);
    setCongratulationsModal(false);
  };

  const handleClickLogin = () => {
    setShowLogin(true);
  };

  const brandPage = () => {
    // window.scrollTo(0, 0);
    // navigate('/brand-pages');
  };

  return (
    <div>
      {showResetPswDone && (
        <PasswordResetDone
          setShowResetPswDone={setShowResetPswDone}
          showResetPswDone={showResetPswDone}
        />
      )}
      {showResetPassword && (
        <ResetPassword
          setShowResetPassword={setShowResetPassword}
          showResetPassword={showResetPassword}
          setShowResetPswDone={setShowResetPswDone}
        />
      )}

      {showForget && (
        <ForgetPassword
          showForget={showForget}
          setShowForegt={setShowForegt}
          setShowResetPassword={setShowResetPassword}
        />
      )}
      {showLogin && (
        <Login
          showLogin={showLogin}
          setShowLogin={setShowLogin}
          setShowSignUp={setShowSignUp}
          setShowForegt={setShowForegt}
          setShowPhone={setShowPhone}
          setOtpShow={setOtpShow}
        />
      )}
      {showSigunUp && (
        <Signup
          showSigunUp={showSigunUp}
          setShowSignUp={setShowSignUp}
          setOtpShow={setOtpShow}
          setShowLogin={setShowLogin}
        />
      )}

      {otpShow && (
        <Otp
          setOtpShow={setOtpShow}
          otpShow={otpShow}
          setCongratulationsModal={setCongratulationsModal}
          setShowPhone={setShowPhone}
        />
      )}

      {congratulationsModal && (
        <Congratulations
          setCongratulationsModal={setCongratulationsModal}
          congratulationsModal={congratulationsModal}
          setShowPhone={setShowPhone}
        />
      )}
      {showPhone && (
        <Phone
          showPhone={showPhone}
          setShowPhone={setShowPhone}
          setPhoneOtpShow={setPhoneOtpShow}
        />
      )}
      {phoneOtpShow && (
        <VerifyPhone
          phoneOtpShow={phoneOtpShow}
          setPhoneOtpShow={setPhoneOtpShow}
        />
      )}

      <div className='container'>
        <div className='row' id='top-gap'>
          <div className='col-sm-6 mobile'>
            <img
              src={Meet}
              style={{width: '100%', height: '100%'}}
              className='loginImageMobile'
            />
          </div>
          <div className='col-sm-6 ' id='parentsLeft'>
            <div className='parentsDoPlay'>
              <h1 className='parentsDoBold'>
                Parents do play favorites with Brands
              </h1>
              <p>
                A new mom is born every 8 seconds. She needs your brand now more
                then ever
              </p>
              <div className='mb-2'>
                <button
                  type='button'
                  className='btn btn-outline-danger btn-lg custom-btn reg'
                  onClick={openModal}
                >
                  Register
                </button>
                <span> </span>
                <button
                  type='button'
                  className='btn btn-danger btn-lg custom-btn lgn reg1'
                  onClick={handleClickLogin}
                >
                  Login
                </button>
              </div>
            </div>
          </div>

          <div className='col-sm-6 pc'>
            <img
              src={Meet}
              className='loginImage'
              style={{width: '100%', height: '100%'}}
            />
          </div>
        </div>
      </div>
      <Container className='text-center yourPerfect'>
        <Card.Img src={Logo} className='btpLogo ' />
        <h2 className='yourPer'>
          Your perfect <span className='solution'>solutions!</span>
        </h2>
        <p className='btpPara'>
          Our innovative blend of marketing and advertising solutions connect
          brands with parents in powerful ways, at key decision-making moments
          throughout the journey called life
        </p>
        <Card.Img src={Perfect} className='perfectPic' />
      </Container>

      <Container className='text-center containerDoMore'>
        <p className='doMore'> Do more with Brand Labs</p>
        <h2 className=' advertising'>
          Ready to start <span className='brandLabsRight'>advertising ?</span>
        </h2>
      </Container>

      <div className='container brandPageConatiner'>
        <div className='row'>
          <div className='col-sm-6' id='brandLeft'>
            <h2>Brand Page Showcase</h2>
            <p className='brandLeftPara'>
              Increase awareness of your business with a free brand page that
              you can make in minutes.
            </p>
            <p onClick={brandPage} className='brandColor'>
              {/* See Brand Pages */}
              Coming Soon
            </p>
          </div>
          <div className='col-sm-6'>
            <img src={Pc} className='brandImage' />
          </div>
        </div>
      </div>
      <Container style={{marginTop: '60px'}}>
        <Row>
          <Col className='sm-6 pc'>
            <Row></Row>
            <div className='half-circle'></div>
            <Card.Img src={Dabar} className='dabarPic ' />
          </Col>

          <Col className='sm-6' id='preciseRight'>
            <h2>Precise Ad Targeting</h2>
            <p>Inovation blend of life-stage marketing</p>
            <p style={{marginTop: '-20px'}}>
              solutions to Reach and engage new , expectant
            </p>
            <p style={{marginTop: '-20px'}}>and experienced moms</p>

            <ul className='preciseUl'>
              <li>Set clear objectives</li>
              <li>Define your audience</li>
              <li>Make impact</li>
            </ul>
            <Col className='sm-6 mobile'>
              <Row className='row'>
                <div className='half-circle'></div>
              </Row>
              <Card.Img src={Dabar} className='dabarPic ' />
            </Col>
          </Col>
        </Row>
      </Container>
      <Container className='containerBuild'>
        <Row>
          <Col className='sm-6'>
            <div className='circleBuild'>
              <div className='text-left circleBuild1'>
                <h2 className='buildRelationship'>
                  Build Relationship that lasts
                </h2>
                <p>
                  Build an active and engaged audience for your business. Nuild
                  a lasting relationship with Parents for long term impacts
                </p>
              </div>
            </div>
          </Col>
          <Col className='sm-6 buildCircle'>
            <Row>
              <div className='circleBuildMobile'></div>
            </Row>
            <Card.Img src={Family} className='familyImage' />
          </Col>
        </Row>
      </Container>
      <div className='take-step-homepage-barndlabs'>
        <div
          className=' text-center goals'
          style={{backgroundColor: '#FEF8DC'}}
          id='towardLeft'
        >
          <Container>
            <div className='row'>
              <div className='col-sm-6' id='buildLeft'>
                <h2 className='leftGoals'>Take steps towards your goals </h2>
                <p className='leftGoals1'>Our secret sauce for your success </p>
              </div>
            </div>
            <div className='onboarding'>
              <div className='circle'>
                <img src={ManWithPc} />
                <p id='on-p'>Come onboard</p>
              </div>

              <div className='right-line'></div>
              <div className='line'></div>
              <div className='circle'>
                <img src={BrandIm} />
                <p id='on-p'>List your brand page</p>
              </div>

              <div className='right-line'></div>
              <div className='line'></div>
              <div className='circle'>
                <img src={Category} />
                <p id='on-p'>Categorize your products</p>
              </div>
              <div className='right-line'></div>
              <div className='line'></div>
              <div className='circle'>
                <img src={SetupManage} />
                <p id='on-p'>Set up & manage campaigns</p>
              </div>
              <div className='right-line'></div>
              <div className='line'></div>
              <div className='circle'>
                <img src={Mom} />
                <p id='on-p'>Get discovered by Moms</p>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <section className='simple-tools-for-every'>
        <div style={{backgroundColor: '#F2FEFF'}}>
          <div className='text-center row'>
            <div
              className='col-sm-6'
              style={{marginTop: '40px', marginLeft: '23%'}}
            >
              <h2 className='leftRes'>Simple tools for every business </h2>
              <p className='leftRes1'>A complete business toolkit</p>
            </div>
          </div>
          <br />
          <div className='container'>
            <div className='row'>
              <div
                className=' col-sm-4 text-left'
                style={{marginRight: '0px'}}
              >
                <img src={Product} className='img-responsive' alt='Image' />
                <h3>Manage Brand Pages</h3>
                <p>
                  Stylizes, emotional representations of your brand using images
                  & videos
                </p>
              </div>

              <div className='col-sm-4 text-left'>
                <img src={Shopkeep} className='img-responsive' alt='Image' />
                <h3>Showcase Your Product</h3>
                <p>Create, categorize and showcase your product cataglogue</p>
              </div>
              <div className='col-sm-4 text-left '>
                <img src={Tar} className='img-responsive' alt='Image' />
                <h3>Target User Precisely</h3>
                <p>
                  Define your audience for smarter ad targeting. Adjust your
                  target audience to be as broad or well-define as you like
                </p>
              </div>
            </div>
          </div>
          <div className='container'>
            <div className='row'>
              <div className='col-sm-4 text-left'>
                <img src={Campaign} className='img-responsive' alt='Image' />
                <h3>Create & Manage Campaigns</h3>
                <p>
                  Select campaign parameters, such as an advertising objective
                  and manage everything on go
                </p>
              </div>
              <div className='col-sm-4 text-left'>
                <img src={Graph} className='img-responsive' alt='Image' />
                <h3>Get Real-time Insights</h3>
                <p>
                  Real time insights and analystics to better understand the
                  impact for your campaign
                </p>
              </div>
              <div className='col-sm-4 text-left'>
                <img src={Manage} className='img-responsive' alt='Image' />
                <h3>Optimize and Get Better</h3>
                <p>
                  Do testing and optimization for Search Engines to improve
                  performance over a period of time
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <Container>
        <div className='text-center'>
          <Card.Img src={Vector} className='vectorLogo' />
        </div>

        <div className='row mb-2'>
          <div className='col-sm-6'>
            <Card className='border-0 card1'>
              <Card.Body>
                <Card.Img src={Channa} className='imgCeo' />
                <div style={{marginLeft: '150px', marginTop: '-90px'}}>
                  <Card.Title>Channa Keshava </Card.Title>
                  <p>CEO, Kidzavalle</p>
                </div>
                <p style={{marginTop: '45px'}}>
                  The BTP Brand labs team came onboard early, helping Kidzvalle
                  by providing key insights into user interests and segments,
                  aligned with their target groups.
                </p>
              </Card.Body>
            </Card>
          </div>
          <div className='col-sm-6 '>
            <Card className='border-0 card2'>
              <Card.Body>
                <Card.Img src={Charith} className='imgCeo' />
                <div style={{marginLeft: '150px', marginTop: '-90px'}}>
                  <Card.Title>Charith Kashyap </Card.Title>
                  <p>CEO, Selvitate</p>
                </div>
                <p style={{marginTop: '45px'}}>
                  BTP Brand Labs is a full-funnel solution that reaches
                  high-intent audiences. It’s the only platform where people
                  actively look for what’s next, so you can reach them at
                  critical moments throughout the decision-making process.
                </p>
              </Card.Body>
            </Card>
          </div>
        </div>
        <Container className='text-center dotSlider'>
          <span className='dot'></span>
        </Container>
      </Container>
      <div className='container'>
        <div className='row'>
          <div className='card border-0 wantsToTalk'>
            <div className='card-body '>
              <div>
                <h3 className='parentsHeading'>Wants to talk to parents ? </h3>
                <button
                  onClick={openModal}
                  className='btn btn-danger btnAdvertise'
                >
                  Advertise with us
                </button>
                <div className='pc'>
                  <img
                    className='card-img-top parentWantPic'
                    src={ParentsGroup}
                    alt='Card image cap'
                  />
                </div>
                <div className='mobile'>
                  <img
                    className='card-img-top parentWantPic'
                    src={mobilePGroup}
                    alt='Card image cap'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
