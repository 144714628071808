import errorHandling from '../errorhandling/ErrorHandle';
import {brandlabsAuth} from './BrandlabsAuth';
import apiUrl from '../utils/brandlabapi';

export default {
  // add ticket
  postTicket: async (ticketData, userDetails, productId, product) => {
    let res = {};
    const data = {
      product: product,
      title: ticketData.title,
      productId: productId,
      category: ticketData.category.value,
      status: 'assigned',
      priority: 'high',
      assignee: {'name': 'Admin',
        'userId': ''},
      message: ticketData.message,
      createdBy: userDetails.fullName,
      userEmail: userDetails.email,
    };
    try {
      res = await brandlabsAuth.fetchData(
          apiUrl.ticketApi,
          'post',
          {},
          data,
      );
      return res.data;
    } catch (err) {
      if (err.response) {
        errorHandling.handleErrors(err.response.data);
        return;
      } else {
        errorHandling.handleErrors('Server error');
        return;
      }
    }
  },

  // send message
  postMessage: async (ticketData, message, userDetails) => {
    console.log(userDetails);
    let res = {};
    const data = {
      _id: ticketData._id,
      message: message,
      createdBy: userDetails.full_name,
      userEmail: userDetails.email,
    };
    try {
      res = await brandlabsAuth.fetchData(
          apiUrl.messageApi,
          'put',
          {},
          data,
      );
      return res.data;
    } catch (err) {
      if (err.response) {
        errorHandling.handleErrors(err.response.data);
        return;
      } else {
        errorHandling.handleErrors('Server error');
        return;
      }
    }
  },

  // get all posts
  getAllTickets: async (query) => {
    let params = '';
    if (query && query.queryKey[1] !== undefined) {
      params = '?productId=' + query.queryKey[1];
    }
    const response = await brandlabsAuth.fetchData(
        apiUrl.ticketApi + 's' + params,
        'get',
    );
    return response;
  },

  // get post detail
  getTicketById: async (id) => {
    try {
      const res = await brandlabsAuth.fetchData(
          apiUrl.ticketApi + '?ticketId=' + id,
          'get',
      );
      return await res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  // get product by Id
  getTicketByProductId: async (id) => {
    try {
      const res = await brandlabsAuth.fetchData(
          apiUrl.ticketApi + '/' + id,
          'get',
      );
      return await res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  // delete post
  deletePostById: async (id) => {
    try {
      const res = await brandlabsAuth.fetchData(
          apiUrl.sponsoredPostApi + '?id=' + id,
          'delete',
      );
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },
};
