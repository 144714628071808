import React from 'react';
import {Button, Card, Modal, Form} from 'react-bootstrap';
import {MdOutlineWatchLater} from 'react-icons/md';
import {AiOutlinePlus} from 'react-icons/ai';
import {DatePicker, Space} from 'antd';

const {RangePicker} = DatePicker;

const Filterday = (prop) => {
  const handleDayRangePicker = (e) => {
    console.log(e.target.value);
  };

  return (
    <Modal
      show={prop.filterDay}
      onHide={() => {
        prop.setFilterDay(false);
      }}
    >
      <Modal.Body>
        <div className='col-2'>
          <div id='parent'>
            <div id='wide'>
              <div>
                <Button
                  aria-controls='example-collapse-text'
                  className='filter-btn-dash-last'
                >
                  <MdOutlineWatchLater /> Filter By Day
                </Button>
              </div>
              <Card className='filter-drop-card'>
                <div className='filter-drop'>
                  <Form.Check
                    id='1'
                    type='radio'
                    aria-label='radio 1'
                    label='Last day'
                  />
                  <Form.Check
                    id='2'
                    type='radio'
                    aria-label='radio 2'
                    label='Last 7 days'
                  />
                  <Form.Check
                    id='3'
                    type='radio'
                    aria-label='radio 3'
                    label='Last 14 days'
                  />
                  <Form.Check
                    id='4'
                    type='radio'
                    aria-label='radio 4'
                    label='Last 30 days'
                  />
                  <hr />
                  <button className='text-center'>
                    <AiOutlinePlus /> Custom
                  </button>
                </div>
              </Card>
            </div>
            <div id='arrow'>
              <div className='filter-drop-se'>
                <div>
                  <Button
                    aria-controls='example-collapse-text'
                    className='filter-btn-dash-last'
                  >
                    <MdOutlineWatchLater />
                    Last 30 Day
                  </Button>
                </div>
                <form action='' id='7'>
                  <Card className='border-0 mt-2 day-filter'>
                    <div className='filter-drop '>
                      <Space direction='vertical'>
                        <RangePicker
                          picker='week'
                          className='dateRangePicker'
                          onClick={(e) => {
                            handleDayRangePicker(e);
                          }}
                        />
                      </Space>
                    </div>
                  </Card>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Filterday;
