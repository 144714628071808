/* eslint-disable require-jsdoc */
import React, {useState, useEffect} from 'react';
import {Button, Modal, Table} from 'react-bootstrap';
import propTypes from 'prop-types';
import TransactionApi from '../../services/TransactionApi';
import {useParams} from 'react-router-dom';
import moment from 'moment';

export default function Transaction(props) {
  Transaction.propTypes = {
    show: propTypes.bool,
    handleClose: propTypes.any,
  };
  const params = useParams();
  const [transactionDetails, setTransactionDetails] = useState({
    created_at: '',
    orderId: '',
    paymentId: '',
    paymentMode: '',
    userId: '',
    status: '',
    amount: '',
  });
  const getTransactionDetails = async () => {
    const data = await TransactionApi.getTransactionById(params.id);
    if (data && data.data[0]) {
      setTransactionDetails(data.data[0]);
    }
  };

  useEffect(() => {
    if (props.show === true) {
      getTransactionDetails();
    }
  }, [props.show]);
  return (
    <Modal size="lg" show={props.show}
      onHide={props.handleClose} backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Transaction Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {transactionDetails.orderId ?
          <Table className="striped bordered hover">
            <tbody>
              {transactionDetails.amount ?<tr><td>Amount</td>
                <td>{(transactionDetails.paymentMode ==='razorPay' ? '₹' :
                '$') + ((transactionDetails.amount)/100).toLocaleString()}
                </td></tr>: null
              }
              <tr>
                <td>Order Id</td><td>{transactionDetails.orderId}</td>
              </tr>
              <tr>
                <td>Payment Mode</td><td>{transactionDetails.paymentMode}</td>
              </tr>
              <tr>
                <td>Payment Id</td><td>{transactionDetails.paymentId}</td>
              </tr>
              <tr>
                <td>Status</td><td>{transactionDetails.status}</td>
              </tr>
              <tr>
                <td>Created Time</td><td>{moment(transactionDetails.created_at).
                    format('DD/MM/YYYY')}</td>
              </tr>
            </tbody>
          </Table>:
          <span>No data found</span>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={props.handleClose}>
            Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
