import React, {useState, useEffect, useContext} from 'react';
import {Col, Row, Form, InputGroup} from 'react-bootstrap';
import AppContext from '../dashboard/dashboardPages/createpages/Context';
import ProfileApi from '../services/ProfileApi';

const BusinessDetails = () => {
  const myContext = React.useContext(AppContext);
  const updateContext = myContext.userDetails;
  const {formData, setFormData, submitData, gstData} = useContext(AppContext);
  const [userId, setUserId] = useState();
  const [validated, setValidated] = useState(false);

  const next = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (
      form.checkValidity() === false ||
      null ||
      formData.business_country == undefined ||
      formData.business_city == undefined ||
      formData.business_phone_number == undefined
    ) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      submitData();
    }
    setValidated(true);
  };

  const back = () => {
    updateContext.setStep(updateContext.currentPage - 1);
  };

  const handleCahnge = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == 'business_name') {
      setFormData({
        ...formData,
        ['business_name']: value.replace(/[^a-zA-Z\s]/g, ''),
      });
    } else if (name == 'business_phone_number') {
      setFormData({
        ...formData,
        ['business_phone_number']: value.replace(/\D/g, ''),
      });
    } else if (name == 'business_pincode') {
      setFormData({
        ...formData,
        ['business_pincode']: value.replace(/\D/g, ''),
      });
    } else if (name == 'business_city') {
      setFormData({
        ...formData,
        ['business_city']: value.replace(/[^a-zA-Z\s]/g, ''),
      });
    } else {
      setFormData({
        ...formData,
        [name]: e.target.value,
        ['user_id']: userId,
      });
    }
  };

  const getUserDeatils = async () => {
    await ProfileApi.getuserDetail().then((res)=>{
      setUserId(res.data.data[0].sk_user_id);
      localStorage.setItem('full_name', res.data.data[0].full_name);
    }).catch((err)=>{
      console.log(err);
    });
  };

  useEffect(() => {
    getUserDeatils();
  }, []);

  return (
    <div className='businessType1'>
      <div className='rightContainer'>
        <h3>Let’s fill your business details</h3>
        <Form className='formWidth' noValidate validated={validated}>
          <Form.Group className='mb-3'>
            <Form.Label className='labelHead'>
              Business name and contact
            </Form.Label>

            {formData.gst_number != undefined && (
              <Form.Control
                type='text'
                placeholder='Business Name'
                autoComplete='off'
                name='business_name'
                id='business_name'
                value={gstData.tradeName}
                required
              />
            )}
            {formData.gst_number == undefined && (
              <Form.Control
                type='text'
                placeholder='Business Name'
                autoComplete='off'
                name='business_name'
                id='business_name'
                value={formData.business_name}
                onChange={handleCahnge}
                required
              />
            )}

            <Form.Control.Feedback type='invalid'>
              Please fill the business name.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className='mb-3'>
            <InputGroup className='mb-2'>
              <InputGroup.Text>+91</InputGroup.Text>
              <Form.Control
                type='text'
                placeholder='Business Phone No'
                autoComplete='off'
                name='business_phone_number'
                id='business_phone_number'
                value={formData.business_phone_number}
                onChange={handleCahnge}
                pattern='[1-9]{1}[0-9]{9}'
                maxLength={10}
                title='Please enter exactly 10 digits'
                required
              />
              <Form.Control.Feedback type='invalid'>
                Please fill the business phone number.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <hr />

          <Form.Group className='mb-3' controlId='formBasicEmail'>
            <Form.Label className='labelHead'>
              Which city are you operating in?
            </Form.Label>

            {formData.gst_number != undefined && (
              <Form.Control
                type='text'
                placeholder='Business Address'
                autoComplete='off'
                name='business_address'
                id='business_address'
                value={gstData.adr}
                required
              />
            )}
            {formData.gst_number == undefined && (
              <Form.Control
                type='text'
                placeholder='Business Address'
                autoComplete='off'
                name='business_address'
                id='business_address'
                value={formData.business_address}
                onChange={handleCahnge}
                required
              />
            )}

            <Form.Control.Feedback type='invalid'>
              Please fill the business address.
            </Form.Control.Feedback>
          </Form.Group>
          <Col>
            <Row>
              <Col>
                {formData.gst_number != undefined && (
                  <Form.Control
                    type='text'
                    className='form-control'
                    placeholder='Area'
                    id='business_area'
                    name='business_area'
                    value={gstData.stj}
                    required
                  />
                )}
                {formData.gst_number == undefined && (
                  <Form.Control
                    type='text'
                    className='form-control'
                    placeholder='Area'
                    id='business_area'
                    name='business_area'
                    value={formData.business_area}
                    onChange={handleCahnge}
                    required
                  />
                )}

                <Form.Control.Feedback type='invalid'>
                  Please fill the business area.
                </Form.Control.Feedback>
              </Col>
              <Col>
                <Form.Control
                  type='text'
                  className='form-control'
                  placeholder='City'
                  name='business_city'
                  id='business_city'
                  value={formData.business_city}
                  onChange={handleCahnge}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please fill the business city.
                </Form.Control.Feedback>
              </Col>
            </Row>
            <Row className='mt-2'>
              <Col>
                <Form.Control
                  type='text'
                  className='form-control'
                  placeholder='Country'
                  name='business_country'
                  id='business_city'
                  value={formData.business_country}
                  onChange={handleCahnge}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please fill the country.
                </Form.Control.Feedback>
              </Col>
              <Col>
                {formData.gst_number != undefined && (
                  <Form.Control
                    type='text'
                    className='form-control'
                    placeholder='Pin Code'
                    name='business_pincode'
                    id='business_pincode'
                    value={gstData.pincode}
                    required
                  />
                )}

                {formData.gst_number == undefined && (
                  <Form.Control
                    type='text'
                    className='form-control'
                    placeholder='Pin Code'
                    name='business_pincode'
                    id='business_pincode'
                    value={formData.business_pincode}
                    onChange={handleCahnge}
                    maxLength={6}
                    required
                  />
                )}

                <Form.Control.Feedback type='invalid'>
                  Please fill the pin code.
                </Form.Control.Feedback>
              </Col>
            </Row>
          </Col>
        </Form>
      </div>
      <div className='btn-nxt-bck-on-boarding'>
        <button id='bck' onClick={back}>
          Back
        </button>
        <p id='cntr'>2/2 Page</p>
        <button id='nxt' type='submit' className='formSubmit' onClick={next}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default BusinessDetails;
