/* eslint-disable max-len */
import React, {useState} from 'react';
import {Button, Modal, Form, Tooltip, OverlayTrigger} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Google from '../dashboard/assets/svg/image 378.svg';
import axios from 'axios';
import Swal from 'sweetalert2';
import {useNavigate} from 'react-router-dom';
import {useGoogleLogin} from '@react-oauth/google';
import {AiOutlineEyeInvisible, AiOutlineEye} from 'react-icons/ai';
import ProfileApi from '../services/ProfileApi';
import MyPagesApi from './dashboardPages/MyPagesApi';

const Signup = (prop) => {
  const [validated, setValidated] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [formData, setformData] = useState({});

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = JSON.stringify({
      registred_from: 'brandlabs-registration',
      email: formData.email,
      password: formData.password,
      full_name: formData.full_name,
      c_code: '',
      mobile: '',
      country: '91',
      pincode: '583 400',
      signupsource: 'brandlabs-web',
      due_date_parent: '06-09-2022',
      dob: '06-09-2004',
      alies_name: '',
      gender: 'Female',
      i_am: '2',
      no_of_children: '0',
      username_momnexus: 'egg-4456',
      childata: [],
      firebase_device_id: process.env.REACT_APP_Global_Token_Firbase,
    });
    const form = e.currentTarget;
    if (
      form.checkValidity() === false ||
      (formData.email == '' &&
        formData.password == '' &&
        formData.full_name == '')
    ) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      const data1 = {
        value: formData.email,
        type: 'email',
      };
      ProfileApi.userExists(data1)
          .then(function(resp) {
            // console.log(resp);
            if (resp.data.status == true) {
              Swal.fire({
                icon: 'error',
                title: 'This Email is Already Registered',
                showConfirmButton: false,
                timer: 2000,
              });
              prop.setOtpShow(false);
            } else if (resp.data.status == false) {
              setLoader(true);
              ProfileApi.registerUserAdd(data)
                  .then(function(response) {
                    const token = response.data.data.Accesstoken;
                    const username = formData.email;
                    if (response.data.status === true) {
                      sessionStorage.setItem('accessToken', token);
                      sessionStorage.setItem('username', username);
                      localStorage.setItem('full_name', formData.full_name);
                      setLoader(false);
                      prop.setShowSignUp(false);
                      prop.setOtpShow(true);
                    }
                  })
                  .catch(function(er) {
                    console.log(er);
                  });
            }
          })
          .catch(function(errorrrrrrrrrr) {
            console.log(errorrrrrrrrrr);
          });
    }
    setValidated(true);
  };




  const loginwithGoogle = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );
        const data = JSON.stringify({
          email: res.data.email,
          c_code: "",
          mobile: "",
          country: "91",
          pincode: "583 400",
          signupsource: "",
          due_date_parent: "",
          dob: "",
          full_name: res.data.given_name,
          alies_name: "",
          gender: "Female",
          i_am: "2",
          password: "",
          registred_from: "",
          no_of_children: "0",
          username_momnexus: "",
          childata: [],
          firebase_device_id: process.env.REACT_APP_Global_Token_Firbase,
        });
        ProfileApi.registerUserAdd(data)
          .then(function (response) {
            console.log("wfwf", response);
            if (response.data.status === true) {
              setLoader(false);
              localStorage.setItem(
                "accessToken",
                response.data.data.Accesstoken
              );
              sessionStorage.setItem(
                "accessToken",
                response.data.data.Accesstoken
              );
              sessionStorage.setItem("username", res.data.email);
              sessionStorage.setItem("profilepic", res.data.picture);
              sessionStorage.setItem("given_name", res.data.given_name);
              localStorage.setItem("full_name", res.data.name);
              prop.setShowLogin(false);
              Swal.fire({
                icon: "success",
                title: "Welcome Back!",
                showConfirmButton: false,
                timer: 1000,
              });
              prop.setShowLogin(false);
              ProfileApi.getuserDetail()
                .then((resn) => {
                  // console.log(resn,"resm");
                  MyPagesApi.getOnboarding(resn.data.data[0].sk_user_id)
                    .then((reen) => {
                      if (reen.data.message == "failed") {
                        if (resn.data.data[0].c_code === "IN") {

                          if(resn.data.data[0].emil_verification_status == "0" &&
                          resn.data.data[0].mobile_verification_status == "0" ){
                            navigate("/verify");
                          }else{

                            navigate("/on-boarding");
                          }
                        } else {
                          if (
                            resn.data.data[0].emil_verification_status == "0" &&
                          resn.data.data[0].mobile_verification_status == "0" 
                          ) {
                            navigate("/verify");
                            
                          }else{
                            navigate("/abroad");
                          }
                        }
                      } else {
                        if (
                          resn.data.data[0].emil_verification_status == "0" &&
                          resn.data.data[0].mobile_verification_status == "0" &&
                          resn.data.data[0].c_code != "IN"
                        ) {
                          navigate("/verify");
                        } else if (
                          resn.data.data[0].c_code != "IN" &&
                          resn.data.data[0].mobile != ""
                        ) {
                          navigate("/dashboard/sponsored-posts");
                        } else if (
                          resn.data.data[0].emil_verification_status == "1" ||
                          resn.data.data[0].mobile_verification_status == "1"
                        ) {
                          navigate("/dashboard/sponsored-posts");
                        } else {
                          navigate("/verify");
                        }
                      }
                    })
                    .catch((er) => {
                      console.log(er);
                    });
                })
                .catch((error) => {
                  console.log(error);
                });
            } else if (response.data.status === false) {
              navigate("/");
              setLoader(false);
              Swal.fire({
                icon: "error",
                text: "Your account is not registered please create your account.",
                showConfirmButton: true,
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: response.data.message,
                showConfirmButton: false,
              });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } catch (err) {
        console.log(err);
      }
    },
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == 'full_name') {
      setformData({
        ...formData,
        ['full_name']: value.replace(/[^a-zA-Z\s]/gi, ''),
      });
    } else if (name=='password') {
      setformData({
        ...formData,
        ['password']: value.replace(/\s/g, ''),
      });
    } else {
      setformData({...formData, [e.target.name]: e.target.value});
    }
  };

  const handleLogin = () => {
    prop.setShowLogin(true);
    prop.setShowSignUp(false);
  };

  const tooltip = (
    <Tooltip id="tooltip">
        Minimum 8 characters,
        At least one digit ,
        one upper case , one lower case,
        one special character which includes !@#$%&*()-+=^,
        no white space.
    </Tooltip>
  );

  return (
    <>
      {loader ? (
        <div className='text-center vackground'>
          <div className='loader'></div>
        </div>
      ) : (
        <Modal
          backdrop='static'
          show={prop.showSigunUp}
          onHide={() => {
            prop.setShowSignUp(false);
          }}
        >
          <Modal.Header closeButton className='border-0'>
            <Modal.Title>Sign up for a brandlabs account</Modal.Title>

          </Modal.Header>
          <Modal.Body className='modalBody'>
            <Form
              autoComplete='off'
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className='signupHomepage'
            >
              <Form.Group
                className='mb-3'
              >
                <Form.Control
                  type='hidden'
                  placeholder='brandlabs-registration'
                  autoFocus
                  value={formData.registred_from}
                  id='registred_from'
                  name='registred_from'
                  required
                />
              </Form.Group>
              <Form.Group
                className='mb-3'
              >
                <input
                  className='rg-form'
                  type='text'
                  placeholder='Your Name'
                  name='full_name'
                  autoFocus
                  onChange={handleChange}
                  value={formData.full_name}
                  required
                />
                <Form.Control.Feedback type='invalid'>
                  Please fill your full name.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group
                className='mb-3'
                controlId='exampleForm.ControlInput1'
              >
                <input
                  className='rg-form'
                  type='email'
                  placeholder='Email'
                  name='email'
                  autoFocus
                  onChange={handleChange}
                  value={formData.email}
                  required
                  pattern='[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$'
                />

                <Form.Control.Feedback type='invalid'>
                  Please fill your email.
                </Form.Control.Feedback>
              </Form.Group>


              <Form.Group
                className='mb-3'
              >
                <span>
                  <OverlayTrigger placement="top" overlay={tooltip}>
                    <input
                      className='rg-form'
                      type={passwordShown ? 'text' : 'password'}
                      placeholder='Password'
                      onChange={handleChange}
                      name='password'
                      value={formData.password}
                      required
                      maxLength={16}
                      pattern='^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,12}$'
                    />
                  </OverlayTrigger>
                  <Form.Control.Feedback type='invalid'>
                    Please fill your password.
                  </Form.Control.Feedback>
                  <span onClick={togglePasswordVisiblity}>
                    {passwordShown == true && (
                      <AiOutlineEyeInvisible className='eyeviewer'>
                        {' '}
                      </AiOutlineEyeInvisible>
                    )}
                    {passwordShown == false && (
                      <AiOutlineEye className='eyeviewer'></AiOutlineEye>
                    )}
                  </span>
                </span>
              </Form.Group>
              <Button
                variant='danger'
                className='col-12 customPopupBtn'
                type='submit'
              >
                Create My Account
              </Button>
              <p className='text-center or'>or</p>
              <Button className='col-12 withGoogle ' onClick={loginwithGoogle}>
                <img src={Google} alt='' className='googleFont' /> Continue With
                Google
              </Button>
              <p className='text-center haveAccount'>
                Already have an account ?{' '}
                <span className='text-danger' onClick={handleLogin}>
                  Login
                </span>
              </p>
            </Form>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default Signup;
