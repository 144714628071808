import React, {useState, useContext, useRef} from 'react';
import {Collapse, Form, Card} from 'react-bootstrap';
import {AiOutlineInfoCircle} from 'react-icons/ai';
import {AiFillCheckCircle} from 'react-icons/ai';
import Bag from '../dashboard/assets/svg/bag.svg';
import Home from '../dashboard/assets/svg/home.svg';
import AppContext from '../dashboard/dashboardPages/createpages/Context';
import brandlabapi from '../utils/brandlabapi';
import FileUploadApi from '../services/FileUploadApi';
import errorHandling from '../errorhandling/ErrorHandle';

const BusinessType = () => {
  const myContext = React.useContext(AppContext);
  const updateContext = myContext.userDetails;
  const [open, setOpen] = useState(false);
  const [panOpen, setPenOpen] = useState(false);
  const [previewFile, setPreviewFile] = useState();
  const {formData, setFormData, gstData, getGst, setPanFile, setGstData} =
    useContext(AppContext);
  const [validated, setValidated] = useState(false);
  const GSThandle = () => {
    setOpen(!open);
    setPenOpen(false);

    // setFormData({});
  };
  const panHandler = () => {
    setPenOpen(!panOpen);
    setOpen(false);
    // setFormData({});
    setGstData([]);
    setOpen(false);
  };

  const handleSubmitNext = (e) => {
    console.log(e);
    e.preventDefault();
    if (formData.gst_number != undefined) {
      e.preventDefault();
      setOpen(true);
      setPenOpen(false);
      const form = e.currentTarget;
      if (form.checkValidity() === false || !validateGST(formData.gst_number)) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        updateContext.setStep(updateContext.currentPage + 1);
        getGst();
      }
      setValidated(true);
    } else if (formData.gst_number == undefined) {
      e.preventDefault();
      setPenOpen(true);
      setOpen(false);

      const form = e.currentTarget;
      if (
        form.checkValidity() === false ||
        !validatePan(formData.pan_card_number)
      ) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        updateContext.setStep(updateContext.currentPage + 1);
      }
      setValidated(true);
    }
  };

  const back = () => {
    // updateContext.setStep(updateContext.currentPage - 1)
    // navigate('/dashboard')
  };

  const validateGST = (number) => {
    const GST_REGEX = /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z\d]{1}$/;
    return GST_REGEX.test(number);
  };

  const validatePan = (pan) => {
    const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    return panRegex.test(pan);
  };

  const handleChangeGst = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
    // setOpen(true);
  };

  const inputRef = useRef(HTMLInputElement);

  const handleUploadClick = (e) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleFileChange = async (e) => {
    const fileData = await FileUploadApi.uploadFile(
        'brandlabs',
        'brandlabs',
        e.target.files[0],
    );
    try {
      if (fileData.data) {
        setPanFile(fileData.data.key);
        setPreviewFile(fileData.data.key);
      }
    } catch (err) {
      errorHandling.handleErrors(err);
    }
  };

  const handleChangePan = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  const handleSubmitGst = (e) => {
    e.preventDefault();
    setOpen(true);
    setPenOpen(false);
    const form = e.currentTarget;
    if (form.checkValidity() === false || !validateGST(formData.gst_number)) {
      e.preventDefault();
      e.stopPropagation();
    } else {
      updateContext.setStep(updateContext.currentPage + 1);
      getGst();
    }
    setValidated(true);
  };

  return (
    <div className='businessType1'>
      <div className='rightContainer'>
        <h3>First up, choose your business type</h3>
        <div style={{display: 'flex'}}>
          <Card
            style={{width: '18rem'}}
            className='col-6 mt-2 card1Busi'
            onClick={GSThandle}
            aria-controls='example-collapse-text'
            aria-expanded={open}
          >
            <Card.Body>
              <Card.Title>
                {' '}
                <img src={Bag} alt='' />
                <AiFillCheckCircle className='firstSign' />
              </Card.Title>
              <Card.Subtitle className='mb-2 '>
                Business with GST{' '}
              </Card.Subtitle>
              <Card.Text className='pCardText'>
                Choose this if you’re a registered business with GST
                certification.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card
            style={{width: '18rem'}}
            className='col-6 mt-2 card1Busi  second_onborading_card'
            onClick={panHandler}
            aria-controls='example-collapse-text'
            aria-expanded={panOpen}
          >
            <Card.Body>
              <Card.Title>
                {' '}
                <img src={Home} alt='' />{' '}
                <AiFillCheckCircle className='firstSign' />
              </Card.Title>
              <Card.Subtitle className='mb-2'>
                Small/home based business
              </Card.Subtitle>
              <Card.Text className='pCardText'>
                Choose this if you’re running it as a small, home based setup.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <p className='pTagRequired'>
          <AiOutlineInfoCircle className='requiredInfo' /> You’ll be required to
          submit relevant proofs to verify either of the above.
        </p>
        <Form noValidate validated={validated}>
          <Collapse in={open}>
            <div id='example-collapse-text'>
              <div className='formWidth'>
                <Form.Group className='mb-3'>
                  <Form.Label className='labelHead'>Your GST No.</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Type in your GST No. here'
                    autoComplete='off'
                    name='gst_number'
                    value={formData.gst_number}
                    id='gst_number'
                    onChange={handleChangeGst}
                    required
                    pattern='^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]
                    {1}[1-9A-Z]{1}Z[0-9A-Z]{1}$'
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please fill the correct Gst Number
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </Collapse>

          <Collapse in={panOpen}>
            <div id='example-collapse-text'>
              <div className='formWidth'>
                <Form.Group className='mb-3'>
                  <Form.Label className='labelHead'>Your Pan No.</Form.Label>
                  {formData.gst_number != undefined ? (
                    <Form.Control
                      type='text'
                      placeholder='Type in your PAN No. here'
                      autoComplete='off'
                      id='pan_card_number'
                      name='pan_card_number'
                      value={gstData.pan}
                      required
                      pattern='^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$'
                    />
                  ) : (
                    <Form.Control
                      type='text'
                      placeholder='Type in your PAN No. here'
                      autoComplete='off'
                      id='pan_card_number'
                      name='pan_card_number'
                      value={formData.pan_card_number}
                      onChange={handleChangePan}
                      required
                      pattern='^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$'
                    />
                  )}

                  <Form.Control.Feedback type='invalid'>
                    Please fill the correct PAN Number
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className='formWidth'>
                <label className='upload_head'>Upload your PAN card copy</label>
                <br />
                <div>
                  <br />
                  <input
                    ref={inputRef}
                    className='d-none'
                    type='file'
                    onChange={handleFileChange}
                    name='pan_card_image'
                    value={formData.pan_card_image}
                  />
                  <button
                    className='btn_upload_onboarding'
                    onClick={handleUploadClick}
                  >
                    Upload file
                  </button>
                </div>
              </div>

              {previewFile == undefined ? (
                <img className='d-none' />
              ) : (
                <img
                  src={
                    brandlabapi.mediaURL +
                    'api/media/v3/no-auth?key=' +
                    previewFile
                  }
                  alt=''
                  className='pan_preview_image'
                />
              )}
            </div>
          </Collapse>
        </Form>
      </div>
      <div className='btn-nxt-bck-on-boarding'>
        <button id='bck' onClick={back}></button>
        <p id='cntr'>1/2 Page</p>
        {open == false ? (
          <button
            id='nxt'
            type='submit'
            className='formSubmit'
            onClick={(event) => handleSubmitNext(event, formData)}
          >
            Next
          </button>
        ) : (
          <button
            id='nxt'
            type='submit'
            className='formSubmit'
            onClick={(event) => handleSubmitGst(event, formData)}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default BusinessType;
