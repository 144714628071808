import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import Homepage from './brandlabs/Homepage';
import Header from './brandlabs/Header';
import Footer from './brandlabs/Footer';
import Coming from './brandlabs/Coming';
import {Routes, Route} from 'react-router-dom';
import {useState} from 'react';
import Dashboard from './dashboard/Dashboard';
import {useEffect} from 'react';
import NoPage from './brandlabs/NoPage';
import CreatePage from './dashboard/dashboardPages/createpages/CreatePage';
import Confirmation from './dashboard/dashboardPages/createpages/Confirmation';
import {logoutAllTabs} from './auth/auth';
import Solutions from './brandlabs/headerpages/Solutions';
import Utilities from './brandlabs/headerpages/Utilities';
import Brand from './brandlabs/headerpages/Brand';
import ProductPage from './brandlabs/headerpages/ProductPage';
import Onboarding from './onboarding/Onboarding';
import Abroad from './onboarding/Abroad';
import Login from './dashboard/Login';
import UserVerify from './brandlabs/UserVerify';
import Signup from './dashboard/Signup';
import VerifyPhone from './dashboard/VerifyPhone';
import Phone from './dashboard/Phone';
import Otp from './dashboard/Otp';
import ForgetPassword from './brandlabs/Login/ForgetPassword';
import ResetPassword from './brandlabs/Login/ResetPassword';
import PasswordResetDone from './brandlabs/Login/PasswordResetDone';
import Congratulations from './dashboard/Congratulations';

const App = () => {
  const [show, setShow] = useState(true);
  const [showLogin, setShowLogin] = useState(false);
  const [showSigunUp, setShowSignUp] = useState(false);
  const [phoneOtpShow, setPhoneOtpShow] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showForget, setShowForegt] = useState(false);
  const [showResetPswDone, setShowResetPswDone] = useState(false);
  const [congratulationsModal, setCongratulationsModal] = useState(false);
  useEffect(() => {
    setInterval(() => {
      if (
        window.location.pathname == '/' ||
        window.location.pathname == 'Coming-Soon'
      ) {
        setShow(true);
      }
    }, 100);
  });

  useEffect(() => {
    logoutAllTabs();
  }, []);

  return (
    <>
      <div className='allFile'>
        {show && <Header />}
        <Routes basename='/'>
          <Route path='/' element={<Homepage />} />
          <Route path='/Coming-soon' element={<Coming />} />
          <Route
            path='/on-boarding'
            element={<Onboarding setShow={setShow} />}
          />
          <Route
            path='/dashboard/*'
            element={<Dashboard setShow={setShow} />}
          />
          <Route
            path='/create-page'
            element={<CreatePage setShow={setShow} />}
          />
          <Route
            path='/confirmation'
            element={<Confirmation setShow={setShow} />}
          />
          <Route path='/solutions' element={<Solutions />} />
          <Route path='/utilities' element={<Utilities />} />
          <Route path='/brand-pages' element={<Brand />} />
          <Route path='/product-pages' element={<ProductPage />} />
          <Route path='/abroad' element={<Abroad setShow={setShow} />} />
          <Route path='/verify' element={<UserVerify setShow={setShow} />} />
          <Route path='*' element={<NoPage />} />
        </Routes>
        {show && <Footer />}
        <Login open={showLogin} onClose={() => setShowLogin(false)} />
        <Signup open={showSigunUp} onClose={() => setShowSignUp(false)} />
        <VerifyPhone
          open={phoneOtpShow}
          onClose={() => setPhoneOtpShow(false)}
        />
        <Phone open={showPhone} onClose={() => setShowPhone(false)} />
        <Otp open={otpShow} onClose={() => setOtpShow(false)} />
        <ForgetPassword
          open={showForget}
          onClose={() => setShowForegt(false)}
        />
        <ResetPassword
          open={showResetPassword}
          onClose={() => setShowResetPassword}
        />
        <PasswordResetDone
          open={showResetPswDone}
          onClose={() => setShowResetPswDone(false)}
        />
        <Congratulations
          oepn={congratulationsModal}
          onClose={() => setCongratulationsModal(false)}
        />
      </div>
    </>
  );
};

export default App;
