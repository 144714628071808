import React, {useEffect} from 'react';
import {Link, Route, Routes, useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

import {ListGroup} from 'react-bootstrap';
import Homepage from './dashboardPages/Homepage';
import MyCampaigns from './dashboardPages/MyCampaigns';
import MyPages from './dashboardPages/MyPages';
import Setting from './dashboardPages/Setting';
// import Home from "../dashboard/assets/svg/HomeDash.svg";
// import CampSvg from "../dashboard/assets/svg/Camp.svg";
import Page from '../dashboard/assets/svg/Mypage.svg';
import SupportSvg from '../dashboard/assets/svg/Support.svg';
import SponsoredSvg from '../dashboard/assets/svg/sponsored.svg';
import SponsoredPosts from './sponsoredPosts/SponsoredPosts';
import Header from './Header';
import AddSponsoredPost from './sponsoredPosts/AddSponsoredPost';
import SponsoredPostDetail from './sponsoredPosts/SponsoredPostDetail';
import EmailAdds from './emailAdds/EmailAdds';
import AddEmailAdd from './emailAdds/AddEmailAdd';
import Tickets from './tickets/Tickets';
import TicketDetails from './tickets/TicketDetails';
import EmailAddDetail from './emailAdds/EmailAddDetail';

const Dashboard = (prop) => {
  const data = [
    // {
    //   name: "Homepage",
    //   src: Home,
    //   path: "/dashboard",
    // },
    {
      name: 'My Pages',
      src: Page,
      path: '/dashboard/my-pages',
    },
    // {
    //   name: "My Campaigns",
    //   src: CampSvg,
    //   path: "/dashboard/my-campaigns",
    // },
    // {
    //     name: "Settings",
    //     src: SettingSvg

    // },
    {
      name: 'Sponsored Posts',
      src: SponsoredSvg,
      path: '/dashboard/sponsored-posts',
    },
    // {
    //   name: 'Email Adds',
    //   src: SponsoredSvg,
    //   path: '/dashboard/email-adds',
    // },
    {
      name: 'Support',
      src: SupportSvg,
      path: '/dashboard/support',
    },
  ];
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (sessionStorage.getItem('username') == null) {
      navigate('/');
    }
  }, []);

  useEffect(() => {
    prop.setShow(false);
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('username') == '') {
      <redirect to="/" />;
    }
  }, []);

  return (
    <div className="mainPageDashboard">
      <div className="dashboardHeader">
        <Header />
      </div>
      <div className="dashboardSideMenu">
        <div className="dashboardSideBarMenu">
          <ListGroup>
            {data.map((value, index) => {
              return <div key={index + 1} className="dashboard-directory">
                <Link
                  className={
                    'dr-dashboard ' +
                    ((location.pathname === value.path ||
                      location.pathname.includes(value.path)) ? 'activedb' : '')
                  }
                  to={value.path}
                >
                  <img
                    src={value.src}
                    alt={value.name}
                    className="dashboard-icon"
                  />
                  <span>{value.name}</span>
                </Link>
              </div>;
            })}
          </ListGroup>
        </div>
      </div>
      <div className="Col">
        <Routes basename="/dashboard">
          <Route path="/my-campaigns" element={<MyCampaigns />} />
          <Route path="/my-pages" element={<MyPages />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/support" element={<Tickets />} />
          <Route
            path="/sponsored-posts/detail/:id"
            element={<SponsoredPostDetail />}
          />
          <Route
            path="/sponsored-posts/edit-post/:id"
            element={<AddSponsoredPost />}
          />
          <Route
            path="/sponsored-posts/create-post"
            element={<AddSponsoredPost />}
          />
          <Route path="/sponsored-posts" element={<SponsoredPosts />} />
          <Route
            path="/email-adds/detail/:id"
            element={<EmailAddDetail />}
          />
          <Route
            path="/email-adds/edit-add/:id"
            element={<AddEmailAdd />}
          />
          <Route
            path="/email-adds/create-add"
            element={<AddEmailAdd />}
          />
          <Route path="/email-adds" element={<EmailAdds />} />
          <Route
            path="/support/detail/:id"
            element={<TicketDetails />}
          />
          <Route path="/" element={<Homepage />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;
