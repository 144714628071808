import React from 'react';
import '../assets/css/desktop.css';
import {Link} from 'react-router-dom';

const NoPage = () => {
  return (
    <div>
      <div className='card text-center comming'>
        <div className='card-body'>
          <h1 className='card-text no_page'>404 No Page Found</h1>
          <Link to='/' className='btn btn-light'>
            Go Back{''}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NoPage;
