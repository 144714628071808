import errorHandling from '../errorhandling/ErrorHandle';
import {brandlabsAuth} from './BrandlabsAuth';
import apiUrl from '../utils/brandlabapi';
import brandlabapi from '../utils/brandlabapi';


export default {
  // add post
  postSponsoredPost: async (data) => {
    let res = {};
    try {
      if (data._id !== '') {
        res = await brandlabsAuth.fetchData(
            apiUrl.sponsoredPostApi,
            'put',
            {},
            data,
        );
      } else {
        res = await brandlabsAuth.fetchData(
            apiUrl.sponsoredPostApi,
            'post',
            {},
            data,
        );
      }
      return res.data;
    } catch (err) {
      if (err.response) {
        errorHandling.handleErrors(err.response.data);
        return;
      } else {
        errorHandling.handleErrors('Server error');
        return;
      }
    }
  },

  // get all posts
  getSponsoredPosts: async () => {
    const response = await brandlabsAuth.fetchData(
        apiUrl.sponsoredPostApi + 's',
        'get',
    );
    return response;
  },

  // get post detail
  getPostById: async (id) => {
    try {
      const res = await brandlabsAuth.fetchData(
          apiUrl.sponsoredPostApi + '?id=' + id,
          'get',
      );
      return await res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  // delete post
  deletePostById: async (id) => {
    try {
      const res = await brandlabsAuth.fetchData(
          apiUrl.sponsoredPostApi + '?id=' + id,
          'delete',
      );
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  // File details by post id
  getFileByPostId: async (id) => {
    try {
      const res = await brandlabsAuth.fetchData(
          apiUrl.sponsoredPostApi + '?id=' + id,
          'get',
      );
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  // pageDelete: async (id) => {
  //   try {
  //     const res = await brandlabsAuth.fetchData(
  //       brandlabapi.pageDelete + id,
  //       'delete',
  //     );
  //     return res.data;
  //   } catch (err) {
  //     errorHandling.handleErrors(err.response.data);
  //     return;
  //   }
  // },


  recoverCoupon: async (data) => {
    try {
      const response = await brandlabsAuth.fetchData(
          brandlabapi.recoverCoupon,
          'patch',
          '',
          data,
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },

  applyCoupon: async (data) => {
    try {
      const response = await brandlabsAuth.fetchData(
          brandlabapi.applyCoupon,
          'post',
          '',
          data,
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
  },
};
