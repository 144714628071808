import errorHandling from '../errorhandling/ErrorHandle';
import {brandlabsAuth} from './BrandlabsAuth';

export default {
  postOnBoardingDetails: async (data) => {
    let res = {};
    try {
      res = await brandlabsAuth.fetchData(
          process.env.REACT_APP_Btpbrandlabs_Api + 'create-onboarding',
          'post',
          '',
          data,
      );
      return res.data;
    } catch (err) {
      if (err.response) {
        errorHandling.handleErrors(err.response.data);
        return;
      } else {
        errorHandling.handleErrors('Server error');
        return;
      }
    }
  },
};
