import React from 'react';
import {useEffect, useState} from 'react';
import AppContext from '../dashboard/dashboardPages/createpages/Context';
import BusinessDetails from './BusinessDetails';
import BusinessType from './BusinessType';
import Logo from '../assets/img/brandlabs-logo.png';
import {useNavigate} from 'react-router-dom';
import {Col, Row, ListGroup, Card} from 'react-bootstrap';
import ProgresOnboarding from './ProgresOnboarding';
import axios from 'axios';
import OnBoardingApi from '../services/OnBoardingApi';
import ProfileApi from '../services/ProfileApi';
import {BsFillArrowLeftCircleFill} from 'react-icons/bs';
import {logout} from '../auth/auth';
// BsFillArrowLeftCircleFill

const Onboarding = (prop) => {
  const [formData, setFormData] = useState({
    pan_card_image: undefined,
  });
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [file, setFile] = React.useState('');
  const [gstData, setGstData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [panFile, setPanFile] = useState('');
  const [name, setFullname] = useState();

  const getGst = () => {
    const data = JSON.stringify({
      gstin: formData.gst_number,
    });
    const config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://gst-return-status.p.rapidapi.com/free/gstin/${formData.gst_number}`,
      headers: {
        'Content-Type': 'application/json',
        'X-RapidAPI-Key': process.env.REACT_APP_X_RapidAPI_Key,
        'x-rapidapi-host': process.env.REACT_APP_X_Rapidapi_Host,
        Connection:'keep-alive',
      },
      data: data,
    };
    axios(config)
        .then(function(response) {
          setGstData(response.data.data);
        }).catch(function(error) {
          console.log(error);
        });
  };

  const submitData = async () => {
    const fileData = new FormData();
    let data = formData;
    if (formData.gst_number != undefined) {
      fileData.append('File', file);
      formData.gst_number = gstData.gstin;
      formData.business_name = gstData.tradeName;
      formData.business_address = gstData.adr;
      formData.business_area = gstData.stj;
      formData.business_pincode = gstData.pincode;
      formData.pan_card_image = file;
      formData.pan_card_number = gstData.pan;
    } else {
      data = {
        business_address: formData.business_address,
        business_area: formData.business_area,
        business_city: formData.business_city,
        business_country: formData.business_country,
        business_name: formData.business_name,
        business_phone_number: formData.business_phone_number,
        business_pincode: formData.business_pincode,
        pan_card_image: panFile,
        pan_card_number: formData.pan_card_number,
      };
    }
    const res = await OnBoardingApi.postOnBoardingDetails(data);
    console.log(res,"wdfew");
    if (res.message == 'success') {
      navigate('/dashboard/sponsored-posts');
    } else if (res.message !== 'success') {
      navigate('/on-boarding');
    }
  };

  const [listValue, setListValue] = useState();
  const listClick = (e) => {
    setListValue(e.target.value);
    if (e.target.value == 'Business Type') {
      setStep(1);
    } else if (e.target.value == 'Business Detail') {
      if (
        formData.card_number == '' ||
        formData.pan_card_image == undefined ||
        ('' && formData.gst_number == '')
      ) {
        setStep(1);
      } else {
        setStep(2);
      }
    } else if (e.target.value == 'Billing Details') {
      setStep(3);
    }
    if (e.target.value == 'Abroad') {
      setStep(1);
    }
  };

  useEffect(() => {
    prop.setShow(false);
  }, []);

  const userDetails = {
    currentPage: step,
    setStep,
  };

  const homePage = () => {
    navigate('/dashboard');
  };

  const getUserDeatils =async () => {
    await ProfileApi.getuserDetail()
        .then((resn) => {
          sessionStorage.setItem('full_name', resn.data.data[0].full_name);
          localStorage.setItem('full_name', resn.data.data[0].full_name);
          setFullname(resn.data.data[0].full_name);
        })
        .catch((error) => {
          console.log(error);
        });
  };


  useEffect(() => {
    getUserDeatils();
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('username') == null) {
      navigate('/');
      window.location.reload();
    }
  }, []);
  return (
    <div className='on-boarding-b'>
      <div className='on-boarding-b-left'>
        <div className='onBoardingCard'>
          <div>
            <Card.Img
              src={Logo}
              className='headerLogoDashboard'
              onClick={homePage}
            />
          </div>
          <div className='on-boarding-sidebar'>
            <h3>Hi, {name}</h3>
            <p>
              Tell us a bit about your business so that we can help you create a
              business account.
            </p>
          </div>
          <Row className='sideBarRow'>
            <Col>
              <ListGroup className='pointer-none'>
                {['Business Type', 'Business Detail'].map((value, index) => (
                  <option
                    className={
                      listValue == value?
                      'activeList settingListItem px-3 py-2 border-bottom':
                      ' cursor settingListItem px-3 py-2 border-bottom'
                    }
                    value={value}
                    key={index}
                    onClick={listClick}
                  >
                    {value}
                  </option>
                ))}
              </ListGroup>
            </Col>
          </Row>
          <div className='ft-left'>
            <h5 onClick={() => logout()}>
              <BsFillArrowLeftCircleFill
                style={{fontSize:'27px', marginLeft:'30px'}}/> &nbsp;
              Exit
            </h5>
          </div>
        </div>
      </div>

      <div className='on-boarding-b-right'>
        <AppContext.Provider
          value={{
            userDetails,
            formData,
            setFormData,
            submitData,
            file,
            setFile,
            gstData,
            getGst,
            validated,
            setValidated,
            panFile,
            setPanFile,
            setGstData,
          }}
        >
          {/* {step === 3 && <BillingDetails />} */}
          {step === 2 && <BusinessDetails />}
          {step === 1 && <BusinessType />}
          <ProgresOnboarding />
        </AppContext.Provider>
      </div>
    </div>
  );
};

export default Onboarding;
