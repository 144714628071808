import React, {useState} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import ProfileApi from '../../services/ProfileApi';
import Swal from 'sweetalert2';

const ForgetPassword = (prop) => {
  const [formData, setFormData] = useState({});

  const handleReset = async (e) => {
    e.preventDefault();
    const data = `{\n    "email" : "${formData.email}",\n    "reset_link" : "https://www.beingtheparent.com/reset-password/"\n}`;
    await ProfileApi.forgetPassword(data)
        .then((response) => {
          if (response.data.status==true) {
            prop.setShowResetPassword(true);
            prop.setShowForegt(false);
          } else if (response.data.status==false) {
            Swal.fire({
              icon: 'error',
              text:
                response.data.message,
              timer: 1000,
              showConfirmButton: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value});
  };

  return (
    <Modal
      backdrop='static'
      show={prop.showForget}
      onHide={() => {
        prop.setShowForegt(false);
      }}
    >
      <Modal.Header closeButton className='border-0'></Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleReset}>
          <Form.Group className='mb-4' controlId='formBasicEmail'>
            <Form.Label>Forgot Password</Form.Label>
            <p className='congoP'>
              Please put the email below to reset the link
            </p>
            <Form.Control
              type='email'
              placeholder='Enter email'
              name='email'
              id='email'
              required
              onChange={handleChange}
              value={formData.email}
            />
          </Form.Group>
          <Button
            variant='danger'
            type='submit'
            className='col-12 customPopupBtn'
          >
            Submit
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer className='border-0'></Modal.Footer>
    </Modal>
  );
};

export default ForgetPassword;
