import React from 'react';
import {useEffect, useState} from 'react';
import Logo from '../assets/img/brandlabs-logo.png';
import {useNavigate} from 'react-router-dom';
import {Col, Row, ListGroup, Card, Form} from 'react-bootstrap';
import ProfileApi from '../services/ProfileApi';
import OnBoardingApi from '../services/OnBoardingApi';
import {BsFillArrowLeftCircleFill} from 'react-icons/bs';
import {logout} from '../auth/auth';

const Abroad = (prop) => {
  const [formData, setFormData] = useState({});
  const [validated, setValidated] = useState(false);
  const [userId, setUserId] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    prop.setShow(false);
  }, []);

  const homePage = () => {
    navigate('/dashboard');
  };

  const [name, setFullname] = useState();
  const getUserDeatils = async () => {
    await ProfileApi.getuserDetail()
        .then((resn) => {
          setFullname(resn.data.data[0].full_name);
          setUserId(resn.data.data[0].sk_user_id);
        })
        .catch((error) => {
          console.log(error);
        });
  };

  useEffect(() => {
    getUserDeatils();
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem('username') == null) {
      navigate('/');
      window.location.reload();
    }
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == 'business_name') {
      setFormData({
        ...formData,
        ['business_name']: value.replace(/[^a-zA-Z\s]/g, ''),
      });
    } else if (name == 'business_phone_number') {
      setFormData({
        ...formData,
        ['business_phone_number']: value.replace(/\D/g, ''),
      });
    } else if (name == 'business_pincode') {
      setFormData({
        ...formData,
        ['business_pincode']: value.replace(/\D/g, ''),
      });
    } else if (name == 'business_city') {
      setFormData({
        ...formData,
        ['business_city']: value.replace(/[^a-zA-Z\s]/g, ''),
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        ['user_id']: userId,
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      event.preventDefault();
      await OnBoardingApi.postOnBoardingDetails(formData)
          .then(function(res) {
            if (res.message == 'success') {
              navigate('/dashboard/sponsored-posts');
            } else if (res.message !== 'success') {
              navigate('/abroad');
            }
          })
          .catch(function(error) {
            console.log(error);
          });
    }
    setValidated(true);
  };

  return (
    <>
      <div className='on-boarding-b'>
        <div className='on-boarding-b-left'>
          <div className='onBoardingCard'>
            <div>
              <Card.Img
                src={Logo}
                className='headerLogoDashboard'
                onClick={homePage}
              />
            </div>
            <div className='on-boarding-sidebar'>
              <h3>Hi, {name}</h3>
              <p>
                Tell us a bit about your business so that we can help you create
                a business account.
              </p>
            </div>
            <Row className='sideBarRow'>
              <Col>
                <ListGroup className='pointer-none'>
                  <option>Business Detail</option>
                </ListGroup>
              </Col>
            </Row>
            <div className='ft-left'>
              <h5 onClick={() => logout()}>
                <BsFillArrowLeftCircleFill
                  style={{fontSize: '27px', marginLeft: '30px'}} /> &nbsp;
                Exit
              </h5>
            </div>
          </div>
        </div>
        <div className='on-boarding-b-right'>
          <Form
            className='formWidth'
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <div className='abroad'>
              <div className='rightContainer'>
                <h3>Let’s fill your business details</h3>

                <Form.Group className='mb-3'>
                  <Form.Label className='labelHead'>
                    Business name and contact
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Business Name'
                    autoComplete='off'
                    name='business_name'
                    id='business_name'
                    value={formData.business_name}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please fill the business name.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <Form.Control
                    type='text'
                    placeholder='Business Phone No'
                    autoComplete='off'
                    name='business_phone_number'
                    id='business_phone_number'
                    value={formData.business_phone_number}
                    onChange={handleChange}
                    required
                  />

                  <Form.Control.Feedback type='invalid'>
                    Please fill the business phone number.
                  </Form.Control.Feedback>
                </Form.Group>
                <hr />
                <Form.Group className='mb-3' controlId='formBasicEmail'>
                  <Form.Label className='labelHead'>
                    Which city are you operating in?
                  </Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Business Address'
                    autoComplete='off'
                    name='business_address'
                    id='business_address'
                    value={formData.business_address}
                    onChange={handleChange}
                    required
                  />
                  <Form.Control.Feedback type='invalid'>
                    Please fill the business address.
                  </Form.Control.Feedback>
                </Form.Group>
                <Col>
                  <Row>
                    <Col>
                      <Form.Control
                        type='text'
                        className='form-control'
                        placeholder='Area'
                        id='business_area'
                        name='business_area'
                        value={formData.business_area}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type='invalid'>
                        Please fill the business area.
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Control
                        type='text'
                        className='form-control'
                        placeholder='City'
                        name='business_city'
                        id='business_city'
                        value={formData.business_city}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type='invalid'>
                        Please fill the business city.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  <Row className='mt-2'>
                    <Col>
                      <Form.Control
                        type='text'
                        className='form-control'
                        placeholder='Country'
                        name='business_country'
                        id='business_city'
                        value={formData.business_country}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type='invalid'>
                        Please fill the country.
                      </Form.Control.Feedback>
                    </Col>
                    <Col>
                      <Form.Control
                        type='text'
                        className='form-control'
                        placeholder='Pin Code'
                        name='business_pincode'
                        id='business_pincode'
                        value={formData.business_pincode}
                        onChange={handleChange}
                        required
                      />
                      <Form.Control.Feedback type='invalid'>
                        Please fill the pin code.
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </Col>
              
              </div>
              <div className='btn-nxt-bck-on-abroad'>
                <hr className='hr_abroad' />
                <p id='cntr'>1 Page</p>
                <button id='nxt' type='submit' className='formSubmit'>
                  Submit
                </button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Abroad;
