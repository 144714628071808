import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import {PayPalScriptProvider, PayPalButtons} from '@paypal/react-paypal-js';
import PaymentApi from '../services/PaymentApi';
import errorHandling from '../errorhandling/ErrorHandle';
import propTypes from 'prop-types';


// eslint-disable-next-line require-jsdoc
export default function PayPal(props) {
  PayPal.propTypes = {
    show: propTypes.bool,
    handleClose: propTypes.any,
    postId: propTypes.any,
    cause: propTypes.any,
    couponCode: propTypes.any,
    getProductDetails: propTypes.func,
  };

  // creates a paypal order
  const createOrder = async () => {
    const orderData = await PaymentApi.paypalOrder(props.postId,
        props.cause, props.couponCode);
    try {
      if (orderData) {
        return orderData.data.order.id;
      }
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  };

  // check Approval
  const onApprove = async () => {
    const statusData = await PaymentApi.paypalPayment(props.postId,
        props.couponCode, props.cause);
    if (statusData) {
      props.handleClose();
      props.getProductDetails();
    }
  };

  const cancelPayment = () => {
  };

  const style = {layout: 'vertical', color: 'blue'};
  return (
    <Modal size="lg" show={props.show}
      onHide={props.handleClose} backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Payment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PayPalScriptProvider options={
          {'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID}}>
          {props.show ? (
            <PayPalButtons
              style={style}
              createOrder={createOrder}
              onApprove={onApprove}
              onCancel={cancelPayment}
            />
          ) : null}
        </PayPalScriptProvider>

      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
