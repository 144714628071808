import Swal from 'sweetalert2';
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-danger swal-button',
    cancelButton: 'btn btn-secondary pr-2',
  },
  buttonsStyling: false,
});

// eslint-disable-next-line require-jsdoc
async function deleteMessage(deleteParams) {
  const data = await swalWithBootstrapButtons
      .fire({
        title: deleteParams.title ? deleteParams.title: 'Are you sure?',
        text: deleteParams.text ? deleteParams.text:
        'You want to delete this post?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      });
  return data;
}
export default deleteMessage;
