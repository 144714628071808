import React from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import Settingr from '../../dashboard/assets/svg/Settingright.svg';

const PasswordResetDone = (prop) => {
  const handleReset = () => {
    prop.setShowResetPswDone(false);
  };
  return (
    <div>
      <Modal
        backdrop='static'
        show={prop.showResetPswDone}
        onHide={() => {
          prop.setShowResetPswDone(false);
        }}
      >
        <Modal.Header closeButton className='border-0 '>
          <Modal.Title>
            {' '}
            <img src={Settingr} alt='' />{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modalBody'>
          <h3>Password Reset Done!</h3>
          <p>Proceed with the login here</p>
          <Form>
            <Button
              variant='danger'
              className='col-12 customPopupBtn'
              onClick={handleReset}
            >
              Login
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PasswordResetDone;
