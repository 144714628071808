import React, {useState} from 'react';
import Card from 'react-bootstrap/Card';
import Traget from '../../assets/svg/audience.svg';
import Sound from '../../assets/svg/sound.svg';
import Insight from '../../assets/svg/Insight.svg';
import Withbaby from '../../assets/svg/withbaby.svg';
import Parents from '../../assets/svg/parentswithchild.svg';
import LeftButton from '../../assets/svg/LeftButton.svg';
import Targetaudience from '../../assets/svg/Targetaudience.svg';
import Ads from '../../assets/svg/Ads.svg';
import Investment from '../../assets/svg/Investment.svg';
import Measure from '../../assets/svg/Measure.svg';
import SolutionSide from '../../assets/img/solside.png';
import Signup from '../../dashboard/Signup';
import PasswordResetDone from '../Login/PasswordResetDone';
import Otp from '../../dashboard/Otp';
import ForgetPassword from '../Login/ForgetPassword';
import ResetPassword from '../Login/ResetPassword';
import VerifyPhone from '../../dashboard/VerifyPhone';
import Phone from '../../dashboard/Phone';
import Congratulations from '../../dashboard/Congratulations';
import Login from '../../dashboard/Login';


const Solutions = () => {
  const [showLogin, setShowLogin] = useState(false);
  const [phoneOtpShow, setPhoneOtpShow] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [otpShow, setOtpShow] = useState(false);
  const [showForget, setShowForegt] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showSigunUp, setShowSignUp] = useState(false);
  const [showResetPswDone, setShowResetPswDone] = useState(false);
  const [congratulationsModal, setCongratulationsModal] = useState(false);

  const handleSingUp = ()=>{
    setShowSignUp(true);
  }



  return (
    <>
      {showLogin && (
        <Login
          showLogin={showLogin}
          setShowLogin={setShowLogin}
          setShowSignUp={setShowSignUp}
          setShowForegt={setShowForegt}
          setShowPhone={setShowPhone}
          setOtpShow={setOtpShow}
        />
      )}
      {showResetPswDone && (
        <PasswordResetDone
          setShowResetPswDone={setShowResetPswDone}
          showResetPswDone={showResetPswDone}
        />
      )}
      {showSigunUp && (
        <Signup
          showSigunUp={showSigunUp}
          setShowSignUp={setShowSignUp}
          setOtpShow={setOtpShow}
          setShowLogin={setShowLogin}
        />
      )}
      {otpShow && (
        <Otp
          setOtpShow={setOtpShow}
          otpShow={otpShow}
          setCongratulationsModal={setCongratulationsModal}
          setShowPhone={setShowPhone}
        />
      )}

      {showForget && (
        <ForgetPassword
          showForget={showForget}
          setShowForegt={setShowForegt}
          setShowResetPassword={setShowResetPassword}
        />
      )}

      {showResetPassword && (
        <ResetPassword
          setShowResetPassword={setShowResetPassword}
          showResetPassword={showResetPassword}
          setShowResetPswDone={setShowResetPswDone}
        />
      )}
      {phoneOtpShow && (
        <VerifyPhone
          phoneOtpShow={phoneOtpShow}
          setPhoneOtpShow={setPhoneOtpShow}
        />
      )}
      {showPhone && (
        <Phone
          showPhone={showPhone}
          setShowPhone={setShowPhone}
          setPhoneOtpShow={setPhoneOtpShow}
        />
      )}

      {congratulationsModal && (
        <Congratulations
          setCongratulationsModal={setCongratulationsModal}
          congratulationsModal={congratulationsModal}
          setShowPhone={setShowPhone}
        />
      )}
    <div className='solution-page-conatiner' id='top-gap'>
      <div className='solution-full-width'>
        <div className='container'>
          <div className='solution-left-width'>
            <div className='solution-first-left-div'>
              <p>
                <span>Moms</span> come to us for <span> answers</span>,
                <div id='gap'>
                  <span> Brands</span> comes to us for <span>results</span>.
                </div>
              </p>
                <button onClick={handleSingUp}>Get Started</button>
            </div>
          </div>
          <div className='solution-right-width'>
            <div className='solution-right-image'>
              <img src={SolutionSide} alt='' />
            </div>
          </div>
        </div>
      </div>
      <section className='solution-second-section'>
        <div className='container'>
          <div className='row'>
            <div className='second-inner-div'>
              <h1>Introducing BrandLabs</h1>
              <p id='p-comp'>Your comprehensive Advertising Solutions</p>
              <div className='introducing-card col-12'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div>
                      <Card
                        border='secondary'
                        style={{width: '20rem'}}
                        className='border-0 solution-card'
                      >
                        <img src={Traget} alt='' />
                        <Card.Body>
                          <h3>Niche Audience Reach</h3>
                          <p>
                            24/7 technology based upgrades for your campaign to
                            reach a niche audience.
                          </p>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div>
                      <Card
                        border='secondary'
                        style={{width: '20rem'}}
                        className='border-0 solution-card'
                      >
                        <img src={Sound} alt='' />
                        <Card.Body>
                          <h3>Ad Tech</h3>
                          <p>
                            Artificial Intelligence tool to helps you design,
                            run and boost ad campaigns with optimum results.
                          </p>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                  <div className='col-sm-4'>
                    <div>
                      <Card
                        border='secondary'
                        style={{width: '20rem'}}
                        className='border-0 solution-card'
                      >
                        <img src={Insight} alt='' />
                        <Card.Body>
                          <h3>Real-time Insights </h3>
                          <p>
                            Measure and learn from results to drive growth for
                            your brand and products.
                          </p>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='solution-fifth-section'>
        <div className='container'>
          <div className='row'>
            <div className='sol-fif-card'>
              <div className='solution-inner-main-div'>
                <div className='left-solution-fifth-section'>
                  <h1>
                    Guest<sapn className='inner-mess'> Posts</sapn>
                  </h1>
                  <p>
                    Guest posts are an excellent way to gain publicity for your
                    business. Through this form of content marketing,
                    you&apos;ll establish yourself as a credible expert in your
                    industry and gain valuable brand exposure to the publication
                    &apos;s audience.
                  </p>
                </div>
                <div className='right-solution-fifth-section'>
                  <img src={Withbaby} alt='' />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='solution-six-section'>
        <div className='container'>
          <div className='row'>
            <div className='inner-main-six-div'>
              <h1 className='text-center'>
                <span className='inner-mess'> 5 simple steps</span>
                <br />
                To get you started
              </h1>
              <div className='introducing-card col-12'>
                <div className='row'>
                  <div className='simple-step-outer'>
                    <div className='inner-simple-step'>
                      <Card border='secondary' className='border-0 simple-step'>
                        <img src={LeftButton} alt='' />
                        <Card.Body>
                          <p>Choose your objective</p>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className='inner-simple-step'>
                      <Card border='secondary' className='border-0 simple-step'>
                        <img src={Targetaudience} alt='' />
                        <Card.Body>
                          <p>Define your target audience</p>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className='inner-simple-step'>
                      <Card border='secondary' className='border-0 simple-step'>
                        <img src={Ads} alt='' />
                        <Card.Body>
                          <p>Choose your ad format</p>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className='inner-simple-step'>
                      <Card border='secondary' className='border-0 simple-step'>
                        <img src={Investment} alt='' />
                        <Card.Body>
                          <p>Set your budget for ad</p>
                        </Card.Body>
                      </Card>
                    </div>

                    <div className='inner-simple-step'>
                      <Card border='secondary' className='border-0 simple-step'>
                        <img src={Measure} alt='' />
                        <Card.Body>
                          <p>Measure at will & optimise</p>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </section>

      <section className='solution-section-eight'>
        <div className='container'>
          <div className='row'>
            <div className='solution-section-inner-main-div'>
              <div className='left-section-solution-eight-div'>
                <p>Your Brand is your Baby. Trust it to Being the Parent</p>
                  <button onClick={handleSingUp}>Get Started</button>
              </div>
              <div className='right-section-solution-eight-div'>
                <img src={Parents} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
};

export default Solutions;
