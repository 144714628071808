import React, {Fragment} from 'react';
import {Badge, Card} from 'react-bootstrap';
import propTypes from 'prop-types';

const Attachments = (props) => {
  Attachments.propTypes = {
    fileDetails: propTypes.any,
  };
  return (
    <Fragment>
      <Card>
        <Card.Header>Attachments
        </Card.Header>
        <Card.Body>
          {props.fileDetails.map((fileDetail, i) => {
            return <div key={i+1} className="d-flex mb-2">
              <div>
                <img alt="" className="attachment"
                  src={process.env.REACT_APP_Media_Api +
                  'api/media/v3/no-auth?key=' +
                    fileDetail.attachment_url} />
              </div>
              <div className="file-details p-2">
                <div>{fileDetail.fileName}</div>
                <div className="small">
                  Size: {fileDetail.size} KB.
                  Type: {fileDetail.fileType}.{' '}
                  <Badge bg="warning">
                    {
                      fileDetail.imageFeature ?
                        fileDetail.imageFeature.charAt(0).toUpperCase() +
                        fileDetail.imageFeature.slice(1) :
                          fileDetail.imageFeature
                    }
                  </Badge>
                </div>
              </div>
            </div>;
          })}
        </Card.Body>
      </Card>
    </Fragment>

  );
};

export default Attachments;
