/* eslint-disable max-len */
/* eslint-disable require-jsdoc */
import React, {useEffect, useState} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import propTypes from 'prop-types';
import moment from 'moment';
import TicketsApi from '../../services/TicketsApi';
import './style.css';
import errorHandling from '../../errorhandling/ErrorHandle';
import Spinner from '../../services/Spinner';

export default function Messages(props) {
  Messages.propTypes = {
    show: propTypes.bool,
    handleClose: propTypes.any,
    ticketId: propTypes.any,
    userDetails: propTypes.any,
    ticketData: propTypes.any,
  };
  const [message, setMessage] = useState('');
  const [ticket, setTicket] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const sendMessage = async (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    try {
      await TicketsApi.postMessage(ticket, message,
          props.userDetails,
      );
      await getTicket(props.ticketId);
      setMessage('');
    } catch (err) {
      errorHandling.handleErrors(err);
    }
    setIsButtonDisabled(false);
  };

  const getTicket = async (ticketId) => {
    try {
      const ticketData = await TicketsApi.getTicketById(ticketId);
      if (ticketData && ticketData.data) {
        setTicket(ticketData.data);
        setIsLoading(false);
      }
    } catch (err) {
      errorHandling.handleErrors(err);
    }
  };
  useEffect(() => {
    if (props.show === true) {
      getTicket(props.ticketId);
    }
  }, [props.show]);
  return (
    <Modal size="lg" show={props.show}
      onHide={props.handleClose} backdrop="static"
      keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Messages of {ticket.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          {isLoading === true ?
            <Spinner /> :
          <div className="row">
            {Object.keys(ticket).length > 0 && ticket.messages && ticket.messages.length > 0 ? ticket.messages.map((message, i) => {
              return (<div key={i+1} className="col-md-12">
                <div className="card p-3 mb-2">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      <div className="icon">{message.userName ? (message.userName).slice(0, 2): null}</div>
                      <div className="ms-2 c-details">
                        <h6 className="mb-0">{message.userName}</h6> <span>{moment(message.created_at).format('DD/MM/YYYY hh:mm A')}</span>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3"> {message.message} </div>
                </div>
              </div>
              );
            }): null
            }
          </div>
          }
          <Form method="post" onSubmit={(e) => e.preventDefault()}>
            <Form.Group className="mb-3" controlId="message">
              <div className="input-group mb-2">
                <Form.Control
                  name="message"
                  onChange={handleChange}
                  value={message}
                  type="text"
                  placeholder="Type Message"
                />
                <div className="input-group-text"><Button size="sm"
                  variant="primary" disabled={isButtonDisabled} onClick={sendMessage}>Send</Button></div>
              </div>
            </Form.Group>
          </Form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="secondary" onClick={props.handleClose}>
            Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
