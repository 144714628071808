import errorHandling from '../errorhandling/ErrorHandle';
import {brandlabsAuth} from './BrandlabsAuth';
import apiUrl from '../utils/brandlabapi';

export default {

  // get transaction detail
  getTransactionById: async (id) => {
    try {
      const res = await brandlabsAuth.fetchData(
          apiUrl.transactionApi + 'productId=' + id,
          'get',
      );
      return await res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },
};
