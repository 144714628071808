/* eslint-disable react/no-unknown-property */
import React, {Fragment, useEffect, useState} from 'react';
import {Card, Table} from 'react-bootstrap';
import './style.css';
import propTypes from 'prop-types';
import moment from 'moment';

let noOfWeeks = 1;
const EmailAddPrice = (props) => {
  EmailAddPrice.propTypes = {
    stageCount: propTypes.any,
    emailAdd: propTypes.any,
    getTotalAmount: propTypes.any,
    userData: propTypes.any,
  };
  const startDate = moment(props.emailAdd.dateRange[0].startDate);
  const endDate = moment(props.emailAdd.dateRange[0].endDate);
  const noOfDays = endDate.diff(startDate, 'days');
  noOfWeeks = (noOfDays/7);
  const [price, setPrice] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const price = props.stageCount *
    0.5 *
    Math.round(noOfWeeks);
    setPrice(price);
    if (props.userData.c_code === 'IN') {
      setTotalAmount(price * 1.18);
    } else {
      setTotalAmount(price);
    }
    props.getTotalAmount(totalAmount);
  }, [props.stageCount, props.emailAdd.dateRange, price]);


  return (
    <Fragment>
      <Card>
        <Card.Header>
        Price Details
        </Card.Header>
        <Card.Body>
          <Table className="striped bordered hover">
            <tbody>
              <tr><td>Total Count</td><td>
                {props.stageCount.toLocaleString()}</td></tr>
              <tr><td>Price</td><td>
                {props.stageCount.toLocaleString() + ' * 0.5 * ' +
                  Math.round(noOfWeeks) + ' = '
                }{'₹'}{price.toLocaleString()}
              </td></tr>
              {props.userData.c_code === 'IN' ?
                <tr><td>GST</td><td>
                  18%
                </td></tr>: null
              }
              <tr><td>Total Amount</td><td>
                {'₹'}{totalAmount.toLocaleString()}</td></tr>
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default EmailAddPrice;
