
import React from 'react';
const Spinner = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="spinner" role="status">
        <span className="sr-only"> <img className="spinner-image"
          src ={process.env.PUBLIC_URL +
          '/images/btp-spinner.svg'} alt="image"/>
        </span>
      </div>
    </div>
  );
};
export default Spinner;
