import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const errorHandling = {
  handleErrors: async function (response, data) {
    console.log(response);
    if (response.status === "401" || response.status === "403") {
      await toast.error(response.message);
      window.location = "/";
    } else if (data !== "IN" || "") {
      window.location = "/abroad";
    } else if (data == "IN") {
      window.location = "/on-boarding";
    } else if (
      response === "User is not not onboarded" ||
      response.errorMsg === "User is not not onboarded"
    ) {
      window.location = "/on-boarding";
    } else {
      if (response.message) {
        await toast.error(response.message);
      } else if (response.msg) {
        await toast.error(response.msg);
      } else {
        await toast.error(response);
        return;
      }
    }
    return true;
  },
};

export default errorHandling;
