import React, {useEffect, useState, useContext, useCallback} from 'react';
import {Form, Card} from 'react-bootstrap';
import AppContext from './Context';
import {useDropzone} from 'react-dropzone';
import Drag from '../../../dashboard/assets/svg/Drag.svg';
import {FaPlus} from 'react-icons/fa';
import FileUploadApi from '../../../services/FileUploadApi';
import brandlabapi from '../../../utils/brandlabapi';
import errorHandling from '../../../errorhandling/ErrorHandle';
import MyPagesApi from '../MyPagesApi';
// import deleteMessage from '../../../services/DeleteMessage';
// import Swal from 'sweetalert2';
// import {MdOutlineDeleteOutline} from 'react-icons/md';
import {useLocation} from 'react-router-dom';
import {useNavigate} from 'react-router-dom';

const CreatePage3 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {brandData, setBrandData, setBrandMediaFile, submitBrandPage,
    addSection, brandMediaFile} =
    useContext(AppContext);
  const myContext = React.useContext(AppContext);
  const updateContext = myContext.userDetails;
  const [validated, setValidated] = useState(false);
  const [previewFile, setPreviewFile] = useState();
  // const [arrayData, setArrayData] = useState([]);
  const onDrop = useCallback(async (acceptedFiles) => {
    const fileData = await FileUploadApi.uploadFile(
        'brandlabs',
        'brandlabs',
        acceptedFiles[0],
    );
    try {
      if (fileData.data) {
        setBrandMediaFile(fileData.data.key);
        setPreviewFile(fileData.data.key);
      }
    } catch (err) {
      errorHandling.handleErrors(err);
    }
  });
  const {getRootProps, getInputProps, isDragActive} = useDropzone({
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.jpg'],
    },
    onDrop
  });
  const [inputList, setinputList] = useState([
    {section_media: '', section_title: '', section_description: ''},
  ]);
  const page = sessionStorage.getItem('page_id');
  const next = (e) => {
    console.log(e);
    e.preventDefault();
    if (location.state == null) {
      const form = e.currentTarget;
      if (form.checkValidity() === false &&
        brandData.section_description == undefined ||
        brandData.section_title == undefined || brandMediaFile == undefined) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        submitBrandPage();
        updateContext.setStep(updateContext.currentPage + 1);
        console.log('submit');
      }
      setValidated(true);
    } else if (location.state != null && location.state.key == 'addsection') {
      const form = e.currentTarget;
      if (form.checkValidity() === false &&
        brandData.section_description == undefined ||
        brandData.section_title == undefined || brandMediaFile == undefined) {
        e.preventDefault();
        e.stopPropagation();
      } else {
        addSection();
        navigate('/dashboard/my-pages', {state: location});
        console.log('add section');
      }
      setValidated(true);
    }
  };



  const back = () => {
    updateContext.setStep(updateContext.currentPage - 1);
  };

  const getSecData = async () => {
    const res = await MyPagesApi.getSectionData();
    // setArrayData(res.data.data);
  };

  useEffect(() => {
    getSecData();
  }, []);

  // const handleDelete = async (e, val) => {
  //   const deleteParams = {
  //     title: 'Are you sure?',
  //     text: 'You want to delete this section?',
  //   };
  //   const deleteData = await deleteMessage(deleteParams);
  //   if (deleteData.isConfirmed) {
  //     try {
  //       const res = await MyPagesApi.sectionDelete(val._id);
  //       if (res.status == 200) {
  //         getSecData();
  //         Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   }
  // };


  const handleChange = (i, e) => {
    setBrandData({...brandData, [e.target.name]: e.target.value});
  };

  const handleaddclick = () => {
    setBrandData({});
    if (
      (brandData.section_description != undefined ||
        brandData.section_title != undefined || brandMediaFile == undefined)
    ) {
      console.log('not enter');
      submitBrandPage();
      getSecData();
      // setPreviewFile('');
      setinputList([
        ...inputList,
        {section_media: '', section_title: '', section_description: ''},
      ]);
      // setBrandData(...inputList);
      // setBrandData({...brandData});
    }
  };

  return (
    <div className='create-page-onboarding'>
      <div className='contain'>
        <Form className='form' noValidate validated={validated} onSubmit={next}>
          <div className='create-page-div'>
            <a href='/dashboard/my-pages' id='bck-to-hm'>
              Exit
            </a>
            <h1>Finally, create the brand page</h1>
            <div className='fourth-page-product'>
              {
                location.state == null &&
                <text onClick={handleaddclick}>
                  <FaPlus />
                  &#160; Add Section
                </text>}
            </div>
          </div>

          <section className='create-page-section'>
            {/* <Form controlId='form'> */}
            {inputList.map((x, i) => {
              return (
                <div className='all_section' key={i}>
                  <div >
                    <div className='cp-section-div1' id={`create_sec-${i}`}>
                      <div className='section-btn-add'>
                        <label>Section {i + 1}</label>
                      </div>
                      <Form.Group className='mar'>
                        <Form.Label>Media</Form.Label>
                        <div {...getRootProps()} className='drag-drop' >
                          <input
                            {...getInputProps()}
                            name='section_media'
                            value={brandData.section_media}
                            accept="image/jpeg,image/png,image/jpg"
                            required
                          />
                          <Form.Control.Feedback type="invalid">
                            Please choose media pic.
                          </Form.Control.Feedback>
                          {isDragActive ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <div>
                              <img src={Drag} alt='' className='darg-icon' />
                              <p>
                                Drag and drop an image, or{' '}
                                <span className='drag-browser'> Browse </span>
                              </p>
                            </div>
                          )}
                        </div>
                        <Form.Text className='text-muted'>
                          960x300 or higher recommended. Max 08 MB size
                        </Form.Text>

                      </Form.Group>
                      <Form.Group className='mar'>
                        <Form.Label>Title</Form.Label>
                        <Form.Control
                          type='text'
                          placeholder='Enter a title'
                          name='section_title'
                          value={brandData.section_title}
                          onChange={(e) => handleChange(i, e)}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a title
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group
                        className='mb-3'
                      >
                        <Form.Label>Description</Form.Label>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          placeholder='Enter description'
                          name='section_description'
                          value={brandData.section_description}
                          onChange={(e) => handleChange(i, e)}
                          required
                          style={{marginBottom: '200px'}}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please enter a description
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>

                  {/* </Form> */}
                  <div>
                    <label>Preview Page</label>

                    <div>
                      <Card className='cp-card preview-page'>
                        <Card.Body>
                          <Card.Title>
                            <img
                              src={
                                brandlabapi.mediaURL +
                                'api/media/v3/no-auth?key=' +
                                previewFile
                              }
                              alt=''
                              className='right-header-img'
                            />
                          </Card.Title>
                          <div className='cp-third-page-head'>
                            {brandData.section_title == undefined ? (
                          <label htmlFor=''>Create a eye catching Title!</label>
                            ) : (
                          <label htmlFor=''>{brandData.section_title}</label>
                            )}
                            {brandData.section_description == undefined ? (
                              <p>Type impressive description of your product</p>
                            ) : (
                              <p>{brandData.section_description}</p>
                            )}
                          </div>
                        </Card.Body>
                      </Card>

                      {/* {arrayData?.map((val) => {
                  return (
                    <>
                      {(page == val.pageId && location.state == null) (
                        <Card className='preview-page cp-card dynamic-card_sec'>
                          <Card.Body>
                            <MdOutlineDeleteOutline
                              id='delete'
                              name='delete'
                              onClick={(e) => handleDelete(e, val)}
                            />
                            <Card.Title>
                              <img
                                src={
                                  brandlabapi.mediaURL +
                                  'api/media/v3/no-auth?key=' +
                                  val.section_media
                                }
                                alt=''
                                className='right-header-img'
                              />
                            </Card.Title>
                            <div className='cp-third-page-head'>
                              <label htmlFor=''>{val.section_title}</label>
                              <p>{val.section_description}</p>
                            </div>
                          </Card.Body>
                        </Card>
                      )}
                    </>
                  );
                })} */}
                    </div>
                  </div>
                </div>);
            })}


          </section>

          <div className='btn-nxt-bck'>
            {/* <button id='bck' onClick={back}>Back</button> */}
            {location.state == null ? <p id='cntr'>Step 3 of 4</p> :
              <p id='cntr'>Step 3 of 3</p>}

            {
              (location.state == null) ?< button
                id='nxt'
                type='submit'
                className='formSubmit'
              >
            Next
              </button> : < button
                id='nxt'
                type='submit'
                className='formSubmit'>
            Add Section
              </button>
            }
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreatePage3;

