import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import OtpInput from "react18-input-otp";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router-dom";
import ProfileApi from "../services/ProfileApi";
import MyPagesApi from "../dashboard/dashboardPages/MyPagesApi";
// import MyPagesApi from "../dashboard/dashboardPages/MyPagesApi";
import { useQuery, useQueryClient } from "react-query";

const UserVerify = (prop) => {
  const navigate = useNavigate();
  const [emailForm, setEmailForm] = useState({});
  const [emailOtpData, setEmailOtpData] = useState({});
  const [formData, setFormData] = useState({});
  const [phoneOtpForm, setPhoneOtpForm] = useState({});
  const [iso, setIso] = useState();
  const [number, setNumber] = useState();
  const [OTP, setOTP] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");
  const [emailStatus, setEmailStatus] = useState();
  const [phoneStatus, setPhoneStatus] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [sendOtpPhoneDisabled, setSendOtpPhoneDisabled] = useState(false);
  const [messageError, setMessageError] = useState();

  const userDataDetails = useQuery("userDetails", ProfileApi.getuserDetail, {
    enabled: true,
  });
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
  }
  const username = sessionStorage.getItem("username");
  useEffect(() => {
    prop.setShow(false);
  }, []);
  const [userId, setUserId] = useState();

  const getUserDeatils = () => {
    ProfileApi.getuserDetail()
      .then((resn) => {
        // console.log(resn);
        sessionStorage.setItem("full_name", resn.data.data[0]?.full_name);
        setEmailStatus(resn.data.data[0]?.emil_verification_status);
        setPhoneStatus(resn.data.data[0]?.mobile_verification_status);
        setUserId(resn.data.data[0]?.sk_user_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getUserDeatils();
  }, []);

  useEffect(() => {
    if (
      userData?.emil_verification_status == "1" ||
      userData?.mobile_verification_status == "1"
    ) {
      navigate("/dashboard/sponsored-posts");
    }
    // if(sessionStorage.getItem("accessToken") == "" || null ){
    //   navigate("/")
    // }
    // if(localStorage.getItem("")=="" || null){
    //   navigate("/")
    // }
  });

  useEffect(() => {
    if (sessionStorage.getItem('username') == null) {
      navigate('/');
    }
  }, []);

  window.addEventListener('storage', (event) => {
    if (event.key == 'logout' && event.newValue) {
      sessionStorage.removeItem('logged-in');
      localStorage.removeItem('logout');
      window.location.href = '/login';
    }
  });

  const handleChange = (e) => {
    setEmailForm({ ...emailForm, [e.target.name]: e.target.value });
  };

  const handleUserVerify = async (e) => {
    e.preventDefault();
    const encodedemail = btoa(username);
    const data = {
      email: encodedemail,
    };
    ProfileApi.resendVerificationPin(data)
      .then(function (response) {
        if (response.data.status == true) {
          sessionStorage.setItem("emailForm", emailForm);
          setButtonDisabled(true);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function handleChangeOtp(OTP) {
    setEmailOtpData({ ...emailOtpData, ["pin"]: OTP });
    setOTP(OTP);
  }

  const handleUserVerifyOtp = async (e) => {
    e.preventDefault();
    const emailSend = username;
    const emailFormD = btoa(emailSend);
    const data = {
      email: emailFormD,
      pin: OTP,
    };
    ProfileApi.globalSettingsUserVerify(data)
      .then(function (resp) {
        if (resp.data.message == "Success") {
          Swal.fire({
            icon: "success",
            title: "Email verified successfully!",
            showConfirmButton: false,
            timer: 2000,
          });
          getUserDeatils();
        } else if (resp.data.status == false) {
          Swal.fire({
            icon: "error",
            title: "Invalid Pin",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //   for phone number verify
  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    if (formData.phone_number.slice(0, 2) == 91) {
      const data1 = {
        type: "contact",
        contact_no: number.slice(2, 12),
        c_code: iso,
      };
      ProfileApi.updateuserDetails(data1)
        .then(function (resp) {
          console.log(JSON.stringify(resp.data));
          const data = {
            phone_number: formData.phone_number.slice(2, 12),
          };
          ProfileApi.sendOtp(data)
            .then(function (response) {
              sessionStorage.setItem("phone_numberr", formData.phone_number);
              if (response.data.status == true) {
                setSendOtpPhoneDisabled(true);
                setMessageError("");
                setTimeout(() => {
                  sessionStorage.removeItem("phone_numberr");
                }, 130000);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        })
        .catch(function (err) {
          console.log(err);
          if (err.response.status == 500) {
            setMessageError(
              "This number is already exit. Please enter another number"
            );
            setSendOtpPhoneDisabled(false);
          } else {
            const data = {
              phone_number: formData.phone_number.slice(2, 12),
            };
            ProfileApi.sendOtp(data)
              .then(function (response) {
                sessionStorage.setItem("phone_numberr", formData.phone_number);
                if (response.data.status == true) {
                  setSendOtpPhoneDisabled(true);
                  setTimeout(() => {
                    sessionStorage.removeItem("phone_numberr");
                  }, 130000);
                }
              })
              .catch(function (error) {
                console.log(error);
              });
          }
        });
    } else if (formData.phone_number.slice(0, 2) != 91) {
      navigate("/abroad");
      const data3 = {
        type: "contact",
        contact_no: number,
        c_code: iso,
      };
      ProfileApi.updateuserDetails(data3)
        .then(function (resp) {
          console.log(JSON.stringify(resp.data));
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  };

  const abroad = async (e) => {
    e.preventDefault();
    if (formData.phone_number.slice(0, 2) == 91) {
      const data = {
        phone_number: formData.phone_number,
      };
      ProfileApi.sendOtp(data)
        .then(function (response) {
          sessionStorage.setItem("phone_numberr", formData.phone_number);
          if (response.data.status == true) {
            setTimeout(() => {
              sessionStorage.removeItem("phone_numberr");
            }, 130000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else if (formData.phone_number.slice(0, 2) != 91) {
      navigate("/abroad");
    }

    const data1 = {
      type: "contact",
      contact_no: number,
      c_code: iso,
    };
    ProfileApi.updateuserDetails(data1)
      .then(function (resp) {
        console.log(JSON.stringify(resp.data));
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const handleChangePhone = (e) => {
    setFormData({ ...formData, ["phone_number"]: e });
    setSendOtpPhoneDisabled(false);
  };

  const handlePhoneOtp = (phoneOtp) => {
    setPhoneOtpForm({ ...phoneOtpForm, ["otp_entered_by_user"]: phoneOtp });
    setPhoneOtp(phoneOtp);
  };

  const handleVerifyPhoneOtp = async (e) => {
    e.preventDefault();
    const data = {
      phone_number: sessionStorage.getItem("phone_numberr"),
      otp: phoneOtp,
    };
    ProfileApi.verifyOtp(data)
      .then(function (response) {
        if (response.data.status == true) {
          getUserDeatils();
          MyPagesApi.getOnboarding(userId)
            .then((respon) => {
              if (respon.data.message == "failed") {
                navigate("/on-boarding");
              } else {
                navigate("/verify");
              }
            })
            .catch((error) => {
              console.log(error);
            });
          Swal.fire({
            icon: "success",
            title: "Phone verified successfully!",
            showConfirmButton: false,
            timer: 2000,
          });
        } else if (response.data.status == false) {
          Swal.fire({
            icon: "error",
            title: "Oops! wrong OTP",
            showConfirmButton: false,
            timer: 1000,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <div className="container">
        <div className="verify_user">
          {emailStatus == 0 && (
            <div>
              <h5>Email Address</h5>
              <div>
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      autoFocus
                      id="username"
                      name="username"
                      value={username}
                      onChange={handleChange}
                      autoComplete="off"
                      required
                      disabled
                    />
                  </Form.Group>
                  <Button
                    id="sendOtp"
                    variant="danger"
                    onClick={handleUserVerify}
                    className="col-12 customPopupBtn"
                    disabled={buttonDisabled}
                  >
                    Send Verificaiton Code
                  </Button>
                </Form>
              </div>
              <div>
                <Form>
                  <div className="otp_verify">
                    <OtpInput
                      id="otpInput"
                      value={OTP}
                      onChange={handleChangeOtp}
                      numInputs={4}
                      otpType="number"
                      inputStyle="inputStyle"
                      secure
                      name="otp_entered_by_user"
                    />
                  </div>
                  <Button
                    id="otpMobile"
                    variant="danger"
                    onClick={handleUserVerifyOtp}
                    className="col-12 customPopupBtn"
                  >
                    Next
                  </Button>
                </Form>
              </div>
            </div>
          )}

          {/* phone verify */}
          {phoneStatus == 0 && (
            <div style={{ marginTop: "30px" }}>
              <h5>Verify Your Phone</h5>
              <div style={{ marginTop: "10px" }}>
                <Form autoComplete="off">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <PhoneInput
                      country={"in"}
                      value={formData.phone_number}
                      id="phone_number"
                      onChange={handleChangePhone}
                      name="phone_number"
                      className="phone_input_verify"
                      countryCodeEditable={false}
                      inputExtraProps={{
                        name: "tel",
                        required: true,
                        autoFocus: true,
                      }}
                      isValid={(inputNumber, value) => {
                        setIso(value.iso2.toUpperCase());
                        setNumber(inputNumber);
                      }}
                    />
                  </Form.Group>
                  {iso != "IN" && (
                    <Button
                      variant="danger"
                      onClick={abroad}
                      className="col-12 customPopupBtn"
                    >
                      Save Number
                    </Button>
                  )}
                  {iso == "IN" && (
                    <Button
                      id="sendOtp"
                      variant="danger"
                      onClick={handlePhoneSubmit}
                      className="col-12 customPopupBtn"
                      disabled={sendOtpPhoneDisabled}
                    >
                      Send Verification Code
                    </Button>
                  )}
                  <p className="text-danger">{messageError}</p>
                </Form>
                {iso == "IN" && (
                  <Form>
                    <div className="otp_verify">
                      <OtpInput
                        id="otpMobile"
                        value={phoneOtp}
                        onChange={handlePhoneOtp}
                        numInputs={5}
                        otpType="number"
                        inputStyle="inputStyle"
                        secure
                        name="otp_entered_by_user"
                      />
                    </div>
                    <Button
                      variant="danger"
                      onClick={handleVerifyPhoneOtp}
                      className="col-12 customPopupBtn"
                    >
                      Verify Phone
                    </Button>
                  </Form>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserVerify;
