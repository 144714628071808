const brandLabURL = process.env.REACT_APP_Btpbrandlabs_Api;
const localURL = 'http://localhost:3000/api/';

const dev = {
  brandLabURL: localURL,
  mediaURL: 'https://media.dev.beingtheparent.com/',
  globalApi: 'https://auth.dev.beingtheparent.com/',
};


const production = {
  brandLabURL: brandLabURL,
  mediaURL: 'https://media.dev.beingtheparent.com/',
  globalApi: 'https://auth.beingtheparent.com/',
};
const config = process.env.NODE_ENV === 'production' ? production : dev;
const brandLabApi = process.env.REACT_APP_Btpbrandlabs_Api;
const mediaApi = process.env.REACT_APP_Media_Api;
const apiUrl = {
  sponsoredPostApi: brandLabApi + 'sponsored-post',
  uploadMediaApi: mediaApi +
    'api/media/v1/upload?folder=',
  getMediaApi: mediaApi + 'api/media/v3/no-auth?key=',
  fileDetailsApi: brandLabApi + 'file-details/',
  userDetailsApi: process.env.REACT_APP_Btp_Global_Api +
    'Btpmobileapi/getuser_details',
  // searchApi: process.env.REACT_APP_Search_Api + 'api/search/v1/query',
  searchApi: process.env.REACT_APP_Search_Api + 'api/search-count/v1/count',
  paypalOrderApi: brandLabApi + 'order-paypal',
  paypalCaptureApi: brandLabApi + 'capture-paypal',
  razorPayOrderApi: brandLabApi + 'order/',
  razorPayCaptureApi: brandLabApi + 'capture/',
  emailAddApi: brandLabApi + 'email-add',
  getPages: brandLabApi + 'get-pages',
  getProduct: brandLabApi + 'get-products/',
  getOnboarding: brandLabApi + 'get-onboarding/',
  getMessage: brandLabApi + 'readMessage/',
  getProductWid: brandLabApi + 'get-products',
  getSectionData: brandLabApi + 'get-section',
  uploadMediaApi: mediaApi + 'api/media/v1/upload?folder=',
  getMediaApi: mediaApi + 'api/media/v3/no-auth?key=',
  fileDetailsApi: brandLabApi + 'file-details/',
  createPages: brandLabApi + 'create-pages',
  createSection: brandLabApi + 'create-section',
  createProduct: brandLabApi + 'create-products',
  pageUpdate: brandLabApi + 'page-update/',
  productUpdate: brandLabApi + 'product-update/',
  pageDelete: brandLabApi + 'page-delete/',
  productDelete: brandLabApi + 'delete-products/',
  sectionDelete: brandLabApi + 'delete-section/',
  // getCategories: brandLabApi + "categories",
  transactionApi: brandLabApi + 'transaction?',
  ticketApi: brandLabApi + 'ticket',
  messageApi: brandLabApi + 'send-message',
  recoverCoupon: brandLabApi + 'recover-coupon',
  applyCoupon: brandLabApi + 'validate-coupon',
};
export default {...apiUrl, ...config};

