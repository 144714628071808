import React, {useState, useEffect} from 'react';
import OtpInput from 'react18-input-otp';
import {Button, Modal, Form} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import Swal from 'sweetalert2';
import ProfileApi from '../services/ProfileApi';

const Otp = (prop) => {
  const navigate = useNavigate();
  const [OTP, setOTP] = useState('');
  const [formData, setFormData] = useState({});
  const [timer, setTimer] = useState(0);
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer(timer - 1);
        // setSendOtpPhoneDisabled(true);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleVerify = async (e) => {
    e.preventDefault();
    const emailSend = sessionStorage.getItem('username');
    const encodedemail = btoa(emailSend);
    const data = {
      email: encodedemail,
      pin: formData.pin,
    };
    ProfileApi.globalSettingsUserVerify(data)
        .then(function(resp) {
          if (resp.data.message == 'Success') {
            prop.setShowPhone(true);
            prop.setOtpShow(false);
          } else if (resp.data.status == false) {
            Swal.fire({
              icon: 'error',
              text: resp.data.message,
              showConfirmButton: false,
              timer: 600,
            });
            navigate('/');
            prop.setOtpShow(true);
          }
        })
        .catch(function(error) {
          console.log(error);
        });
  };

  const handleResentOtp = async () => {
    setTimer(30);
    const emailSend = sessionStorage.getItem('username');
    const encodedemail = btoa(emailSend);
    const data = {
      email: encodedemail,
    };
    ProfileApi.resendVerificationPin(data).then((res)=>{
      // console.log(res.data.status);
      if (res.data.status==true) {
      }
    }).catch((err)=>{
      console.log(err);
    });
  };

  function handleChange(OTP) {
    setFormData({ ...formData, ['pin']: OTP });
    setOTP(OTP);
  }

  return (
    <Modal
      backdrop='static'
      show={prop.otpShow}
      onHide={() => {
        prop.setOtpShow(false);
      }}
    >
      <Modal.Header closeButton className='border-0'></Modal.Header>
      <Modal.Body>
        <Form.Label>Enter Verification code</Form.Label>
        <Form onSubmit={handleVerify}>
          <Form.Label id='mail-ga'>
            We have sent you a PIN at {sessionStorage.getItem('username')}
          </Form.Label>
          <Form.Group
            className='mb-3 otpNumber'
            controlId='exampleForm.ControlInput1'
          >
            <div className='otp'>
              <OtpInput
                value={OTP}
                onChange={handleChange}
                numInputs={4}
                otpType='number'
                inputStyle='inputStyle'
                secure
                name='pin'
              />
            </div>
          </Form.Group>
          <Button
            variant='danger'
            className='col-12 customPopupBtn'
            type='submit'
          >
            Verify
          </Button>
        </Form>
        {/* <p className='resend' onClick={handleResentOtp}>
          <Button
            variant='danger'
            disabled={sendOtpPhoneDisabled}
          >Resend Verification Code</Button>
        </p> */}
        {timer === 0 ? (
          <p className='resend' onClick={handleResentOtp}>
            <Button
              variant='danger'
            >Resend Verification Code</Button>
          </p>
        ) : (
          <p className='resend'>
            <Button
              variant='danger'
              disabled
            >Resend OTP {timer}</Button>
          </p>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Otp;
