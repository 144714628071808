import errorHandling from '../errorhandling/ErrorHandle';
import {brandlabsAuth} from './BrandlabsAuth';
import apiUrl from '../utils/brandlabapi';
const FormData = require('form-data');

export default {
  uploadFile: async (folderName, bucketName, fileData) => {
    const form = new FormData();
    form.append('file', fileData);
    form.append('name', fileData.name);
    form.append('type', fileData.type);
    form.append('size', fileData.size);
    form.append('key', '');
    try {
      const res = await brandlabsAuth.
          fetchData(apiUrl.uploadMediaApi + folderName + '&bucket=' +
      bucketName, 'post', {'Content-Type':
      'multipart/form-data;boundary=${form._boundary}`'}, form);
      return res;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  getFile: async (key, folder) => {
    try {
      const res = await brandlabsAuth.fetchData(apiUrl.getMediaApi + key +
        '&folder=' + folder, 'get');
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },

  getFileDetails: async (id) => {
    try {
      const res = await brandlabsAuth.
          fetchData(apiUrl.fileDetailsApi + id, 'get');
      return res.data;
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
      return;
    }
  },
};
