import apiUrl from '../utils/brandlabapi';
import errorHandling from '../errorhandling/ErrorHandle';
import {brandlabsAuth} from './BrandlabsAuth';
const logo = require('../utils/config.json');
export default {

  razorPayPaymentOrder: async (id, userDetails, cause, couponCode,
      getRazorPayResposne) => {
    try {
      const responseData = await brandlabsAuth.
          fetchData(apiUrl.razorPayOrderApi + id, 'get', '',
              {params: {'cause': cause, 'couponCode': couponCode}});
      const {data} = responseData;
      if (data.data) {
        const options = {
          'key': process.env.REACT_APP_RAZOR_PAY_KEY_ID,
          'name': 'BTP Brandlabs',
          'order_id': data.data.id,
          'image':
            logo,
          'prefill': {
            contact: userDetails.mobile &&
            userDetails.mobile.length === 12 ? userDetails.mobile.slice(2): '',
            email: userDetails.email,
            name: userDetails.full_name,
          },
          'modal': {
            'ondismiss': function() {
              console.log('Checkout form closed');
            },
          },
          'handler': async (response) => {
            try {
              const paymentId = response.razorpay_payment_id;
              response.productId = id;
              response.couponCode = couponCode;
              response.cause = cause;
              const captureUrl = `${apiUrl.razorPayCaptureApi}${paymentId}`;
              const captureResponse = await brandlabsAuth.
                  fetchData(captureUrl, 'post', '', {response: response});
              getRazorPayResposne(captureResponse.data);
            } catch (err) {
              console.log('err', err);
              errorHandling.handleErrors(err.response.data);
              getRazorPayResposne();
              return err;
            }
          },
          'theme': {
            color: '#FAF5FE',
          },
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      } else {
        console.log('err');
        errorHandling.handleErrors('server error');
        getRazorPayResposne();
        return;
      }
    } catch (err) {
      if (err.response) {
        console.log('err');
        errorHandling.handleErrors(err.response.data.message);
        getRazorPayResposne();
      } else {
        console.log('err');
        errorHandling.handleErrors(err.message);
        getRazorPayResposne();
      }
    }
  },

  paypalPayment: async (productId, couponCode, cause) => {
    const body = {productId: productId, couponCode: couponCode, cause: cause};
    try {
      const responseData = await brandlabsAuth.
          fetchData(apiUrl.paypalCaptureApi, 'post', '', body);
      const data = responseData;
      return data;
    } catch (err) {
      await errorHandling.handleErrors({'message': 'Payment failed'});
      return;
    }
  },

  paypalOrder: async (productId, cause, couponCode) => {
    const body = {};
    body.productId = productId;
    body.cause = cause;
    body.couponCode = couponCode;
    try {
      const responseData = await brandlabsAuth.
          fetchData(apiUrl.paypalOrderApi, 'post', '', body);
      const data = responseData;
      return data;
    } catch (err) {
      if (err.response) {
        await errorHandling.handleErrors(err.response.data.message);
      } else {
        await errorHandling.handleErrors({'message': 'Payment failed'});
      }
      return;
    }
  },
};
