import React, {useEffect, useState} from 'react';
import {Card, Table, Button, Form, InputGroup} from 'react-bootstrap';
import SponsoredPostApi from '../../services/SponsoredPostApi';
import './style.css';
import ProfileApi from '../../services/ProfileApi';
import {useQuery} from 'react-query';
import PayPal from '../../services/PayPal';
import PaymentApi from '../../services/PaymentApi';
import propTypes from 'prop-types';

const PriceDetails = (props) => {
  PriceDetails.propTypes = {
    show: propTypes.bool,
    handleClose: propTypes.any,
    getProductDetails: propTypes.any,
    productDetails: propTypes.any,
    cause: propTypes.string,
  };
  const [show, setShow] = useState(false);
  const [postId, setPostId] = useState('');
  const [amount, setAmount] = useState(0);
  const [inputDisabled, setInputDisabled] = useState(false);
  const [btnDisabled, setBtnDisabled]=useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const userDataDetails = useQuery('userDetails', ProfileApi.getuserDetail,
      {enabled: false});
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
  }

  const [formaData, setFormData] = useState({couponCode: ''});
  const [errorCoupon, setErrorCoupon] = useState();
  const [finalAmount, setFinalAmount] = useState('');
  const [applyBtnShow, setApplyBtnShow] = useState(false);

  const validateCoupon = async (e) => {
    e.preventDefault();
    const data = {
      'couponCode': formaData.couponCode,
      'amount': props.productDetails.originalAmount,
    };
    await SponsoredPostApi.applyCoupon(data)
        .then((response) => {
          setErrorCoupon(response.message);
          if (response.message === 'Coupon code successfully applied') {
            setApplyBtnShow(true);
            setFinalAmount(response.finalAmount);
            setInputDisabled(true);
          }
        }).catch((err) => {
          console.log(err);
        });
  };


  const handleChangeCoupon = (e) => {
    setFormData({...formaData, [e.target.name]: e.target.value});
  };

  const removeValidateCoupon = async (e) => {
    setApplyBtnShow(false);
    setFormData({couponCode: ''});
    setFinalAmount('');
    setErrorCoupon();
    setInputDisabled(false);
  };

  useEffect(()=>{
    if (props.productDetails.couponCode!='') {
      setFormData({'couponCode': props.productDetails.couponCode});
      setErrorCoupon('Coupon Code Already Used');
      setInputDisabled(true);
      setApplyBtnShow(true);
    } else {
      setFormData({couponCode: ''});
      setInputDisabled(false);
    }
  }, [props]);

  function getRazorPayResposne(data) {
    if (data) {
      props.getProductDetails();
    } else {
      setBtnDisabled(false);
    }
  }
  const handlePayment = async (event, id, couponCode) => {
    event.preventDefault();
    if (userData &&
      userData.c_code === 'IN') {
      setBtnDisabled(true);
      await PaymentApi.
          razorPayPaymentOrder(id,
              userData,
              props.cause,
              couponCode, getRazorPayResposne );
    } else {
      setShow(true);
      setPostId(id);
      setAmount(props.productDetails.originalAmount);
      props.getProductDetails();
    }
  };
  function getAmount(type, amount, finalAmount) {
    if (amount === undefined) {
      amount = 0;
    }
    if (type === 'totalAmount' && finalAmount !== '') {
      amount = finalAmount;
    }
    if (userData.c_code === 'IN') {
      return '₹' + (amount !== undefined ?
        amount.toLocaleString('en-IN') : amount);
    } else {
      return '$' + (amount !== undefined ?
        amount.toLocaleString('en-US'): amount);
    }
  }
  console.log(formaData.couponCode);
  console.log(applyBtnShow);
  return (
    <>
      <Card>
        <Card.Header>Price Details
        </Card.Header>
        <Card.Body>
          <Form >
            <Table className="striped bordered hover">
              <tbody>
                <tr><td>Actual Amount</td><td>
                  {getAmount('actualAmount',
                      props.productDetails.originalAmount)}
                </td></tr>
                <tr><td>Apply coupon</td>
                  <td>
                    <InputGroup >
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter coupon code here"
                        id='validate_coupon_input'
                        name='couponCode'
                        value={formaData.couponCode}
                        onChange={handleChangeCoupon}
                        disabled={inputDisabled}
                      />
                      {formaData.couponCode !== '' ?
                        applyBtnShow === false ?
                          <Button size="sm" onClick={validateCoupon}
                            variant="outline-danger">  Apply</Button>:
                          <Button size="sm" onClick={removeValidateCoupon}
                            variant="outline-danger"> Remove</Button>: null
                      }
                    </InputGroup>
                    <small id="coupon_err_msg" className="text-danger">
                      {errorCoupon}
                    </small>
                  </td></tr>

                <tr><td>Total Amount</td><td>
                  {getAmount('totalAmount', props.productDetails.totalAmount,
                      finalAmount)} <Button
                    className='pay_btn'
                    disabled={
                      !btnDisabled ?
                        false :
                        true
                    }
                    onClick={(event) =>
                      handlePayment(
                          event,
                          props.productDetails._id,
                          formaData.couponCode,
                      )
                    }
                  > Pay</Button>
                </td></tr>
              </tbody>
            </Table>
          </Form>
        </Card.Body>
      </Card>
      <PayPal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        postId={postId}
        userDetails={userData}
        amount={amount}
        cause={props.cause}
        couponCode={formaData.couponCode}
        getProductDetails={props.getProductDetails}
      />
    </>
  );
};

export default PriceDetails;
