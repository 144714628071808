import React, {useEffect, useState} from 'react';
import {Card, Form} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import defu from '../../../assets/svg/default-logo.svg';
import brandlabapi from '../../../utils/brandlabapi';
import MyPagesApi from '../MyPagesApi';
import {useLocation} from 'react-router-dom';
import ProfileApi from '../../../services/ProfileApi';
import errorHandling from '../../../errorhandling/ErrorHandle';

const Confirmation = (prop) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageID, setPageID] = useState();
  useEffect(() => {
    prop.setShow(false);
  }, []);

  useEffect(() => {
    if (location.state == null) {
      setPageID(sessionStorage.getItem('page_id'));
    } else {
      setPageID(location.state.state.product.page_id);
    }
  }, []);

  const handleClickPublish = () => {
    navigate('/dashboard/my-pages');
  };
  const [userId, setuserID] = useState();
  const [productData, setProductData] = useState([]);
  const [arrayData, setArrayData] = useState([]);

  const getUserDeatils = async () => {
    await ProfileApi.getuserDetail()
        .then((resn)=>{
          setuserID(resn.data.data[0].sk_user_id);
        })
        .catch((error) =>{
          console.log(error);
        });
  };

  useEffect(() => {
    getUserDeatils();
  }, []);

  const getPage = async () => {
    try {
      const res = await MyPagesApi.getPages();
      setArrayData(res.data.data);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  };

  const getProduct = async () => {
    try {
      const res = await MyPagesApi.getProductWid();
      setProductData(res.data.data);
    } catch (err) {
      errorHandling.handleErrors(err.response.data);
    }
  };

  useEffect(() => {
    getPage();
    getProduct();
  }, []);

  return (
    <div className='confiramtion-page border-0'>
      <div className='create-confiramtion card'>
        <div className='create-page-div'>
          <a href='/dashboard/my-pages' id='bck-to-hm'>
            Confirmation Page
          </a>
          <h1>Brand page is ready!</h1>
        </div>
        <section className='create-page-confirmation'>
          <Card className='publish-now-card'>
            <div className='card-div-logo'>
              <div className='small-logo'>
                {arrayData?.map((value) => {
                  return (
                    <>
                      {userId == value.user_id && (
                        <>
                          {value._id == pageID && (
                            <>
                              {value.brand_logo == '' ? (
                                <img
                                  className='publish_img'
                                  src={defu}
                                  alt=''
                                />
                              ) : (
                                <img
                                  src={
                                    brandlabapi.mediaURL +
                                    'api/media/v3/no-auth?key=' +
                                    value.brand_logo
                                  }
                                  className='publish_img'
                                />
                              )}
                              <text>{value.brand_name}</text>
                            </>
                          )}
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
            <div className='card-div-logo'>
              <div className='fourth-page-product'>
                {productData?.map((val) => {
                  return (
                    <>
                      {pageID == val.page_id && (
                        <>
                          {' '}
                          <text>{val.product_name}</text>{' '}
                        </>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
            <div className='card-right-button'>
              <div className='card-right-button-inner'>
                <Form.Text className='text-muted'>
                  We will publish your page after review.
                </Form.Text>
                &#160; &#160;
                <button onClick={handleClickPublish}>Send for review</button>
              </div>
            </div>
          </Card>
        </section>
      </div>
    </div>
  );
};

export default Confirmation;
