import React from 'react'
import {Button, Modal, Form} from 'react-bootstrap';

const Congratulations = (prop) => {
    // show={show} onHide={handleClose}
    // congratulationsModal, setCongratulationsModal

    const handleContinue=()=>{
        prop.setShowPhone(true)
        prop.setCongratulationsModal(false)
    }
    return (
        <Modal backdrop="static" show={prop.congratulationsModal} onHide={()=>{
            prop.setCongratulationsModal(false)}}>
            <Modal.Header closeButton className='border-0'>
                {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
            <Form.Label >Congratulations!</Form.Label>
            <p className='congoP'>Your email has been verified. Please go ahead.</p>
                {/* Woohoo, you're reading this text in a modal! */}

            <Button variant='danger' className='col-12 customPopupBtn' onClick={handleContinue}>Continue</Button>
            </Modal.Body>
            <Modal.Footer className='border-0'>
                {/* <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleClose}>
                    Save Changes
                </Button> */}
            </Modal.Footer>
        </Modal>
    )
}

export default Congratulations
