import {Button, Modal, Form} from 'react-bootstrap';
import React from 'react';
import {useState} from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {useNavigate} from 'react-router-dom';
import ProfileApi from '../services/ProfileApi';
const Phone = (prop) => {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [iso, setIso] = useState();
  const [number, setNumber] = useState();
  const [messageError, setMessageError] = useState();

  const [error, setError]=useState(false);
  const handleChange = (e) => {
    // console.log(e)
    // var phone = '1234567890';
    // if (!(phone.match('[0-9]{10}'))) {
    //   alert('Please provide valid phone number');
    // } else {
    //   //good to go
    // }
    setFormData({...formData, ['phone_number']: e});
    // formData.phone_number({phone, ['phone_number']: e});
  };


  const phone = (number) => {
    var mobPattern = /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/;
    return mobPattern.test(number);
  };


  const handleVerify = async (e) => {
    e.preventDefault();
    // if (formData.phone_number.slice(0, 2) == 91){
    //   if (!phone(formData.phone_number)){
    //     console.log(formData.phone_number)
    //     window.alert("Wrong number")
    //     setError(true)
    //   }else{
    //     console.log(formData.phone_number)
    //   }
    // }
    if (formData.phone_number.slice(0, 2) == 91) {
      if (!phone(formData.phone_number)) {
        console.log(formData.phone_number);
        setError(true);
        e.preventDefault();
      } else {
        console.log(formData.phone_number);
        const data1 = {
          type: 'contact',
          contact_no: number.slice(2, 12),
          c_code: iso,
        };
        ProfileApi.updateuserDetails(data1)
            .then(function(resp) {
              const data = {
                phone_number: formData.phone_number.slice(2, 12),
              };
              ProfileApi.sendOtp(data)
                  .then(function(response) {
                    sessionStorage.setItem('phone_number',
                        formData.phone_number);
                    if (response.data.status == true) {
                      setTimeout(() => {
                        sessionStorage.removeItem('phone_number');
                      }, 130000);
                      prop.setPhoneOtpShow(true);
                      prop.setShowPhone(false);
                    } else {
                      prop.setShowPhone(true);
                    }
                  })
                  .catch(function(error) {
                    console.log(error);
                  });
            })
            .catch(function(err) {
              if (err.response.status == 500) {
                setMessageError(
                    'Number already in use !',
                );
                prop.setShowPhone(true);
              } else if (err.response.status != 500) {
                const data = {
                  phone_number: formData.phone_number.slice(2, 12),
                };
                ProfileApi.sendOtp(data)
                    .then(function(response) {
                      sessionStorage.setItem('phone_number',
                          formData.phone_number);
                      if (response.data.status == true) {
                        setTimeout(() => {
                          sessionStorage.removeItem('phone_number');
                        }, 130000);
                        prop.setPhoneOtpShow(true);
                        prop.setShowPhone(false);
                      } else {
                        prop.setShowPhone(true);
                      }
                    })
                    .catch(function(error) {
                      console.log(error);
                    });
              }
            });
      }

      const data3 = {
        type: 'contact',
        contact_no: number.slice(2, 12),
        c_code: iso,
      };
      ProfileApi.updateuserDetails(data3)
          .then(function(resp) {
          })
          .catch(function(err) {
            console.log(err.response.status);
          });
      // for aborad
    } else if (formData.phone_number.slice(0, 2) != 91) {
      navigate('/abroad');
      prop.setShowPhone(false);
      const data2 = {
        type: 'contact',
        contact_no: number,
        c_code: iso,
      };
      ProfileApi.updateuserDetails(data2)
          .then(function(resp) {
            console.log(JSON.stringify(resp.data));
          })
          .catch(function(err) {
            console.log(err);
          });
    }
  };

  return (
    <Modal
      backdrop='static'
      show={prop.showPhone}
      onHide={() => {
        prop.setShowPhone(false);
      }}
    >
      <Modal.Header closeButton className='border-0'></Modal.Header>
      <Modal.Body>
        <Form.Label>Your phone no. please</Form.Label>
        <p className='congoP'>
          Enter your phone no so that we can verify it’s really you
        </p>
        <Form>
          <Form.Group className='mb-3' controlId='exampleForm.ControlInput1'>
            <PhoneInput
              country={'in'}
              value={formData.phone_number}
              id='phone_number'
              onChange={handleChange}
              name='phone_number'
              className='phone_input_verify'
              countryCodeEditable={false}
              inputExtraProps={{
                name: 'tel',
                required: true,
                autoFocus: true,
              }}
              isValid={(inputNumber, value) => {
                setIso(value.iso2.toUpperCase());
                setNumber(inputNumber);
              }}
            />
            {error && <p className='text-danger'>
              Please fill the correct phone number</p>}
          </Form.Group>
        </Form>
        <Button
          variant='danger'
          type='button'
          className='col-12 customPopupBtn'
          onClick={handleVerify}
        >
          Continue
        </Button>
        <p className='text-danger'>{messageError}</p>
      </Modal.Body>
      <Modal.Footer className='border-0'></Modal.Footer>
    </Modal>
  );
};

export default Phone;
