/* eslint-disable react/no-unknown-property */
import React, {memo, Fragment, useEffect, useState, useRef} from 'react';
import {Card, Form, Button, Row, Col} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import emailAddApi from '../../services/EmailAddsApi';
import ProfileApi from '../../services/ProfileApi';
import SimpleReactValidator from 'simple-react-validator';
import {useQuery} from 'react-query';
import UploadFiles from '../../services/UploadFiles';
import EmailAddsApi from '../../services/EmailAddsApi';
import './style.css';
import 'rc-slider/assets/index.css';
import FileUploadApi from '../../services/FileUploadApi';
import WeekSelector from './WeekSelector';
import {DateRange} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import EmailAddPrice from './EmailAddPrice';
const lifeStages = require('../../utils/lifestages.json');
const AddEmailAdd = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [emailAdd, setEmailAdd] = useState({
    title: '',
    url: '',
    _id: '',
    weeks: [0, 40],
    status: '',
    stages: ['pregnancy'],
    dateRange: [{
      startDate: new Date(),
      endDate: new Date().getTime() + 7 * 24 * 60 * 60 * 1000,
      key: 'selection',
    }],
  });
  const [imageFeature, setImageFeature] = useState(false);
  const [show, setShow] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [featuredImage, setFeaturedImage] = useState({});
  const [stageCount, setStageCount] = useState(0);
  const [error, setError] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const validator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const handleClose = () => setShow(false);
  const handleShow = ((event, imageFeature) => {
    event.preventDefault();
    setShow(true);
    setImageFeature(imageFeature);
  });

  const userDataDetails = useQuery('userDetails', ProfileApi.getuserDetail,
      {enabled: false});
  let userData = {};
  if (userDataDetails.data) {
    userData = userDataDetails.data.data.data[0];
  }

  useEffect(() => {
    if (params.id) {
      getAddDetails();
      getFileDetails();
    }
  }, []);

  const getFileDetails = async () => {
    const data = await FileUploadApi.getFileDetails(params.id);
    if (data.data && data.data.length !== 0) {
      const attachmentData = [];
      data.data.map((image) => {
        const imageData = {};
        imageData.key = image.attachment_url;
        imageData.size = image.size;
        imageData.name = image.fileName;
        imageData.type = image.fileType;
        imageData.imageFeature = image.imageFeature;
        attachmentData.push(imageData);
      });
      const filteredAttachments =
      attachmentData.filter((item) => item.imageFeature !== 'featured');
      setAttachments(filteredAttachments);
      const filteredFeaturedImage =
      attachmentData.filter((item) => item.imageFeature === 'featured');
      setFeaturedImage(filteredFeaturedImage[0]);
    }
  };
  const getAddDetails = async () => {
    const data = await emailAddApi.getAddById(params.id);
    if (data) {
      const emailData = data.data;
      emailData.dateRange = [
        {startDate: new Date(data.data.dateRange.startDate),
          endDate: new Date(data.data.dateRange.endDate), key: 'selection'}];
      setEmailAdd(emailData);
    }
  };

  const handleChange = (event) => {
    setEmailAdd((prevFormData) => {
      return {
        ...prevFormData,
        [event.target.name]: event.target.value,
      };
    });
  };

  const changeDate = (item) => {
    setEmailAdd((prevFormData) => {
      return {
        ...prevFormData,
        'dateRange': [item.selection],
      };
    });
  };

  const getTotalCount = async () => {
    if (emailAdd.stages.length > 0) {
      const data = await emailAddApi.getSearchCount(emailAdd.stages,
          emailAdd.weeks);
      if (data) {
        // setStageCount(data.result.length);
        setStageCount(data.result[0].count);
      }
    } else {
      setStageCount(0);
    }
  };

  const handleCheckboxChange = async (event, stage) => {
    if (event.target.checked) {
      setEmailAdd((prevFormData) => {
        return {
          ...prevFormData,
          'stages': [...prevFormData.stages, stage.id],
        };
      });
    } else {
      setEmailAdd((prevFormData) => {
        return {
          ...prevFormData,
          'stages': prevFormData.stages.filter((e) => e !== stage.id),
        };
      });
    }
  };

  useEffect(() => {
    getTotalCount();
  }, [emailAdd.stages, emailAdd.weeks]);

  const handleBack = () => {
    navigate('/dashboard/email-adds');
  };

  function getTotalAmount(price) {
    setTotalAmount(price);
  }
  const submitAdd = async (event, status) => {
    event.preventDefault();
    setError(false);
    if (validator.current.allValid()) {
      const startDate = emailAdd.dateRange ?
      emailAdd.dateRange[0].startDate: null;
      const endDate = emailAdd.dateRange ? emailAdd.dateRange[0].endDate: null;
      emailAdd.featuredImage = featuredImage;
      emailAdd.status = status;
      emailAdd.fullName = userData.full_name;
      emailAdd.email = userData.email;
      emailAdd.startDate = startDate;
      emailAdd.endDate = endDate;
      emailAdd.totalAmount = totalAmount;
      emailAdd.totalCount = stageCount;
      if (status === 'inReview' && Object.keys(featuredImage).length === 0) {
        setError(true);
        return;
      } else {
        setError(false);
        const data = await EmailAddsApi.postEmailAdd(emailAdd);
        if (data) {
          navigate('/dashboard/email-adds');
        }
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const getFileData = async (attachements, featuredImage) => {
    if (imageFeature === 'featured') {
      setFeaturedImage({});
      setFeaturedImage(featuredImage);
      setError(false);
    } else {
      setAttachments(attachements);
    }
  };

  const handleDeleteImage = (event, imageType, index) => {
    event.preventDefault();
    if (imageType) {
      setFeaturedImage({});
    } else {
      const attachmentdata = attachments.filter((item, i) => i !== index);
      setAttachments(attachmentdata);
    }
  };

  const getWeeks = (weeks) => {
    setEmailAdd((prevFormData) => {
      return {
        ...prevFormData,
        'weeks': weeks,
      };
    });
  };
  return (
    <Fragment>
      <div className="container dashboardRightDiv">
        <div className="middleConatinerDashboard">
          <Card>
            <Card.Header>
              Add Email Add
              <Button
                variant="outline-secondary"
                className="float-end"
                onClick={handleBack}
              >
                Back
              </Button>
            </Card.Header>
            <Card.Body>
              <Form method="post" onSubmit={(e) => e.preventDefault()}>
                <Row>
                  <Col md="6">
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                      {lifeStages.map((stage, i) => {
                        return <Form.Check
                          key={i+1}
                          type="checkbox"
                          name={stage.id}
                          label={stage.label}
                          id={stage.id}
                          disabled={stage.isDisabled}
                          checked={emailAdd.stages.includes(stage.id) ?
                            true: false}
                          onChange={(event) =>
                            handleCheckboxChange(event, stage)}
                        />;
                      })
                      }
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="title">
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        name="title"
                        onChange={handleChange}
                        value={emailAdd.title}
                        type="text"
                        placeholder="Title"
                      />
                      <div className="validation-error">
                        {validator.current.message('title',
                            emailAdd.title, 'required')}</div>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="url">
                      <Form.Label>URL</Form.Label>
                      <Form.Control
                        type="text"
                        name="url"
                        onChange={handleChange}
                        value={emailAdd.url}
                        placeholder="Text"
                      />
                      <div className="validation-error">
                        {validator.current.message('url',
                            emailAdd.url, 'required')}</div>
                    </Form.Group>
                    {emailAdd.stages.includes('pregnancy') ?
                      <WeekSelector getWeeks={getWeeks}
                        weeks={emailAdd.weeks}/>: null
                    }
                    <Form.Group className="mb-3" controlId="dateRange">
                      <Form.Label>Select Date Range</Form.Label>
                      <div>
                        <DateRange
                          editableDateInputs={true}
                          onChange={changeDate}
                          moveRangeOnFirstSelection={false}
                          ranges={emailAdd.dateRange}
                        />
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3 button-position"
                      controlId="attachement">
                      <Form.Label>{' '}</Form.Label>
                      <Button size="sm" variant="outline-secondary"
                        onClick={(event) => handleShow(event, 'featured')}>
                          Add Creative</Button>
                    </Form.Group>
                    {error === true ?
                    <div className="validation-error">
                      {'The Image is required'}</div>: null
                    }
                    {featuredImage && Object.keys(featuredImage).length > 0 ?
                      <span className="image-holder">
                        <img className="preview-image"
                          src={process.env.REACT_APP_Media_Api +
                         'api/media/v3/no-auth?key=' +
                         featuredImage.key} alt="" />
                        <button onClick={(event) =>
                          handleDeleteImage(event, 'featuredImage')}
                        className="delete btn btn-outline-danger btn-circle">
                          X</button>
                      </span> :
                      null
                    }
                  </Col>
                  <Col md="6">
                    <EmailAddPrice stageCount={stageCount} emailAdd={emailAdd}
                      getTotalAmount={getTotalAmount}
                      userData={userData}/>
                  </Col>
                </Row>
                <br />
                <Button variant="outline-secondary"
                  type="submit" onClick={(event) =>
                    submitAdd(event, 'draft')}>Save as Draft
                </Button>{' '}
                <Button variant="outline-danger"
                  disabled={totalAmount === 0 ? true: false} type="submit"
                  onClick={(event) => submitAdd(event, 'inReview')}>
                  Submit for Review
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
      <UploadFiles
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        getFileData={getFileData}
        imageFeature={imageFeature}
        attachments={attachments}
        featuredImage={featuredImage}
        id={emailAdd._id}
      />
    </Fragment>
  );
};

export default memo(AddEmailAdd);
